import React, { useState } from 'react';
import { useMutation, useApolloClient } from '@apollo/client';
import { Spinner, UncontrolledAlert } from 'reactstrap';
import ErrorLine from '../../containers/errorLine';
import AreaForm from './areaForm';
import * as request from './areasRequests';

const SubmitArea = ({ data }) => {
  const areaId = +data.area.id;

  const [complete, setComplete] = useState(null);
  const [loadingAccessSystemArea, setLadingAccessSystemArea] = useState(null);
  const [errorAccessSystemArea, setErrorAccessSystemArea] = useState(null);
  const [formKey, setFormKey] = useState(1);

  const [
    createAccessSystemArea,
    { loading: createLoading, error: createError },
  ] = useMutation(request.CREATE_ACCESS_SYSTEM_AREA, {
    onCompleted: () => {
      setComplete(true);
      setFormKey(formKey + 1);
    },
  });

  const [
    updateAccessSystemArea,
    { loading: updateLoading, error: updateError },
  ] = useMutation(request.UPDATE_ACCESS_SYSTEM_AREA, {
    onCompleted: () => {
      setComplete(true);
      setFormKey(formKey + 1);
    },
  });

  const client = useApolloClient();

  const checkAccessSystemArea = async areaId => {
    if (errorAccessSystemArea) setErrorAccessSystemArea(null);
    setLadingAccessSystemArea(true);

    const response = await client.query({
      query: request.GET_ACCESS_SYSTEM_AREA,
      variables: { areaId },
      fetchPolicy: 'network-only',
    });

    setLadingAccessSystemArea(false);
    return response.data.accessSystemAreasList[0];
  };

  const onSubmitAccessSystemArea = async variables => {
    try {
      const existingAccessSystemArea = await checkAccessSystemArea(areaId);

      if (existingAccessSystemArea && 'id' in existingAccessSystemArea) {
        // Update
        updateAccessSystemArea({ variables });
      } else {
        // Create
        createAccessSystemArea({ variables });
      }
    } catch (error) {
      setLadingAccessSystemArea(false);
      setErrorAccessSystemArea(error);
    }
  };

  const loading = loadingAccessSystemArea || createLoading || updateLoading;

  const success =
    complete &&
    !loading &&
    !errorAccessSystemArea &&
    !createError &&
    !updateError;

  return (
    <AreaForm
      data={data}
      onSubmitAccessSystemArea={onSubmitAccessSystemArea}
      formKey={formKey}
      loading={loading}
      updatingStatus={
        <>
          {loading && <Spinner color="secondary" />}
          {errorAccessSystemArea && <ErrorLine error={errorAccessSystemArea} />}
          {createError && <ErrorLine error={createError} />}
          {updateError && <ErrorLine error={updateError} />}
          {success && (
            <UncontrolledAlert>Data has been saved</UncontrolledAlert>
          )}
        </>
      }
    />
  );
};

export default SubmitArea;
