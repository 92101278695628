import { set, cloneDeep } from 'lodash';
import hasSchema from '../../../lib/hasSchema';

const insertConfigSchemas = (schema, uiSchema, pmses) => {
  const newSchema = cloneDeep(schema);
  const newUiSchema = cloneDeep(uiSchema);

  const oneOf = [];

  pmses.forEach(pms => {
    const id = +pms.id;
    let credSchema, credUiSchema, confSchema, confUiSchema;

    let hasCredSchema = hasSchema(pms.propertyCredentialsTemplate);
    let hasCredUiSchema = hasSchema(
      pms.propertyCredentialsTemplate,
      'uiSchema',
    );
    let hasConfSchema = hasSchema(pms.propertyPmsConfigTemplate);
    let hasConfUiSchema = hasSchema(pms.propertyPmsConfigTemplate, 'uiSchema');

    if (hasCredSchema) {
      credSchema = pms.propertyCredentialsTemplate.schema;
    } else {
      credSchema = {
        type: 'string',
        title: 'Credentials',
        default: '{}',
      };
    }

    if (hasCredUiSchema) {
      credUiSchema = pms.propertyCredentialsTemplate.uiSchema;
    } else {
      credUiSchema = {
        'ui:widget': 'textarea',
        'ui:options': {
          rows: 5,
        },
      };
    }

    if (hasConfSchema) {
      confSchema = pms.propertyPmsConfigTemplate.schema;
    }

    if (hasConfUiSchema) {
      confUiSchema = pms.propertyPmsConfigTemplate.uiSchema;
    }

    if (hasConfSchema) {
      oneOf.push({
        properties: {
          pmsId: {
            enum: [id],
          },
          [`credentials${id}`]: { title: 'Credentials', ...credSchema },
          [`pmsConfig${id}`]: { title: 'PMS bindings', ...confSchema },
        },
      });
    } else {
      oneOf.push({
        properties: {
          pmsId: {
            enum: [id],
          },
          [`credentials${id}`]: { title: 'Credentials', ...credSchema },
        },
      });
    }

    set(newUiSchema, `credentials${id}`, credUiSchema);
    set(newUiSchema, `pmsConfig${id}`, confUiSchema);
  });
  set(newSchema, 'dependencies.pmsId.oneOf', oneOf);
  return { schema: newSchema, uiSchema: newUiSchema };
};

export default insertConfigSchemas;
