const propertyStatuses = [
  {
    id: 'ACTIVE',
    name: 'Active',
    color: 'success',
  },
  {
    id: 'BLOCKED',
    name: 'Blocked',
    color: 'danger',
  },
  {
    id: 'DELETED',
    name: 'Deleted',
    color: 'danger',
  },
  {
    id: 'SUSPENDED',
    name: 'Suspended',
    color: 'warning',
  },
];

export default propertyStatuses;
