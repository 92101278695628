import React, { useContext, useState } from 'react';
import * as requests from './requests';
import { useMutation, useSubscription } from '@apollo/client';
import { Button, Spinner, Alert, UncontrolledTooltip, Badge } from 'reactstrap';
import { ModalDialog } from '../../containers/dialog';
import ErrorLine from '../../containers/errorLine';
import FormWrapper from '../../../form/formWrapper';
import submit from '../../../form/syntheticSubmit';
import { add, format, set } from 'date-fns';
import { AbilityContext } from '../../../auth/abilityContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faKey } from '@fortawesome/free-solid-svg-icons';
import statuses from './manualKeyStatuses';

const finalStatuses = ['FAILED', 'REJECTED', 'SUCCESS'];

const TestKeyButton = ({ data }) => {
  const subjectName = 'TestKey';
  const ability = useContext(AbilityContext);
  const { value } = data || {};
  const { deviceId, areasList } = value || {};

  const schema = {
    type: 'object',
    properties: {
      areaId: {
        type: 'integer',
        title: 'Area',
        enum: areasList.map(a => a.id),
        enumNames: areasList.map(a => a.name),
        default: areasList[0]?.id || 0,
      },
      startDate: {
        type: 'string',
        title: 'Start date',
        default: format(
          set(new Date(), { hours: 12, minutes: 0, seconds: 0 }),
          "yyyy-MM-dd'T'HH:mm:ss",
        ),
      },
      endDate: {
        type: 'string',
        title: 'End date',
        default: format(
          add(set(new Date(), { hours: 12, minutes: 0, seconds: 0 }), {
            days: 1,
          }),
          "yyyy-MM-dd'T'HH:mm:ss",
        ),
      },
    },
    required: ['areaId', 'startDate', 'endDate'],
  };

  const uiSchema = {
    'ui:order': ['areaId', 'startDate', 'endDate'],
    'startDate': {
      'ui:widget': 'DateTimePicker',
    },
    'endDate': {
      'ui:widget': 'DateTimePicker',
    },
  };

  const [form, setForm] = useState();
  const [complete, setComplete] = useState(false);
  const [status, setStatus] = useState(false);
  const [statusMessage, setStatusMessage] = useState(false);
  const [manualKeyId, setManualKeyId] = useState();

  const [createManualKey, { loading, error }] = useMutation(
    requests.CREATE_MANUAL_KEY,
    {
      onCompleted: data => {
        setComplete(true);
        setManualKeyId(data.createManualKey.manualKey.id);
      },
    },
  );

  const onSubmit = data => {
    const variables = {
      ...data,
      deviceId,
    };
    createManualKey({ variables });
  };

  const success = !!complete && !loading && !error;

  const onDismiss = () => setComplete(false);

  const buttonId = `${subjectName}-${deviceId}`;

  const { loading: subscriptionLoading, error: subscriptionError } =
    useSubscription(requests.MANUAL_KEY_CHANGES, {
      variables: { manualKeyId },
      skip: !manualKeyId,
      fetchPolicy: 'network-only',
      onSubscriptionData: data => {
        const {
          data: { manualKeyChanges },
        } = data.subscriptionData;
        const { manualKey } = manualKeyChanges;
        const { status, statusMessage } = manualKey;
        setComplete(true);
        setStatus(status);
        setStatusMessage(statusMessage);
        if (finalStatuses.includes(status)) {
          setManualKeyId(null);
        }
      },
    });

  const getStatusColor = status => {
    if (!status) return 'success';
    const { color } = statuses.find(s => s.id === status) || {
      color: 'secondary',
    };
    return color;
  };

  const getStatusName = status => {
    if (!status) return false;
    const { name } = statuses.find(s => s.id === status) || {
      name: status,
    };
    return name;
  };

  return (
    <>
      {ability.can('view', subjectName) && (
        <>
          <ModalDialog
            buttonLabel={
              <FontAwesomeIcon icon={faKey} className="button-icon" />
            }
            buttonSize="sm"
            title="Issue a test key"
            onClose={onDismiss}
            disabled={areasList.length === 0}
            buttonId={buttonId}
            footer={
              <Button onClick={() => submit(form)} color="success">
                Issue a test key
              </Button>
            }
          >
            <FormWrapper
              subjectName={subjectName}
              loading={loading}
              schemaSource={schema}
              uiSchemaSource={uiSchema}
              onSubmit={onSubmit}
              setForm={setForm}
              children={' '}
            />
            {loading && <Spinner color="secondary" />}
            {error && <ErrorLine error={error} />}
            {subscriptionError && <ErrorLine error={subscriptionError} />}
            {success && (
              <Alert
                isOpen={success}
                toggle={onDismiss}
                color={getStatusColor(status)}
              >
                {subscriptionLoading && <Spinner color="secondary" size="sm" />}{' '}
                {!status && 'Test key request sent successfully'}
                {status && (
                  <div>
                    <Badge color={getStatusColor(status)}>
                      {getStatusName(status)}
                    </Badge>{' '}
                    {statusMessage}
                  </div>
                )}
              </Alert>
            )}
          </ModalDialog>
          <UncontrolledTooltip target={buttonId}>
            Issue a test key
          </UncontrolledTooltip>
        </>
      )}
    </>
  );
};

export default TestKeyButton;
