import React from 'react';
import { Link } from 'gatsby';
import PropertyLogo from '../propertyLogo';

const PropertySidebarHead = ({ data }) => {
  const d = data.property;
  return (
    <div className="border-bottom border-top">
      <Link to={`/property/${d.id}`} className="nav-link">
        <div className="d-flex flex-row align-items-center">
          <div className="mr-3 sidebar-logo">
            <PropertyLogo property={d} size="small" />
          </div>
          <div>
            <span className="font-weight-bold text-secondary">{d.name}</span>
          </div>
        </div>
      </Link>
    </div>
  );
};

export default PropertySidebarHead;
