import React from 'react';
import { useQuery } from '@apollo/client';
import { Spinner, Badge } from 'reactstrap';
import Table from '../../containers/table/table';
import ErrorLine from '../../containers/errorLine';
import EditableCell from '../../containers/table/editableCell';
import areaStatuses from './areaStatuses';
import SectionHead from '../sectionsHead';
import Area from './area';
import * as request from './areasRequests';

const template = {
  schema: {
    type: 'object',
    properties: {
      status: {
        type: 'string',
        title: '',
        default: areaStatuses[0].id,
        enum: areaStatuses.map(s => s.id),
        enumNames: areaStatuses.map(s => s.name),
      },
    },
  },
  uiSchema: {
    status: {
      'ui:options': {
        label: false,
      },
    },
  },
};

const Areas = ({ propertyData, links, title }) => {
  const propertyId = +propertyData.property.id;

  const getAreaStatus = row =>
    areaStatuses.filter(status => status.id === row.status)[0] || {};

  const getAccessSystemConfigName = row => {
    const { accessSystemConfigsList } = row.property || {};
    const accessSystemConfigId =
      row.accessSystemArea && row.accessSystemArea.accessSystemConfigId;
    const accessSystemConfig = accessSystemConfigsList.filter(
      a => a.id === accessSystemConfigId,
    )[0];
    return accessSystemConfig && accessSystemConfig.name;
  };

  const { loading, error, data } = useQuery(request.GET_AREAS_LIST, {
    variables: { propertyId },
  });

  const columns = React.useMemo(
    () => [
      {
        Header: 'Name',
        accessor: 'name',
        width: 250,
        flexGrow: 0.5,
      },
      {
        Header: 'Description',
        accessor: 'description',
        width: 300,
      },
      {
        Header: 'Type',
        accessor: 'areaType.name',
        width: 300,
      },
      {
        id: 'status',
        Header: 'Status',
        accessor: row => getAreaStatus(row).name || row.status,
        Cell: ({ cell }) => (
          <Badge color={getAreaStatus(cell.row.original).color}>
            <EditableCell
              cell={cell}
              query={request.UPDATE_AREA}
              template={template}
              variableName="status"
            />
          </Badge>
        ),
        flexGrow: 0.5,
      },
      {
        id: 'accessSystemConfigId',
        Header: 'Access system',
        accessor: row => getAccessSystemConfigName(row),
        width: 250,
        flexGrow: 0.5,
      },
      {
        id: 'attributes',
        Header: 'Room ID',
        accessor: 'accessSystemArea.attributes.room_id',
        width: 250,
        flexGrow: 0.5,
      },
    ],
    [],
  );

  const getAreasList = ({ areasList }) =>
    areasList.map(area => {
      const { accessSystemAreasList } = area;
      let accessSystemArea = accessSystemAreasList.filter(
        a => a.areaId === area.id,
      )[0];
      return {
        ...area,
        accessSystemArea,
      };
    });

  return (
    <>
      <SectionHead propertyData={propertyData} title={title} links={links} />
      {loading && <Spinner color="secondary" />}
      {error && <ErrorLine error={error} />}
      {data && (
        <Table
          className="py-4"
          columns={columns}
          ExpandCard={Area}
          data={getAreasList(data)}
          options={{ disableFilters: true }}
        />
      )}
    </>
  );
};

export default Areas;
