import React, { useState } from 'react';
import { useLazyQuery } from '@apollo/client';
import { Spinner } from 'reactstrap';
import Table from '../../containers/table/table';
import ErrorLine from '../../containers/errorLine';
import SectionHead from '../sectionsHead';
import * as request from './requests';
import getZonedTime from '../../../lib/getZonedTime';
import DateFilter from '../../containers/table/dateFilter';
import BookingSetFilter from './bookingSetFilter';
import BookingSet from './bookingSet';
import getBookingSetList from './getBookingSetList';
import PaymentCell from './paymentCell';

const toDate = dateStr => new Date(dateStr);
const getBalance = row => -row.remaining;

const BookingSets = ({ propertyData, links, title }) => {
  const propertyId = +propertyData.property.id;
  const { propertySettings: { timezone } = {} } = propertyData.property || {};

  const [bookingSetsList, setBookingSetsList] = useState(null);

  const [getBookingsList, { loading, error, called }] = useLazyQuery(
    request.GET_BOOKING_SETS,
    {
      variables: { propertyId },
      fetchPolicy: 'network-only',
      onCompleted: data => {
        setBookingSetsList(getBookingSetList(data, timezone));
      },
    },
  );

  const columns = React.useMemo(
    () => [
      {
        id: 'reference',
        Header: 'Reference',
        accessor: 'reference',
        Cell: ({ cell }) => cell.value.map(ref => <div key={ref}>{ref}</div>),
        width: 160,
        flexGrow: 0.2,
      },
      {
        id: 'startDate',
        Header: 'Check-in',
        accessor: row => toDate(row.startDate),
        sortType: 'datetime',
        Cell: ({ cell: { value } }) => getZonedTime(value, timezone),
        Filter: DateFilter,
        filter: 'dateFilter',
        width: 180,
        flexGrow: 0.1,
      },
      {
        id: 'endDate',
        Header: 'Check-out',
        accessor: row => toDate(row.endDate),
        sortType: 'datetime',
        Cell: ({ cell: { value } }) => getZonedTime(value, timezone),
        Filter: DateFilter,
        filter: 'dateFilter',
        width: 180,
        flexGrow: 0.1,
      },
      {
        id: 'areas',
        Header: 'Areas',
        accessor: 'areas',
        width: 140,
        flexGrow: 0.3,
      },
      {
        id: 'preauths',
        Header: 'Pre-authorized',
        accessor: row => +row.preauthAmount,
        Cell: ({ cell }) => (
          <PaymentCell
            cell={cell}
            currencyId={cell.row.original.preauthCurrencyId}
          />
        ),
        width: 140,
        flexGrow: 0.2,
        textAlign: 'right',
      },
      {
        id: 'paid',
        Header: 'Paid',
        accessor: row => +row.paid,
        Cell: ({ cell }) => (
          <PaymentCell
            cell={cell}
            currencyId={cell.row.original.paymentCurrencyId}
          />
        ),
        width: 140,
        flexGrow: 0.2,
        textAlign: 'right',
      },
      {
        id: 'balance',
        Header: 'Balance',
        accessor: getBalance,
        Cell: ({ cell }) => (
          <PaymentCell
            cell={cell}
            currencyId={cell.row.original.paymentCurrencyId}
            balance
          />
        ),
        width: 140,
        flexGrow: 0.2,
        textAlign: 'right',
      },
    ],
    [timezone],
  );

  return (
    <>
      <SectionHead propertyData={propertyData} title={title} links={links} />
      <BookingSetFilter
        propertyId={propertyId}
        getBookingsList={getBookingsList}
        loading={loading}
        called={called}
      />
      {loading && <Spinner color="secondary" />}
      {error && <ErrorLine error={error} />}
      {!loading && bookingSetsList && (
        <Table
          className="py-4"
          columns={columns}
          data={bookingSetsList}
          ExpandCard={BookingSet}
        />
      )}
    </>
  );
};

export default BookingSets;
