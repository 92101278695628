import React from 'react';
import { UncontrolledAlert, Badge } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faTimes } from '@fortawesome/free-solid-svg-icons';

const Information = () => {
  return (
    <>
      <UncontrolledAlert color="info">
        Reservations can be filtered using the reference number or
        check-in/check-out date ranges. The default filter is the current
        check-out date.
      </UncontrolledAlert>
      <UncontrolledAlert color="info">
        <p>
          Preauthorization allows you to place a hold on some of the funds in a
          cardholder’s account. This is done to temporarily reserve an amount of
          money in the account to ensure that you get paid for services
          rendered.
          <br />
          The authorization <b>doesn’t charge the card</b>; that only occurs
          once you submit the transaction for settlement using the "Complete"
          button.
        </p>
        <ul>
          <li>
            In order to complete or settle the preauth, enter the amount and
            click the complete button -{' '}
            <Badge
              color="success"
              size="sm"
              style={{
                minHeight: '22px',
                minWidth: '22px',
              }}
            >
              <FontAwesomeIcon icon={faCheck} className="button-icon" />
            </Badge>
          </li>
          <li>
            To abort or cancel the preauth, simply click on the cancel button -{' '}
            <Badge
              color="light"
              size="sm"
              style={{
                backgroundColor: 'transparent',
                minHeight: '22px',
                minWidth: '22px',
                border: '1px solid',
                color: '#6c757d',
              }}
            >
              <FontAwesomeIcon icon={faTimes} className="button-icon" />
            </Badge>
          </li>
        </ul>
      </UncontrolledAlert>
    </>
  );
};

export default Information;
