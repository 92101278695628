import gqlTag from 'graphql-tag';

export const GET_FILTERED_BOOKING_SETS_LIST = gqlTag`
  query BookingSetsList(
    $propertyId: Int!
    $filter: BookingSetFilter = {}
    $preauthFilter: PreauthFilter
  ) {
    bookingSetsList(condition: { propertyId: $propertyId }, filter: $filter) {
      id
      startDate
      endDate
      reference
      preauthsList(filter: $preauthFilter) {
        amount
        id
        status
        currency {
          id
          alphabeticCode
        }
        preauthTransactionsList(orderBy: UPDATED_AT_DESC) {
          id
          statusMessage
          errorMessage
          amount
          type
          task {
            processed
            processingUntil
          }
        }
      }
      bookingsList {
        id
        area {
          id
          name
        }
        guestsList {
          id
          firstName
          lastName
          middleName
        }
      }
    }
  }
`;

export const GET_PREAUTH = gqlTag`
  query GetPreauth($id: Int!) {
    preauth(id: $id) {
      id
      preauthTransactionsList(orderBy: UPDATED_AT_DESC) {
        id
        statusMessage
        errorMessage
        amount
        type
        task {
          processed
          processingUntil
        }
        createdAt
      }
    }
  }
`;

export const COMPLETE_PREAUTH = gqlTag`
  mutation CompletePreauth($id: Int!, $amount: Int!) {
    completePreauth(input: { preauthId: $id, amount: $amount }) {
      preauth {
        id
      }
    }
  }
`;

export const ADJUST_PREAUTH = gqlTag`
  mutation AdjustPreauth($id: Int!, $amount: Int!) {
    adjustPreauth(input: { preauthId: $id, amount: $amount }) {
      preauth {
        id
      }
    }
  }
`;

export const CANCEL_PREAUTH = gqlTag`
  mutation CancelPreauth($id: Int!, $amount: Int!) {
    cancelPreauth(input: { preauthId: $id, amount: $amount }) {
      preauth {
        id
      }
    }
  }
`;

export const PREAUTH_TRANSACTION_CHANGES = gqlTag`
  subscription SubscribePreauth($preauthId: Int!) {
    preauthTransactionChanges(preauthId: $preauthId) {
      preauthTransaction {
        id
        preauthId
        reference
        statusMessage
        errorMessage
        amount
        type
        success
        task {
          processed
          processingUntil
        }
        preauth {
          id
          status
        }
      }
      event
    }
  }
`;
