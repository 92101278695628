import gqlTag from 'graphql-tag';

export const GET_PREAUTH_CONFIG = gqlTag`
  query property($propertyId: Int!) {
    property(id: $propertyId) {
      id
      propertyConfig {
        id
        preauthAmount
        preauthCurrencyId
        preauthType
      }
    }
    currenciesList {
      id
      alphabeticCode
    }
  }
`;

export const UPDATE_PREAUTH_CONFIG = gqlTag`
  mutation UpdatePropertyConfig(
    $configId: Int!
    $preauthAmount: Int
    $preauthCurrencyId: Int
    $preauthType: BitString
  ) {
    updatePropertyConfig(
      input: {
        patch: {
          preauthAmount: $preauthAmount
          preauthCurrencyId: $preauthCurrencyId
          preauthType: $preauthType
        }
        id: $configId
      }
    ) {
      propertyConfig {
        id
        preauthAmount
        preauthCurrencyId
        preauthType
      }
    }
  }
`;
