import { Widget } from '../form/formTemplate';

export const schema = {
  type: 'object',
  title: 'Information',
  required: ['name', 'description', 'status', 'address'],
  properties: {
    name: {
      type: 'string',
      title: 'Name',
    },
    propertyTypeId: {
      type: 'number',
      title: 'Property Type',
      enum: [],
      enumNames: [],
    },
    status: {
      type: 'string',
      title: 'Status',
      enum: ['ACTIVE', 'BLOCKED', 'DELETED', 'SUSPENDED'],
    },
    address: {
      type: 'string',
      title: 'Address',
    },
    description: {
      type: 'string',
      title: 'Description',
    },
    logo: {
      title: 'Property logo',
      type: 'string',
    },
  },
};

export const uiSchema = {
  'ui:ObjectFieldTemplate': Widget,
  'address': {
    'ui:widget': 'textarea',
  },
  'description': {
    'ui:widget': 'textarea',
    'ui:options': {
      rows: 10,
    },
  },
  'logo': {
    'ui:widget': 'AddImage',
    'ui:options': {
      label: false,
    },
  },
};
