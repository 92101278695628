import { set, cloneDeep } from 'lodash';
import hasSchema from '../../../lib/hasSchema';

const insertConfigSchemas = (schema, uiSchema, accessSystems) => {
  const newSchema = cloneDeep(schema);
  const newUiSchema = cloneDeep(uiSchema);

  const oneOf = [];

  accessSystems.forEach(as => {
    const id = +as.id;

    let {
      accessSystemConfigTemplate: {
        schema: asSchema = {},
        uiSchema: asUiSchema = {},
      } = {},
    } = as;

    if (!hasSchema(asSchema, 'properties')) {
      asSchema = {
        type: 'string',
        title: 'Config',
        default: '{}',
        description: 'JSON object',
      };
      asUiSchema = {
        'ui:widget': 'textarea',
        'ui:options': {
          rows: 5,
        },
      };
    }

    oneOf.push({
      properties: {
        accessSystemId: {
          enum: [id],
        },
        [`config${id}`]: { title: 'Config', ...asSchema },
      },
    });

    set(newUiSchema, `config${id}`, asUiSchema);
  });
  set(newSchema, 'dependencies.accessSystemId.oneOf', oneOf);

  return { schema: newSchema, uiSchema: newUiSchema };
};

export default insertConfigSchemas;
