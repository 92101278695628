import {
  Badge,
  ListGroupItem,
  Modal,
  ModalBody,
  ModalHeader,
} from 'reactstrap';
import React, { useState } from 'react';
import getCurrency from './getCurrency';
import PreauthTransactionsList from '../preauth/preauthTransactionsList';
import getStatus from './getStatus';
import preauthStatuses from '../preauth/preauthStatuses';

const PreauthsListItem = ({ data, currenciesList }) => {
  const { id, amount, status, currencyId } = data;
  const preauthStatus = getStatus(preauthStatuses, status);

  const [modal, setModal] = useState(false);
  const toggle = () => {
    if (id) setModal(!modal);
  };

  return (
    <ListGroupItem onClick={toggle} action={!!id}>
      <div className="d-flex flex-row flex-wrap align-items-center justify-content-between booking-set-list-item">
        <h5 className="m-0 mr-3">
          <Badge color="light">
            <>
              <span>{+amount}</span>
              {!!+amount && (
                <span className="ml-2 font-weight-normal">
                  {getCurrency(currenciesList, currencyId)}
                </span>
              )}
            </>
          </Badge>
        </h5>
        <Badge color={preauthStatus.color || 'secondary'}>
          {preauthStatus.name || status}
        </Badge>
      </div>
      <Modal
        isOpen={modal}
        toggle={toggle}
        returnFocusAfterClose={false}
        size="lg"
      >
        <ModalHeader toggle={toggle}>Transactions</ModalHeader>
        <ModalBody>
          <PreauthTransactionsList row={{ original: { id } }} />
        </ModalBody>
      </Modal>
    </ListGroupItem>
  );
};

export default PreauthsListItem;
