import React, { useRef, useCallback } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import Flatpickr from 'react-flatpickr';
import 'flatpickr/dist/themes/light.css';
import { Input, Button, InputGroup, InputGroupAddon } from 'reactstrap';
import formatDate from '../../../lib/formatDate';

const DateFilter = ({
  column: { filterValue, preFilteredRows, setFilter },
}) => {
  const count = preFilteredRows.length;

  const flatpickrRef = useRef(null);

  const clearDate = useCallback(() => {
    if (flatpickrRef.current) {
      flatpickrRef.current.flatpickr.clear();
    }
  }, [flatpickrRef]);

  return (
    <>
      <Flatpickr
        onChange={e => {
          setFilter(e[0] || undefined);
        }}
        placeholder={`Search ${count} records...`}
        ref={flatpickrRef}
        options={{
          formatDate,
          locale: {
            firstDayOfWeek: 1,
          },
        }}
        render={({ defaultValue, value, ...props }, ref) => {
          return (
            <InputGroup size="sm" className="mb-2">
              <Input
                defaultValue={defaultValue}
                innerRef={ref}
                bsSize="sm"
                style={{ boxShadow: 'none', backgroundColor: '#fff' }}
                {...props}
              />
              {filterValue && (
                <InputGroupAddon addonType="append">
                  <Button onClick={clearDate} outline>
                    <FontAwesomeIcon icon={faTimes} className="button-icon" />
                  </Button>
                </InputGroupAddon>
              )}
            </InputGroup>
          );
        }}
      />
    </>
  );
};

export default DateFilter;
