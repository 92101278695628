import React, { useState } from 'react';
import { useMutation } from '@apollo/client';
import { Spinner, Button } from 'reactstrap';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import FormWrapper from '../../../form/formWrapper';
import submit from '../../../form/syntheticSubmit';
import usePreauthSubscription from './usePreauthSubscription';
import * as requests from './requests';

const schema = {
  type: 'object',
  properties: {
    amount: {
      type: 'integer',
      title: '',
    },
  },
};
const uiSchema = {
  amount: {
    'classNames': 'input-group-sm',
    'ui:options': {
      label: false,
    },
  },
};

const AdjustPreauth = ({ cell }) => {
  const cellId = `cell-${cell.getCellProps().key}`;
  const [form, setForm] = useState(null);

  const { id: preauthId } = cell.row.original;

  const [skipPreauthSubscription, setSkipPreauthSubscription] = useState(true);

  const [update, { loading: loadingAdjust }] = useMutation(
    requests.ADJUST_PREAUTH,
    {
      onError: error => {
        cell.row.setState(state => ({
          ...state,
          alert: { color: 'danger', message: `Error: ${error.message}` },
        }));
        setSkipPreauthSubscription(true);
        cell.row.setState(state => ({ ...state, disabled: false }));
      },
      onCompleted: () => {
        setSkipPreauthSubscription(false);
        cell.row.setState(state => ({ ...state, disabled: true }));
      },
    },
  );

  const onSubmit = data => {
    update({
      variables: {
        id: preauthId,
        amount: data.amount,
      },
    });
  };

  usePreauthSubscription(
    cell.row.setState,
    preauthId,
    skipPreauthSubscription,
    setSkipPreauthSubscription,
  );

  const loading = loadingAdjust || !skipPreauthSubscription;

  return (
    <span onClick={e => e.stopPropagation()}>
      {cell.row.state.active && (
        <div className="inline-form-sm" style={{ maxWidth: '240px' }}>
          <div style={{ width: 'calc(100% - 40px)' }}>
            <FormWrapper
              formData={{ amount: 0, id: preauthId }}
              subjectName="AdjustPreauth"
              loading={loading}
              schemaSource={schema}
              uiSchemaSource={uiSchema}
              onSubmit={onSubmit}
              setForm={setForm}
              formKey={cellId}
              disabled={cell.row.state.disabled}
              children={' '}
            />
          </div>
          {loading && <Spinner size="sm" />}
          {!loading && (
            <Button
              color="success"
              style={{ boxShadow: 'none' }}
              onClick={() => submit(form)}
              size="sm"
              className="mr-2"
              disabled={cell.row.state.disabled}
            >
              <FontAwesomeIcon icon={faCheck} className="button-icon" />
            </Button>
          )}
        </div>
      )}
    </span>
  );
};

export default AdjustPreauth;
