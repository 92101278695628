import { useMutation } from '@apollo/client';
import * as request from './paymentSystemsRequests';

const useRemoveCurrency = propertyId => {
  const [deleteCurrency, { loading, error }] = useMutation(
    request.DELETE_CURRENCY,
    {
      refetchQueries: [
        {
          query: request.GET_DEVICES,
          variables: { propertyId },
        },
      ],
    },
  );

  const removeCurrency = id => {
    const variables = { id };
    deleteCurrency({ variables });
  };

  return { removeCurrency, error, loading };
};

export default useRemoveCurrency;
