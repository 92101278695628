import React from 'react';
import Table from '../../containers/table/table';
import getZonedTime from '../../../lib/getZonedTime';
import DateFilter from '../../containers/table/dateFilter';
import PreauthTransactionsList from './preauthTransactionsList';
import CancelPreauth from './cancelPreauth';
import AdjustPreauth from './adjustPreauth';
import CompletePreauth from './completePreauth';
import { Badge } from 'reactstrap';
import preauthStatuses from './preauthStatuses';
import isActive from './isActive';

const toDate = dateStr => new Date(dateStr);
const getAmountWithCurrency = row => `${+row.amount} ${row.currency}`;
const getPreauthStatus = row =>
  preauthStatuses.filter(status => status.id === row.status)[0] || {};

const PreauthList = ({ data = [], property }) => {
  const { propertySettings: { timezone } = {} } = property || {};

  const columns = React.useMemo(
    () => [
      {
        Header: 'id',
        accessor: 'id',
        width: 80,
        flexGrow: 0,
      },
      {
        id: 'reference',
        Header: 'Reference',
        accessor: row => (row.reference || []).join(',\n'),
        width: 120,
        flexGrow: 0.2,
      },
      {
        id: 'startDate',
        Header: 'Check-in',
        accessor: row => toDate(row.startDate),
        sortType: 'datetime',
        Cell: ({ cell: { value } }) => getZonedTime(value, timezone),
        Filter: DateFilter,
        filter: 'dateFilter',
        width: 120,
        flexGrow: 0.2,
      },
      {
        id: 'endDate',
        Header: 'Check-out',
        accessor: row => toDate(row.endDate),
        sortType: 'datetime',
        Cell: ({ cell: { value } }) => getZonedTime(value, timezone),
        Filter: DateFilter,
        filter: 'dateFilter',
        width: 120,
        flexGrow: 0.2,
      },
      {
        Header: 'Room',
        accessor: 'areaName',
        width: 80,
        flexGrow: 0.1,
      },
      {
        Header: 'Guest name',
        accessor: 'guestName',
        width: 120,
        flexGrow: 0.5,
      },
      {
        id: 'status',
        Header: 'Status',
        accessor: row => getPreauthStatus(row).name || row.status,
        Cell: ({ cell }) => (
          <Badge
            color={getPreauthStatus(cell.row.original).color || 'secondary'}
          >
            {cell.value}
          </Badge>
        ),
        width: 100,
        flexGrow: 0.1,
      },
      {
        Header: 'Amount',
        accessor: getAmountWithCurrency,
        width: 80,
        flexGrow: 0.1,
      },
      {
        id: 'cancel',
        Header: 'Cancel',
        flexGrow: 0,
        width: 64,
        disableFilters: true,
        Cell: ({ cell }) => <CancelPreauth cell={cell} />,
      },
      {
        id: 'adjust',
        Header: 'Adjust',
        flexGrow: 0.1,
        width: 120,
        disableFilters: true,
        Cell: ({ cell }) => <AdjustPreauth cell={cell} />,
      },
      {
        id: 'complete',
        Header: 'Complete',
        flexGrow: 0.1,
        width: 120,
        disableFilters: true,
        Cell: ({ cell }) => <CompletePreauth cell={cell} />,
      },
    ],
    [timezone],
  );

  return (
    <Table
      className="py-4"
      columns={columns}
      ExpandCard={PreauthTransactionsList}
      data={data}
      initialState={{ hiddenColumns: ['id'] }}
      options={{
        initialRowStateAccessor: row => ({
          ...row.original.initialState,
          active: isActive(row.original.status),
        }),
      }}
    />
  );
};

export default PreauthList;
