const getPreauthList = bookingSetsList => {
  const preauths = [];

  bookingSetsList.forEach(bs => {
    const {
      reference,
      startDate,
      endDate,
      bookingsList: [booking] = [],
      preauthsList = [],
    } = bs || {};
    const { area: { name: areaName } = {}, guestsList: [guest] = [] } =
      booking || {};
    const { firstName, lastName } = guest || {};

    const _preauths_ = preauthsList.map(pa => {
      const { id, amount, status, currency: { alphabeticCode } = {} } = pa;
      return {
        id,
        reference,
        startDate,
        endDate,
        areaName,
        guestName: `${firstName} ${lastName}`.trim(),
        amount: amount,
        currency: alphabeticCode,
        status,
      };
    });

    preauths.push(..._preauths_);
  });

  return preauths;
};

export default getPreauthList;
