import gqlTag from 'graphql-tag';

export const GET_PAYMENTS = gqlTag`
  query BookingsList(
    $propertyId: Int!
    $bookingSetFilter: BookingSetFilter
    $paymentFilter: PaymentFilter
  ) {
    bookingSetsList(
      condition: { propertyId: $propertyId }
      filter: $bookingSetFilter
    ) {
      id
      startDate
      endDate
      reference
      billsList {
        id
        paymentsList(filter: $paymentFilter) {
          id
          deviceId
          paidAt
          paymentNetwork {
            id
            displayName
          }
          pmsPaymentId
          posMessage
          sentToPms
          status
          amount
          currency {
            alphabeticCode
            id
          }
        }
      }
      bookingsList {
        id
        guestsList {
          id
          lastName
          firstName
        }
      }
    }
  }
`;

export const GET_PAYMENT = gqlTag`
  query Payment(
    $paymentId: Int!
  ) {
    payment(id: $paymentId) {
      id
      deviceId
      paidAt
      paymentNetwork {
        id
        displayName
      }
      pmsPaymentId
      posMessage
      sentToPms
      status
      amount
      currency {
        alphabeticCode
        id
      }
      maskedCardNumber
      receipt
      receiptMerchant
    }
  }
`;
