import React, { useState } from 'react';
import { useLazyQuery } from '@apollo/client';
import { Spinner } from 'reactstrap';
import ErrorLine from '../../containers/errorLine';
import SectionHead from '../sectionsHead';
import * as requests from './requests';
import PreauthList from './preauthsList';
import PreauthsFilter from './preauthsFilter';
import getPreauthsList from './getPreauthsList';
import Information from './information';

const Preauth = ({ propertyData, title, links }) => {
  const propertyId = +propertyData.property.id;

  const [preauthsList, setPreauthsList] = useState(null);

  const [getBookingsList, { loading, error, called }] = useLazyQuery(
    requests.GET_FILTERED_BOOKING_SETS_LIST,
    {
      variables: { propertyId },
      fetchPolicy: 'network-only',
      onCompleted: data => {
        setPreauthsList(getPreauthsList(data.bookingSetsList));
      },
    },
  );

  return (
    <>
      <SectionHead propertyData={propertyData} title={title} links={links} />
      <PreauthsFilter
        propertyId={propertyId}
        getBookingsList={getBookingsList}
        called={called}
        loading={loading}
      />
      <Information />
      {loading && <Spinner color="secondary" />}
      {error && <ErrorLine error={error} />}
      {!loading && preauthsList && (
        <PreauthList property={propertyData.property} data={preauthsList} />
      )}
    </>
  );
};

export default Preauth;
