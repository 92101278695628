import gqlTag from 'graphql-tag';

export const GET_PROPERTY = gqlTag`
  query property($propertyId: Int!) {
    property(id: $propertyId) {
      id
      name
      status
      description
      address
      propertyTypeId
      propertySettings {
        id
        logo
      }
    }
    propertyTypesList {
      id
      name
    }
  }
`;

export const UPDATE_PROPERTY = gqlTag`
  mutation UpdateProperty(
    $propertyId: Int!
    $propertyTypeId: Int
    $name: String
    $status: PropertyStatus
    $description: String
    $address: JSON
  ) {
    updateProperty(
      input: {
        patch: {
          propertyTypeId: $propertyTypeId
          name: $name
          status: $status
          description: $description
          address: $address
        }
        id: $propertyId
      }
    ) {
      property {
        id
        propertyType {
          id
          name
        }
        name
        status
        description
        address
      }
    }
  }
`;

export const UPDATE_LOGO = gqlTag`
  mutation UpdatePropertySetting(
    $settingsId: Int!
    $logo: String
  ) {
    updatePropertySetting(
      input: {
        patch: {
          logo: $logo
        }
        id: $settingsId
      }
    ) {
      propertySetting {
        id
        logo
      }
    }
  }
`;
