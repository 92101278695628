import Areas from './areas/areas';
import AreaTypes from './areaTypes';
import GuestsTable from './guests/guests';
import AccessSystems from './accessSystems/accessSystems';
import Devices from './devices/devices';
import Messaging from './messaging/messagingTemplates';
import GuestFields from './guestFields/guestFields';
import PaymentSystemDevices from './paymentSystemDevices/paymentSystemDevices';
import PaymentNetworks from './paymentNetworks';
import Preauth from './preauth';
import Settings from './settings';
import PmsConfig from './pmsConfig';
import VoucherTemplate from './voucherTemplate';
import Payments from './payments';
import PreauthConfig from './preauthConfig';
import PropertyInformation from './information';
import BookingSets from './bookingSets';
import {
  faHotel,
  faReceipt,
  faDoorClosed,
  faKey,
  faTv,
  faCashRegister,
  faMoneyBillWave,
  faCreditCard,
  faAddressCard,
  faMailBulk,
  faUsers,
  faServer,
  faCog,
  faBed,
  faMoneyCheckAlt,
  faMoneyCheck,
  faCalendarCheck,
} from '@fortawesome/free-solid-svg-icons';

const routes = [
  {
    title: 'Property',
    icon: faHotel,
    path: 'information',
    Route: PropertyInformation,
  },
  {
    title: 'Settings',
    path: 'settings',
    icon: faCog,
    Route: Settings,
  },
  {
    title: 'PMS configuration',
    path: 'pms-config',
    icon: faServer,
    Route: PmsConfig,
  },
  {
    title: 'Pre-authorization configuration',
    path: 'preauth-config',
    icon: faMoneyCheck,
    Route: PreauthConfig,
  },
  {
    title: 'Voucher template',
    path: 'voucher-template',
    icon: faReceipt,
    Route: VoucherTemplate,
  },
  {
    title: 'Registration form',
    path: 'guest-fields',
    icon: faAddressCard,
    Route: GuestFields,
  },
  { title: 'Messaging', path: 'messaging', icon: faMailBulk, Route: Messaging },
  {
    title: 'Bookings',
    path: 'booking-sets',
    icon: faCalendarCheck,
    Route: BookingSets,
  },
  { title: 'Guests', path: 'guests', icon: faUsers, Route: GuestsTable },
  {
    title: 'Payments',
    path: 'payments',
    icon: faMoneyBillWave,
    Route: Payments,
  },
  {
    title: 'Pre-authorization',
    path: 'preauth',
    icon: faMoneyCheckAlt,
    Route: Preauth,
  },
  { title: 'Areas', path: 'areas', icon: faBed, Route: Areas },
  { title: 'Area types', path: 'types', icon: faDoorClosed, Route: AreaTypes },
  {
    title: 'Access systems',
    path: 'access-systems',
    icon: faKey,
    Route: AccessSystems,
  },
  { title: 'Devices', path: 'devices', icon: faTv, Route: Devices },
  {
    title: 'Payment system devices',
    path: 'payment-system-devices',
    icon: faCashRegister,
    Route: PaymentSystemDevices,
  },
  {
    title: 'Payment networks',
    path: 'payment-networks',
    icon: faCreditCard,
    Route: PaymentNetworks,
  },
];

export default routes;
