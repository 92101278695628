import React, { useState } from 'react';
import {
  schema as schemaSource,
  uiSchema as uiSchemaSource,
} from '../../../schemas/voucherTemplate';
import FormWrapper from '../../../form/formWrapper';
import { useQuery } from '@apollo/client';
import * as requests from './requests';
import SectionHead from '../sectionsHead';
import { Spinner } from 'reactstrap';
import ErrorLine from '../../containers/errorLine';
import useOnSubmit from './useOnSubmit';

const VoucherTemplate = ({ data, propertyId }) => {
  const subjectName = 'VoucherTemplate';

  const { voucherTemplatesList } = data || {};
  const [voucherTemplate] = voucherTemplatesList || [];
  const { template = '' } = voucherTemplate || {};

  const [complete, setComplete] = useState(null);
  const [formKey, setFormKey] = useState(1);

  const onComplete = () => {
    setComplete(true);
    setFormKey(formKey + 1);
  };

  const { saveVoucher, error, loading } = useOnSubmit({
    propertyId,
    onComplete,
  });

  const onSubmit = data => {
    const { template } = data;
    saveVoucher({ variables: { template } });
  };

  const success = complete && !loading && !error;

  return (
    <FormWrapper
      formData={{ template }}
      subjectName={subjectName}
      loading={loading}
      schemaSource={schemaSource}
      uiSchemaSource={uiSchemaSource}
      onSubmit={onSubmit}
      formKey={formKey}
      formContext={{ loading, success, error }}
      children={' '}
    />
  );
};

const VoucherTemplateContainer = ({ propertyData, title, links }) => {
  const propertyId = +propertyData.property.id;

  const { data, loading, error } = useQuery(requests.GET_VOUCHER_TEMPLATE, {
    variables: { propertyId },
    fetchPolicy: 'network-only',
  });

  return (
    <div className="limited-container">
      <SectionHead propertyData={propertyData} title={title} links={links} />
      {loading && <Spinner color="secondary" />}
      {error && <ErrorLine error={error} />}
      {data && <VoucherTemplate data={data} propertyId={propertyId} />}
    </div>
  );
};

export default VoucherTemplateContainer;
