import React from 'react';
import { Spinner, Table } from 'reactstrap';
import { useQuery } from '@apollo/client';
import * as requests from './requests';
import ErrorLine from '../../containers/errorLine';
import getZonedTime from '../../../lib/getZonedTime';

const transactionsColumns = [
  { id: 'id', title: 'ID' },
  { id: 'amount', title: 'Amount' },
  { id: 'statusMessage', title: 'Status' },
  { id: 'errorMessage', title: 'Error' },
  { id: 'type', title: 'Type' },
  { id: 'createdAt', title: 'Transaction date', transform: getZonedTime },
];

const Transactions = ({ data }) => (
  <>
    {data.length === 0 ? (
      <p>None records</p>
    ) : (
      <Table size="sm" bordered hover>
        <thead>
          <tr>
            {transactionsColumns.map(c => (
              <th key={`column-${c.id}`}>{c.title}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {data.map((transaction, i) => (
            <tr key={`transactions-${i}`}>
              {transactionsColumns.map(({ id, transform }) => {
                const el = transaction[id];
                const v = transform ? transform(el) : el;
                return <td key={`transaction-${i}-${id}`}>{v}</td>;
              })}
            </tr>
          ))}
        </tbody>
      </Table>
    )}
  </>
);

const PreauthTransactionsList = ({ row }) => {
  const preauthId = row.original.id;

  const { loading, error, data } = useQuery(requests.GET_PREAUTH, {
    variables: { id: preauthId },
    fetchPolicy: 'network-only',
  });

  return (
    <>
      {loading && <Spinner color="secondary" />}
      {error && <ErrorLine error={error} />}
      {data &&
        (data.preauth ? (
          <Transactions data={data.preauth.preauthTransactionsList || []} />
        ) : (
          <p>None records</p>
        ))}
    </>
  );
};

export default PreauthTransactionsList;
