import { Widget } from '../form/formTemplate';

export const schema = {
  type: 'object',
  title: 'Settings',
  properties: {
    payment: {
      type: 'boolean',
      title: 'Payment',
      description:
        'Enable payment. Requires a payment system to be configured.',
      default: false,
      enum: [true, false],
    },
    guestRegistration: {
      type: 'boolean',
      title: 'Guest registration',
      description:
        "Enable guest registration on the kiosk. If disabled the kiosk doesn't prompt the guest to enter any details.",
      default: false,
      enum: [true, false],
    },
    documentScan: {
      type: 'boolean',
      title: 'Document scan',
      description: 'Enable the document scanner',
      default: false,
      enum: [true, false],
    },
    accessSystem: {
      type: 'boolean',
      title: 'Access system',
      description: 'Allow the issuance of key cards.',
      default: false,
      enum: [true, false],
    },
    duplicateKey: {
      type: 'boolean',
      title: 'Duplicate key',
      description:
        "Allow dispensing duplicate key cards. Shows a 'Lost Card' button once the guest is already checked in.",
      default: false,
      enum: [true, false],
    },
    preauth: {
      type: 'boolean',
      title: 'Pre-authorization',
      description:
        "Pre-authorization of the guest's credit card before check-in. Requires a compatible payment system.",
      default: false,
      enum: [true, false],
    },
    earlyCheckin: {
      type: 'boolean',
      title: 'Early check-in',
      description:
        'Enabling this requires your PMS to support room status and will also force the kiosk to verify that the room that\'s currently being checked into is clean. Furthermore the kiosk will use "Default check-in time" set below regardless of the check-in time specified in the reservation.',
      default: false,
      enum: [true, false],
    },
    mandatoryGuestRegistration: {
      type: 'boolean',
      title: 'Mandatory guest registration',
      description:
        'Require registration of all guests before allowing issuance of key cards.',
      default: false,
      enum: [true, false],
    },
    // startPage: {
    //   type: 'boolean',
    //   title: 'Start page',
    //   default: false,
    //   enum: [true, false],
    // },
    timezone: {
      type: 'string',
      title: 'Timezone',
      enum: [],
    },
    checkinCheckoutPolicyStart: {
      type: 'string',
      title: 'Default check-in time',
    },
    checkinCheckoutPolicyEnd: {
      type: 'string',
      title: 'Default check-out time',
    },
    email: {
      type: 'string',
      title: 'email',
    },
    im: {
      type: 'string',
      title: 'im',
    },
    phone: {
      type: 'string',
      title: 'Phone',
    },
  },
};

const switchWidget = {
  'ui:widget': 'Switch',
  'ui:options': {
    label: false,
  },
};

export const uiSchema = {
  'checkinCheckoutPolicyStart': {
    'ui:widget': 'TimePicker',
  },
  'checkinCheckoutPolicyEnd': {
    'ui:widget': 'TimePicker',
  },
  'preauth': switchWidget,
  'payment': switchWidget,
  'guestRegistration': switchWidget,
  'documentScan': switchWidget,
  'accessSystem': switchWidget,
  'duplicateKey': switchWidget,
  'earlyCheckin': switchWidget,
  'mandatoryGuestRegistration': switchWidget,
  // 'startPage': switchWidget,
  'ui:ObjectFieldTemplate': Widget,
};
