import getCurrency from './getCurrency';
import { Badge } from 'reactstrap';
import React from 'react';

const PaymentCell = ({ cell, currencyId, balance }) => {
  const currenciesList = cell.row.original.currenciesList;
  const currency = getCurrency(currenciesList, currencyId);
  let badgeColor = 'light';
  let textColor = '';
  if (balance) {
    if (cell.value < 0) {
      textColor = 'text-danger';
    }
    if (cell.value > 0) badgeColor = 'warning';
  }
  return (
    <h5 className="m-0">
      <Badge color={badgeColor} className={textColor}>
        {(!!cell.value || balance) && (
          <>
            <span>{cell.value}</span>
            {!!cell.value && (
              <span className="ml-2 font-weight-normal">{currency}</span>
            )}
          </>
        )}
      </Badge>
    </h5>
  );
};

export default PaymentCell;
