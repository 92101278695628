import React, { useState } from 'react';
import {
  Button,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  ListGroup,
  ListGroupItem,
  Spinner,
} from 'reactstrap';
import ErrorLine from './errorLine';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';

const ItemListWithSelect = ({
  holderItems = [],
  itemsList,
  title,
  selectButtonName,
  addItem,
  removeItem,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const toggle = () => setIsOpen(!isOpen);
  const [removingItemId, setRemovingItemId] = useState();
  const { addItemSubmit, addError, addLoading } = addItem;
  const { removeItemSubmit, removeError, removeLoading } = removeItem;

  const handleOnClick = id => {
    setRemovingItemId(id);
    removeItemSubmit(id);
  };

  return (
    <div style={{ marginTop: '-16px' }}>
      <p className="mb-2">{title}</p>
      <Dropdown className="mb-2" isOpen={isOpen} toggle={toggle}>
        {itemsList.length > 0 && (
          <>
            <DropdownToggle caret size="sm">
              {selectButtonName}
            </DropdownToggle>
            <DropdownMenu
              modifiers={{
                setMaxHeight: {
                  enabled: true,
                  order: 890,
                  fn: data => {
                    return {
                      ...data,
                      styles: {
                        ...data.styles,
                        overflow: 'auto',
                        maxHeight: 270,
                      },
                    };
                  },
                },
              }}
            >
              {itemsList.map(({ id, name }, i) => (
                <DropdownItem
                  key={`${id}-${i}`}
                  onClick={() => addItemSubmit(id)}
                >
                  {name}
                </DropdownItem>
              ))}
            </DropdownMenu>{' '}
          </>
        )}
        {addLoading && <Spinner size="sm" color="secondary" />}
        {addError && <ErrorLine error={addError} />}
      </Dropdown>
      <div
        className="mb-4 border"
        style={{ maxHeight: '224px', overflow: 'auto', borderRadius: '4px' }}
      >
        <ListGroup flush>
          {holderItems.length === 0 ? (
            <ListGroupItem>No items</ListGroupItem>
          ) : (
            <>
              {holderItems.map((item, i) => {
                return (
                  <ListGroupItem key={`property-${i}`}>
                    <div className="d-flex flex-row justify-content-between align-items-start">
                      <span>{item.name}</span>
                      <div>
                        {removeError && item.id === removingItemId && (
                          <span className="text-danger">
                            {removeError.message}
                          </span>
                        )}
                        {removeLoading && item.id === removingItemId && (
                          <Spinner size="sm" color="secondary" />
                        )}{' '}
                        <Button
                          style={{
                            height: '24px',
                            width: '24px',
                            padding: '0',
                            border: '0',
                          }}
                          onClick={() => handleOnClick(item.id)}
                          color="secondary"
                          outline
                        >
                          <FontAwesomeIcon icon={faTimes} />
                        </Button>
                      </div>
                    </div>
                  </ListGroupItem>
                );
              })}
            </>
          )}
        </ListGroup>
      </div>
    </div>
  );
};

export default ItemListWithSelect;
