import { Widget } from '../form/formTemplate';

export const schema = {
  type: 'object',
  title: 'PMS configuration',
  required: ['pmsId'],
  properties: {
    pmsPropertyId: {
      type: ['string', 'null'],
      title: 'PMS property id',
    },
    countryId: {
      type: 'number',
      title: 'Country',
      enum: [],
    },
    pmsId: {
      type: 'number',
      title: 'PMS',
      enum: [],
    },
    syncSwitch: {
      type: 'boolean',
      title: 'Sync',
      enum: [true, false],
    },
  },
  dependencies: {
    syncSwitch: {
      oneOf: [
        {
          properties: {
            syncSwitch: {
              enum: [true],
            },
            sync: {
              type: 'object',
              title: '',
              properties: {
                areas: {
                  type: 'number',
                  title: 'Areas',
                },
                bookings: {
                  type: 'number',
                  title: 'Bookings',
                },
              },
            },
          },
        },
        {
          properties: {
            syncSwitch: {
              enum: [false],
            },
          },
        },
      ],
    },
  },
};

export const uiSchema = {
  'ui:ObjectFieldTemplate': Widget,
  'syncSwitch': {
    'ui:widget': 'Switch',
    'ui:options': {
      label: false,
    },
  },
};
