import React from 'react';
import { Link } from 'gatsby';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCog } from '@fortawesome/free-solid-svg-icons';
import '../../../styles/sectionCard.css';

const SectionCard = ({ title, icon, to }) => {
  return (
    <div className="section-card col-6 col-md-4 col-lg-3 col-xl-2 p-0">
      <Link to={to}>
        <div className="property-section-card-body border-right border-bottom d-flex flex-column flex-sm-row align-items-center text-center text-sm-left p-2">
          <div className="section-card-icon">
            <FontAwesomeIcon
              icon={icon || faCog}
              className="m-2 mr-3"
              size="2x"
            />
          </div>
          <span className="section-card-title">{title}</span>
        </div>
      </Link>
    </div>
  );
};

export default SectionCard;
