const deviceStatuses = [
  {
    id: 'ONLINE',
    name: 'Online',
    color: 'success',
  },
  {
    id: 'UNAVAILABLE',
    name: 'Unavailable',
    color: 'danger',
  },
  {
    id: 'DISABLED',
    name: 'Disabled',
    color: 'secondary',
  },
  {
    id: 'OUT_OF_SERVICE',
    name: 'Out of service',
    color: 'warning',
  },
  {
    id: 'BLOCKED',
    name: 'Blocked',
    color: 'danger',
  },
];

export default deviceStatuses;
