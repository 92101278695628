const submit = form => {
  if (form && form.formElement) {
    form.formElement.dispatchEvent(
      new CustomEvent('submit', {
        bubbles: true,
        cancelable: true,
      }),
    );
  }
};
export default submit;
