const paymentStatuses = [
  {
    id: 'PENDING',
    name: 'Pending',
    color: 'secondary',
  },
  {
    id: 'PROCESSING',
    name: 'Processing',
    color: 'secondary',
  },
  {
    id: 'FAILED',
    name: 'Failed',
    color: 'danger',
  },
  {
    id: 'REJECTED',
    name: 'Rejected',
    color: 'danger',
  },
  {
    id: 'SUCCESS',
    name: 'Success',
    color: 'success',
  },
  {
    id: 'PAID_OUT',
    name: 'Paid out',
    color: 'success',
  },
  {
    id: 'INITIATED',
    name: 'Initiated',
    color: 'warning',
  },
];

export default paymentStatuses;
