import React from 'react';
import { useQuery } from '@apollo/client';
import { Badge, Spinner } from 'reactstrap';
import Table from '../../containers/table/table';
import ErrorLine from '../../containers/errorLine';
import SectionHead from '../sectionsHead';
import * as requests from './guestFieldsRequests';
import EditableCell from '../../containers/table/editableCell';
import propertyStatuses from './propertyStatuses';
import SwitchCell from '../../containers/table/switchCell';

const template = {
  schema: {
    type: 'object',
    properties: {
      status: {
        type: 'string',
        title: '',
        default: propertyStatuses[0].id,
        enum: propertyStatuses.map(s => s.id),
        enumNames: propertyStatuses.map(s => s.name),
      },
    },
  },
  uiSchema: {
    status: {
      'ui:options': {
        label: false,
      },
    },
  },
};

const getPropertyStatus = row =>
  propertyStatuses.filter(status => status.id === row.status)[0] || {};

const GuestFields = ({ propertyData, title, links }) => {
  const propertyId = +propertyData.property.id;

  const { loading, error, data } = useQuery(requests.GET_GUEST_FIELDS_LIST, {
    variables: { propertyId },
  });

  const columns = React.useMemo(
    () => [
      {
        Header: 'id',
        accessor: 'id',
        width: 80,
        flexGrow: 0,
      },
      {
        Header: 'Field name',
        accessor: 'name',
        width: 300,
        flexGrow: 1,
      },
      {
        Header: 'Column',
        accessor: 'guestColumn',
        flexGrow: 1,
      },
      {
        Header: 'Status',
        accessor: 'status',
        Cell: ({ cell }) => (
          <Badge
            color={getPropertyStatus(cell.row.original).color}
            style={{ cursor: 'pointer' }}
          >
            <EditableCell
              cell={cell}
              variableName="status"
              options={propertyStatuses}
              query={requests.UPDATE_GUEST_FIELD_STATUS}
              template={template}
            />
          </Badge>
        ),
        width: 100,
        flexGrow: 0.2,
      },
      {
        Header: 'Enabled',
        accessor: 'enabled',
        Cell: ({ cell }) => (
          <SwitchCell
            cell={cell}
            fieldValue="row.original.enabled"
            query={requests.UPDATE_GUEST_FIELD_ENABLED}
          />
        ),
        width: 100,
        flexGrow: 0.2,
      },
    ],
    [],
  );

  return (
    <>
      <SectionHead propertyData={propertyData} title={title} links={links} />
      {loading && <Spinner color="secondary" />}
      {error && <ErrorLine error={error} />}
      {data && (
        <Table
          className="py-4"
          columns={columns}
          data={data.propertyGuestFieldsList}
          options={{ disableFilters: true }}
        />
      )}
    </>
  );
};

export default GuestFields;
