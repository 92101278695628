import React, { useState } from 'react';
import { Button, Row, Col, Spinner, UncontrolledAlert } from 'reactstrap';
import { Can } from '../../../auth/abilityContext';
import DeleteButton from '../../deleteButton';
import FormWrapper from '../../../form/formWrapper';
import {
  schema as schemaSource,
  uiSchema as uiSchemaSource,
} from '../../../schemas/paymentSystemDevice';
import { cloneDeep, set } from 'lodash';
import setOptionsLists from './setOptionsLists';
import insertConfigSchemas from './insertConfigSchemas';
import hasSchema from '../../../lib/hasSchema';
import ItemListWithSelect from '../../containers/itemListWithSelect';
import useAddCurrency from './useAddCurrency';
import useRemoveCurrency from './useRemoveCurrency';
import useOnSubmitPaymentDevice from './useOnSubmitPaymentDevice';
import ErrorLine from '../../containers/errorLine';

const PaymentSystemDevice = ({ data, addNew, setAddNew }) => {
  const subjectName = addNew
    ? 'AddPaymentSystemDevice'
    : 'UpdatePaymentSystemDevice';
  const {
    paymentDevice = {},
    data: {
      devicesList = [],
      paymentSystemsList = [],
      currenciesList = [],
    } = {},
    propertyId,
  } = data;
  const { paymentSystemDevicesCurrenciesList = [] } = paymentDevice;

  // Currencies to create paymentSystemDevice
  const [selectedCurrencies, setSelectedCurrencies] = useState([]);
  const addSelectedCurrency = id => {
    const selectedCurrency = currenciesList
      .filter(c => c.id === id)
      .map(c => {
        const { id, alphabeticCode } = c;
        return { id, name: alphabeticCode };
      });
    setSelectedCurrencies(prevSelectedCurrencies => {
      return [...prevSelectedCurrencies, ...selectedCurrency];
    });
  };
  const removeSelectedCurrency = id => {
    const _selectedCurrencies_ = selectedCurrencies.filter(c => c.id !== id);
    setSelectedCurrencies(_selectedCurrencies_);
  };

  const [currentPaymentSystem = {}] = paymentSystemsList.filter(
    ps => ps.id === paymentDevice.paymentSystemId,
  );
  const currentCredentials = hasSchema(currentPaymentSystem.credentialsTemplate)
    ? paymentDevice.credentials
    : JSON.stringify(paymentDevice.credentials || {});

  const currentAttributes = hasSchema(currentPaymentSystem.attributesTemplate)
    ? paymentDevice.attributes
    : JSON.stringify(paymentDevice.attributes || {});

  // Currencies to update paymentSystemDevice
  const currentCurrencies = [];
  paymentSystemDevicesCurrenciesList.forEach(c => {
    const { alphabeticCode } = c.currency;
    currentCurrencies.push({
      id: c.id,
      name: alphabeticCode,
    });
  });

  // Format of currencies object
  const currencies = currenciesList.map(c => {
    const { alphabeticCode, id } = c;
    return { id, name: alphabeticCode };
  });

  // Remove selected currencies from currencies list
  let filteredCurrencies;
  if (addNew) {
    filteredCurrencies = currencies.filter(
      c => selectedCurrencies.filter(sc => c.id === sc.id).length === 0,
    );
  } else {
    filteredCurrencies = currencies.filter(
      c =>
        paymentSystemDevicesCurrenciesList.filter(sc => c.id === sc.currencyId)
          .length === 0,
    );
  }

  const formData = {
    ...paymentDevice,
    [`credentials${paymentDevice.paymentSystemId}`]: currentCredentials,
    [`attributes${paymentDevice.paymentSystemId}`]: currentAttributes,
  };

  const { schema, uiSchema } = insertConfigSchemas(
    schemaSource,
    uiSchemaSource,
    paymentSystemsList,
  );

  const setUiSchema = uiSchema => {
    if (addNew) {
      const newUiSchema = cloneDeep(uiSchema);
      set(newUiSchema, 'id["ui:widget"]', 'hidden');
      return newUiSchema;
    }
    return uiSchema;
  };

  const {
    addCurrency,
    error: addError,
    loading: addLoading,
  } = useAddCurrency(propertyId, paymentDevice.id);
  const {
    removeCurrency,
    error: removeError,
    loading: removeLoading,
  } = useRemoveCurrency(propertyId, paymentDevice.id);

  const { onSubmit, deleteDevice, loading, error, success } =
    useOnSubmitPaymentDevice(data, selectedCurrencies, addNew, setAddNew);

  return (
    <>
      <FormWrapper
        formData={formData}
        subjectName={subjectName}
        loading={loading}
        schemaSource={schema}
        uiSchemaSource={setUiSchema(uiSchema)}
        onSubmit={onSubmit}
        optionsLists={setOptionsLists({
          devicesList,
          paymentSystemsList,
        })}
      >
        {!addNew && (
          <ItemListWithSelect
            holderItems={currentCurrencies}
            itemsList={filteredCurrencies}
            selectButtonName="Add currency"
            title="Currencies"
            addItem={{ addItemSubmit: addCurrency, addError, addLoading }}
            removeItem={{
              removeItemSubmit: removeCurrency,
              removeError,
              removeLoading,
            }}
          />
        )}
        {addNew && (
          <ItemListWithSelect
            holderItems={selectedCurrencies}
            itemsList={filteredCurrencies}
            selectButtonName="Add currency"
            title="Currencies"
            addItem={{ addItemSubmit: addSelectedCurrency }}
            removeItem={{
              removeItemSubmit: removeSelectedCurrency,
            }}
          />
        )}
        <div>
          {loading && <Spinner color="secondary" />}
          {error && <ErrorLine error={error} />}
          {success && (
            <UncontrolledAlert>Data has been saved</UncontrolledAlert>
          )}
        </div>
        <Row form>
          {addNew && (
            <Col md={6}>
              <Button onClick={() => setAddNew(false)} block>
                Cancel
              </Button>
            </Col>
          )}
          <Can I="update" a={subjectName}>
            {!addNew && (
              <Col md={6}>
                <DeleteButton
                  subjectName={subjectName}
                  deleteFunction={deleteDevice}
                  block
                />
              </Col>
            )}
            <Col md={6}>
              <Button color="success" type="submit" block>
                Save
              </Button>
            </Col>
          </Can>
        </Row>
      </FormWrapper>
    </>
  );
};

export default PaymentSystemDevice;
