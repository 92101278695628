/* eslint-disable indent */
import React, { useState, useEffect } from 'react';
import { ModalDialog } from '../../containers/dialog';
import {
  Button,
  ListGroup,
  ListGroupItem,
  Spinner,
  UncontrolledAlert,
  UncontrolledTooltip,
} from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCog } from '@fortawesome/free-solid-svg-icons';
import { useQuery } from '@apollo/client';
import * as requests from './requests';
import { accessSystemDevice } from '../../../api/datastore';
import ErrorLine from '../../containers/errorLine';
import FormWrapper from '../../../form/formWrapper';
import submit from '../../../form/syntheticSubmit';

const ListAccessSystemDevice = ({ accessSystemConfigsList, onSelect }) => {
  return (
    <div
      className="mb-4 border"
      style={{ maxHeight: '224px', overflow: 'auto', borderRadius: '4px' }}
    >
      <ListGroup flush>
        <>
          {accessSystemConfigsList.map((accessSystemConfig, i) => {
            return (
              <ListGroupItem
                key={`startPageTemplate-${i}`}
                onClick={() => onSelect(accessSystemConfig.id)}
                action
              >
                <div className="d-flex flex-row justify-content-between align-content-center align-items-start">
                  {`${accessSystemConfig.id} - ${accessSystemConfig.name}`}
                </div>
              </ListGroupItem>
            );
          })}
        </>
      </ListGroup>
    </div>
  );
};

const EditAccessSystemDevice = ({
  accessSystemConfigId,
  accessSystemDevice = {},
  onSubmit,
}) => {
  const subjectName = 'EditAccessSystemDevice';
  const schema = {
    type: 'object',
    properties: {
      name: {
        type: 'string',
        title: 'Name',
        default: accessSystemDevice.name || '',
      },
      attributes: {
        type: 'string',
        title: 'Attributes',
        default: JSON.stringify(accessSystemDevice.attributes) || '{}',
      },
      credentials: {
        type: 'string',
        title: 'Credentials',
        default: JSON.stringify(accessSystemDevice.credentials) || '{}',
      },
    },
    required: ['name', 'attributes', 'credentials'],
  };

  const uiSchema = {
    'ui:order': ['name', 'attributes', 'credentials'],
    'attributes': {
      'ui:widget': 'textarea',
      'ui:options': {
        rows: 5,
      },
    },
    'credentials': {
      'ui:widget': 'textarea',
      'ui:options': {
        rows: 5,
      },
    },
  };

  const [form, setForm] = useState();
  return (
    <>
      <h4>ID: {accessSystemConfigId}</h4>
      <FormWrapper
        subjectName={subjectName}
        schemaSource={schema}
        uiSchemaSource={uiSchema}
        onSubmit={onSubmit}
        setForm={setForm}
        children={' '}
      />
      <Button onClick={() => submit(form)} color="success">
        Save
      </Button>
    </>
  );
};

const AccessSystemDevice = ({ data: cellData }) => {
  const subjectName = 'AccessSystemDevice';
  const { value } = cellData || {};
  const { deviceId, propertyId } = value || {};
  const buttonId = `${subjectName}-${deviceId}`;

  const [error1, setError1] = useState(null);
  const [loading1, setLoading1] = useState(true);
  const [accessSystemDevices, setAccessSystemDevices] = useState([]);
  const [selectedConfigId, setSelectedConfigId] = useState(null);
  const [complete, setComplete] = useState(false);
  const {
    loading2,
    error2,
    data: accessSystemConfigData,
  } = useQuery(requests.GET_CONFIGS_LIST, {
    variables: { propertyId: +propertyId },
  });

  useEffect(() => {
    (async () => {
      try {
        const res = await accessSystemDevice.list(propertyId, deviceId);
        setLoading1(false);
        setAccessSystemDevices(res.data);
      } catch (err) {
        setLoading1(false);
        setError1(err);
      }
    })();
  }, [deviceId, propertyId]);

  const onSubmit = async ({ name, attributes, credentials }) => {
    try {
      await accessSystemDevice.set(propertyId, deviceId, selectedConfigId, {
        name,
        attributes: JSON.parse(attributes),
        credentials: JSON.parse(credentials),
      });
      setComplete(true);
    } catch (error) {
      console.error(error);
    }
  };

  const onClose = () => {
    setSelectedConfigId(null);
    setComplete(false);
    setLoading1(false);
    setError1(false);
  };

  const loading = loading1 || loading2;
  const error = error1 || error2;
  const success = !error && !loading && complete;

  return (
    <>
      <ModalDialog
        buttonLabel={<FontAwesomeIcon icon={faCog} />}
        title="Access system device"
        buttonSize="sm"
        buttonId={buttonId}
        onClose={onClose}
        footer
      >
        {loading && <Spinner color="secondary" />}
        {error && <ErrorLine error={error1} />}
        {!selectedConfigId && accessSystemConfigData && (
          <ListAccessSystemDevice
            accessSystemConfigsList={
              accessSystemConfigData.accessSystemConfigsList
            }
            onSelect={setSelectedConfigId}
          />
        )}
        {selectedConfigId && accessSystemConfigData && accessSystemDevices && (
          <EditAccessSystemDevice
            accessSystemConfigId={selectedConfigId}
            accessSystemDevice={accessSystemDevices.find(
              x => x.access_system_config_id === selectedConfigId,
            )}
            onSubmit={onSubmit}
          />
        )}
        {success && <UncontrolledAlert>Data has been saved</UncontrolledAlert>}
      </ModalDialog>
      <UncontrolledTooltip target={buttonId}>
        Select access system device
      </UncontrolledTooltip>
    </>
  );
};
export default AccessSystemDevice;
