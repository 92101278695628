import gqlTag from 'graphql-tag';

export const GET_PMS_CONFIG = gqlTag`
  query property($propertyId: Int!) {
    property(id: $propertyId) {
      id
      propertySettings {
        id
        sync
      }
      propertyConfig {
        id
        pms {
          id
          name
        }
        pmsPropertyId
        credentials
        pmsConfig
        country {
          id
          name
        }
      }
    }
    countriesList {
      id
      name
    }
    pmsesList {
      id
      name
      propertyCredentialsTemplate
      propertyPmsConfigTemplate
    }
  }
`;

export const UPDATE_PMS_CONFIG = gqlTag`
  mutation UpdatePropertyConfig(
    $configId: Int!
    $pmsId: Int
    $pmsPropertyId: String
    $credentials: JSON
    $countryId: Int
    $pmsConfig: JSON
    $preauthAmount: Int
    $preauthCurrencyId: Int
    $preauthType: BitString
  ) {
    updatePropertyConfig(
      input: {
        patch: {
          pmsId: $pmsId
          pmsPropertyId: $pmsPropertyId
          credentials: $credentials
          countryId: $countryId
          pmsConfig: $pmsConfig
          preauthAmount: $preauthAmount
          preauthCurrencyId: $preauthCurrencyId
          preauthType: $preauthType
        }
        id: $configId
      }
    ) {
      propertyConfig {
        id
        pms {
          id
          name
        }
        pmsPropertyId
        credentials
        pmsConfig
        country {
          id
          name
        }
        preauthAmount
        preauthCurrencyId
        preauthType
      }
    }
  }
`;

export const UPDATE_SYNC = gqlTag`
  mutation UpdatePropertySetting(
    $settingsId: Int!
    $sync: JSON
  ) {
    updatePropertySetting(
      input: {
        patch: {
          sync: $sync
        }
        id: $settingsId
      }
    ) {
      propertySetting {
        id
        sync
      }
    }
  }
`;
