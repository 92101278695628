import React, { useContext, useState } from 'react';
import { useMutation } from '@apollo/client';
import {
  Spinner,
  Button,
  InputGroup,
  InputGroupAddon,
  Input,
  Badge,
} from 'reactstrap';
import ErrorLine from '../../containers/errorLine';
import * as request from './requests';
import { AbilityContext } from '../../../auth/abilityContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faTimes } from '@fortawesome/free-solid-svg-icons';
import { ModalDialog } from '../../containers/dialog';

const PropertiesPaymentNetwork = ({
  propertiesPaymentNetwork,
  setError,
  fetchData,
}) => {
  const subjectName = 'PropertiesPaymentNetwork';
  const propertiesPaymentNetworkId = propertiesPaymentNetwork.id;
  const ability = useContext(AbilityContext);

  const [code, setCode] = useState(propertiesPaymentNetwork.code);
  const [changed, setChanged] = useState(false);

  const [updatePropertiesPaymentNetwork, { loading: updateLoading }] =
    useMutation(request.UPDATE_PROPERTIES_PAYMENT_NETWORK, {
      onCompleted: () => {
        setChanged(false);
        setError(null);
        fetchData();
      },
      onError: error => {
        setError(error);
      },
    });

  const onSubmit = () => {
    updatePropertiesPaymentNetwork({
      variables: { id: propertiesPaymentNetworkId, code },
    });
  };

  const onChange = e => {
    setChanged(e.target.value !== propertiesPaymentNetwork.code);
    setCode(e.target.value);
  };

  const [
    deletePropertiesPaymentNetwork,
    { loading: deleteLoading, error: deleteError },
  ] = useMutation(request.DELETE_PROPERTIES_PAYMENT_NETWORK, {
    onCompleted: () => {
      setError(null);
      fetchData();
    },
    variables: {
      id: propertiesPaymentNetworkId,
    },
  });

  return (
    <div>
      <InputGroup>
        <InputGroupAddon
          addonType="prepend"
          style={{ width: '50%', maxWidth: '240px' }}
        >
          <div className="py-1 font-weight-bold text-secondary">
            <span className="mr-2">
              {propertiesPaymentNetwork.paymentNetwork.displayName}
            </span>
            {propertiesPaymentNetwork.primary && (
              <Badge color="info">primary</Badge>
            )}
          </div>
        </InputGroupAddon>
        <Input
          placeholder="PMS code"
          style={{
            borderRadius: '4px',
          }}
          className="mr-2"
          value={code}
          onChange={onChange}
          onKeyUp={e => {
            if (e.key === 'Enter') onSubmit();
          }}
        />
        {ability.can('update', subjectName) && (
          <InputGroupAddon addonType="append" style={{ width: '37px' }}>
            {changed && (
              <div>
                <Button
                  color="success"
                  onClick={() => onSubmit()}
                  style={{
                    borderColor: '#ff000000',
                    width: '38px',
                  }}
                >
                  {updateLoading ? (
                    <Spinner color="light" size="sm" />
                  ) : (
                    <FontAwesomeIcon icon={faCheck} className="button-icon" />
                  )}
                </Button>
              </div>
            )}
            {!changed && (
              <div>
                <ModalDialog
                  buttonStyle={{
                    borderColor: '#ff000000',
                    width: '38px',
                  }}
                  outline
                  buttonLabel={
                    <FontAwesomeIcon icon={faTimes} className="button-icon" />
                  }
                  title="Delete item"
                  footer={
                    <Button
                      onClick={deletePropertiesPaymentNetwork}
                      color="danger"
                    >
                      Delete
                    </Button>
                  }
                >
                  Are you sure?
                  <div>
                    {deleteLoading && <Spinner color="secondary" />}
                    {deleteError && <ErrorLine error={deleteError} />}
                  </div>
                </ModalDialog>
              </div>
            )}
          </InputGroupAddon>
        )}
      </InputGroup>
    </div>
  );
};

export default PropertiesPaymentNetwork;
