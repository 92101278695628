import { format, utcToZonedTime } from 'date-fns-tz';
import check24hoursFormat from './check24hoursFormat';

const getZonedTime = (date, timeZone) => {
  const timeFormat = check24hoursFormat() ? 'HH:mm' : 'hh:mm a';
  return format(utcToZonedTime(date, timeZone), `yyyy-MM-dd ${timeFormat}`, {
    timeZone,
  });
};

export default getZonedTime;
