import {
  Badge,
  ListGroupItem,
  Modal,
  ModalBody,
  ModalHeader,
} from 'reactstrap';
import React, { useState } from 'react';
import getCurrency from './getCurrency';
import Payment from '../payments/payment';
import getStatus from './getStatus';
import paymentStatuses from '../payments/paymentStatuses';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faTimes } from '@fortawesome/free-solid-svg-icons';

const SentToPmsBadge = ({ sentToPms }) => {
  let color = sentToPms ? 'success' : 'danger';
  return (
    <Badge color={color} className="mr-2">
      <FontAwesomeIcon icon={sentToPms ? faCheck : faTimes} className="mr-1" />
      {sentToPms ? 'Sent' : 'Unsent'}
    </Badge>
  );
};

const PaymentsListItem = ({ data, timezone, currenciesList }) => {
  const {
    id,
    sentToPms,
    pmsPaymentId,
    status,
    amount,
    currencyId,
    paymentNetwork,
  } = data;

  const paymentStatus = getStatus(paymentStatuses, status);
  const { displayName: paymentNetworkName } = paymentNetwork || {};

  const [modal, setModal] = useState(false);
  const toggle = () => {
    if (id) setModal(!modal);
  };

  return (
    <ListGroupItem onClick={toggle} action={!!id}>
      <div className="d-flex flex-column">
        <div className="d-flex flex-row justify-content-between align-items-center">
          <h5 className="m-0 mr-3">
            <Badge color="light">
              <>
                <span>{+amount}</span>
                {!!+amount && (
                  <span className="ml-2 font-weight-normal">
                    {getCurrency(currenciesList, currencyId)}
                  </span>
                )}
              </>
            </Badge>
          </h5>
          <div>
            {status === 'SUCCESS' && <SentToPmsBadge sentToPms={sentToPms} />}
            <Badge color={paymentStatus.color || 'secondary'}>
              {paymentStatus.name || status}
            </Badge>
          </div>
        </div>
        {(pmsPaymentId || paymentNetworkName) && (
          <div className="d-flex flex-row flex-wrap justify-content-between align-items-start">
            <div className="d-flex flex-column">
              <div>
                <span className="list-item-title">PMS payment ID: </span>
                <span className="list-item-text">{pmsPaymentId}</span>
              </div>
            </div>
            <div>
              {paymentNetworkName && (
                <Badge color="secondary" outline>
                  {paymentNetworkName}
                </Badge>
              )}
            </div>
          </div>
        )}
      </div>
      <Modal
        isOpen={modal}
        toggle={toggle}
        returnFocusAfterClose={false}
        size="lg"
      >
        <ModalHeader toggle={toggle}>Payment</ModalHeader>
        <ModalBody>
          <Payment row={{ original: { id, timezone } }} />
        </ModalBody>
      </Modal>
    </ListGroupItem>
  );
};

export default PaymentsListItem;
