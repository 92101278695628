import { useState } from 'react';
import { useMutation } from '@apollo/client';
import * as requests from './accessSystemConfigRequests';
import parseString from '../../../lib/parseString';

const ACCESS_SYSTEM_ID_BETECH = 2;

const useOnSubmitAccessSystemConfig = (data, addNew, setAddNew) => {
  const { accessSystemConfig: { id } = {}, propertyId } = data;

  const [complete, setComplete] = useState(null);

  const [updateConfig, { loading: loadingUpdate, error: errorUpdate }] =
    useMutation(requests.UPDATE_CONFIG, {
      onCompleted: () => {
        setComplete(true);
      },
    });

  const [createConfig, { loading: loadingCreate, error: errorCreate }] =
    useMutation(requests.CREATE_CONFIG, {
      refetchQueries: [
        {
          query: requests.GET_CONFIGS_LIST,
          variables: { propertyId },
        },
      ],
      onCompleted: () => {
        setComplete(true);
        setAddNew(false);
      },
    });

  const [deleteConfig, { loading: loadingDelete, error: errorDelete }] =
    useMutation(requests.DELETE_CONFIG, {
      refetchQueries: [
        {
          query: requests.GET_CONFIGS_LIST,
          variables: { propertyId },
        },
      ],
      variables: {
        id,
      },
    });

  const onSubmit = data => {
    const { accessSystemId, name, proxy, proxySwitch } = data;
    const accessSystemConfig = data[`config${accessSystemId}`];

    let config = {};
    if (+accessSystemId === ACCESS_SYSTEM_ID_BETECH) {
      config.sector = parseString(accessSystemConfig.sector);
    } else {
      const configParsed =
        typeof accessSystemConfig === 'string'
          ? parseString(accessSystemConfig)
          : accessSystemConfig;

      if (proxySwitch) {
        configParsed.proxy = { ...proxy, ...(parseString(proxy.custom) || {}) };
        delete configParsed.proxy.custom;
      }

      config = configParsed || {};
    }

    if (addNew) {
      createConfig({
        variables: { name, accessSystemId, config, propertyId },
      });
    } else if (id) {
      updateConfig({
        variables: { id, name, accessSystemId, config, propertyId },
      });
    }
  };

  const loading = loadingUpdate || loadingCreate;
  const error = errorUpdate || errorCreate;

  const success =
    complete && !loading && !errorUpdate && !errorCreate && !errorDelete;

  return {
    onSubmit,
    deleteConfig,
    loadingDelete,
    errorDelete,
    loading,
    error,
    success,
  };
};

export default useOnSubmitAccessSystemConfig;
