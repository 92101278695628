import React from 'react';
import { Button } from 'reactstrap';
import { Can } from '../../../auth/abilityContext';
import parseString from '../../../lib/parseString';
import {
  schema as schemaSource,
  uiSchema as uiSchemaSource,
} from '../../../schemas/area';
import FormWrapper from '../../../form/formWrapper';
import areaStatuses from './areaStatuses';

const AreaForm = ({
  data,
  onSubmitAccessSystemArea,
  updatingStatus,
  formKey,
  loading,
}) => {
  const subjectName = 'Area';
  const areaId = +data.area.id;

  const {
    id,
    areaType,
    description,
    status,
    name,
    property,
    accessSystemAreasList,
  } = data.area || {};
  const { accessSystemConfigsList } = property;
  const { name: typeName } = areaType;
  const accessSystemArea = accessSystemAreasList.filter(
    a => a.areaId === data.area.id,
  )[0];

  const {
    id: accessSystemAreaId,
    attributes: originalAttributes,
    accessSystemConfigId,
  } = accessSystemArea || {};

  const { room_id: roomId, ...otherAttributes } = originalAttributes || {};
  const customAttributes = JSON.stringify({ ...otherAttributes });

  const formData = {
    id,
    name,
    areaType: typeName,
    description,
    accessSystemConfigId,
    attributes: {
      roomId,
      customAttributes,
    },
    status,
    accessSystemAreaId,
    accessSystemConfigsList,
  };

  const validate = (formData, errors) => {
    if (!parseString(formData.attributes.customAttributes)) {
      errors.attributes.addError('expected type JSON');
    }
    return errors;
  };

  const optionsLists = [
    {
      path: 'accessSystemConfigId',
      enum: accessSystemConfigsList.map(c => c.id),
      enumNames: accessSystemConfigsList.map(c => c.name),
    },
    {
      path: 'status',
      enum: areaStatuses.map(t => t.id),
      enumNames: areaStatuses.map(t => t.name),
    },
  ];

  const onSubmit = data => {
    const attributes = parseString(data.attributes.customAttributes) || {};
    if (data.attributes.roomId) {
      attributes.room_id = data.attributes.roomId;
    }
    const variables = {
      accessSystemAreaId,
      areaId,
      ...data,
      attributes,
    };

    onSubmitAccessSystemArea(variables);
  };

  return (
    <>
      <FormWrapper
        formData={formData}
        optionsLists={optionsLists}
        subjectName={subjectName}
        loading={loading}
        schemaSource={schemaSource}
        uiSchemaSource={uiSchemaSource}
        onSubmit={onSubmit}
        formKey={formKey}
        key={formKey}
        validate={validate}
      >
        <div>{updatingStatus}</div>
        <Can I="update" a={subjectName}>
          <Button type="submit" color="success">
            Save data
          </Button>
        </Can>
      </FormWrapper>
    </>
  );
};

export default AreaForm;
