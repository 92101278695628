import React, { useContext, useState } from 'react';
import * as requests from './requests';
import { useMutation } from '@apollo/client';
import { Button, Spinner, Alert, UncontrolledTooltip } from 'reactstrap';
import { ModalDialog } from '../../containers/dialog';
import ErrorLine from '../../containers/errorLine';
import FormWrapper from '../../../form/formWrapper';
import submit from '../../../form/syntheticSubmit';
import { AbilityContext } from '../../../auth/abilityContext';
import { faCoins } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const TestPaymentButton = ({ data }) => {
  const subjectName = 'TestPayment';
  const ability = useContext(AbilityContext);
  const { value } = data || {};
  const { deviceId, propertiesPaymentNetworksList, paymentSystemDevicesList } =
    value || {};
  const paymentNetworksList = propertiesPaymentNetworksList.map(p => ({
    id: p.paymentNetwork?.id,
    name: p.paymentNetwork?.displayName,
  }));
  const currencies = paymentSystemDevicesList.reduce(
    (currencies, paymentSystemDevice) => {
      const { paymentSystemDevicesCurrenciesList } = paymentSystemDevice || {};
      const currenciesList = paymentSystemDevicesCurrenciesList.map(c => ({
        id: c.currency.id,
        code: c.currency.alphabeticCode,
      }));
      return Array.from(
        new Map(
          [...currencies, ...currenciesList].map(c => [c.id, c]),
        ).values(),
      );
    },
    [],
  );

  let paymentNetworksEnum = {};
  if (paymentNetworksList.length > 0) {
    paymentNetworksEnum = {
      enum: paymentNetworksList.map(p => p.id),
      enumNames: paymentNetworksList.map(p => p.name),
    };
  }

  const schema = {
    type: 'object',
    properties: {
      amount: {
        type: 'integer',
        title: 'Amount',
        default: 1,
      },
      currencyId: {
        type: 'integer',
        title: 'Currency',
        enum: currencies.map(c => c.id),
        enumNames: currencies.map(c => c.code),
        default: currencies[0]?.id || 0,
      },
      paymentNetworkId: {
        type: 'integer',
        title: 'Payment network',
        ...paymentNetworksEnum,
      },
      paymentSystemDeviceId: {
        type: 'integer',
        title: 'Payment system device ID',
        enum: paymentSystemDevicesList.map(p => p.id),
        enumNames: paymentSystemDevicesList.map(p => p.name),
        default: paymentSystemDevicesList[0]?.id || 0,
      },
    },
    required: ['amount', 'currencyId', 'paymentSystemDeviceId'],
  };

  const uiSchema = {
    'ui:order': [
      'amount',
      'currencyId',
      'paymentSystemDeviceId',
      'paymentNetworkId',
    ],
    ...(propertiesPaymentNetworksList.length > 0
      ? {}
      : { paymentNetworkId: { 'ui:widget': 'hidden' } }),
    ...(paymentSystemDevicesList.length > 1
      ? {}
      : { paymentSystemDeviceId: { 'ui:widget': 'hidden' } }),
  };

  const [form, setForm] = useState();
  const [complete, setComplete] = useState(false);

  const [createManualPayment, { loading, error }] = useMutation(
    requests.CREATE_MANUAL_PAYMENT,
    {
      onCompleted: () => {
        setComplete(true);
      },
    },
  );

  const onSubmit = data => {
    const { paymentNetworkId } = data;
    const variables = {
      ...data,
      deviceId,
      ...(paymentNetworkId ? { paymentNetworkId } : {}),
    };
    createManualPayment({ variables });
  };

  const success = !!complete && !loading && !error;

  const onDismiss = () => setComplete(false);

  const buttonId = `${subjectName}-${deviceId}`;

  return (
    <>
      {ability.can('view', subjectName) && (
        <>
          <ModalDialog
            buttonLabel={
              <FontAwesomeIcon icon={faCoins} className="button-icon" />
            }
            buttonSize="sm"
            title="Make a test payment"
            onClose={onDismiss}
            disabled={currencies.length === 0}
            buttonId={buttonId}
            footer={
              <Button onClick={() => submit(form)} color="success">
                Make a test payment
              </Button>
            }
          >
            <FormWrapper
              subjectName={subjectName}
              loading={loading}
              schemaSource={schema}
              uiSchemaSource={uiSchema}
              onSubmit={onSubmit}
              setForm={setForm}
              children={' '}
            />
            {loading && <Spinner color="secondary" />}
            {error && <ErrorLine error={error} />}
            {success && (
              <Alert isOpen={success} toggle={onDismiss}>
                Test payment created successfully
              </Alert>
            )}
          </ModalDialog>
          <UncontrolledTooltip target={buttonId}>
            Make a test payment
          </UncontrolledTooltip>
        </>
      )}
    </>
  );
};

export default TestPaymentButton;
