import React from 'react';
import PropertyHead from './propertyHead';
import SectionCard from './sectionCard';

const PropertySections = ({ propertyData, links }) => {
  const rootPath = `/property/${propertyData.property.id}`;
  return (
    <>
      <PropertyHead data={propertyData} />
      <div className="row border-top ">
        {links.map((link, i) => (
          <SectionCard
            key={`link-${i}`}
            title={link.title}
            icon={link.icon}
            to={`${rootPath}${link.path && `/${link.path}`}`}
          />
        ))}
      </div>
    </>
  );
};

export default PropertySections;
