/* eslint-disable indent */
import React, { useRef, useCallback, useState, useEffect } from 'react';
import { format, startOfDay, endOfDay } from 'date-fns';
import formatDate from '../../../lib/formatDate';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes, faCalendarAlt } from '@fortawesome/free-solid-svg-icons';
import Flatpickr from 'react-flatpickr';
import 'flatpickr/dist/themes/light.css';
import {
  Input,
  Button,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  CustomInput,
} from 'reactstrap';
import { getBaseUrl } from '../../../api/kioskUiApi';
import { getToken } from '../../../auth/getToken';
import '../../../styles/getFilteredList.css';

const GuestFilter = ({ propertyId, getBookingsList, loading }) => {
  const flatpickrRef = useRef(null);

  const [sezamGuestsOnly, setSezamGuestsOnly] = useState(true);
  const [dateRange, setDateRange] = useState([]);
  const [error, setError] = useState(null);

  useEffect(() => {
    if (dateRange.length > 1) {
      setError(false);
    }
  }, [dateRange.length]);

  useEffect(() => {
    if (flatpickrRef.current) {
      flatpickrRef.current.flatpickr.setDate(dateRange);
    }
  });

  const clearDate = useCallback(() => {
    if (flatpickrRef.current) {
      flatpickrRef.current.flatpickr.clear();
      setError(false);
      setDateRange([]);
    }
  }, [flatpickrRef]);

  const onChangeDate = (dates = []) => {
    const startDate =
      !!dates[0] && format(startOfDay(dates[0]), "yyyy-MM-dd'T'HH:mm:ss");
    const endDate =
      !!dates[1] && format(endOfDay(dates[1]), "yyyy-MM-dd'T'HH:mm:ss");

    if (endDate) {
      setDateRange([startDate, endDate]);
    } else {
      setDateRange([startDate]);
    }
  };

  const token = getToken();
  const baseUrl = getBaseUrl();
  const csvUrl =
    dateRange.length > 1
      ? `${baseUrl}management/download_guests?token=${token}&propertyId=${propertyId}&startDate=${
          dateRange[0]
        }&endDate=${dateRange[1]}&sezamGuestsOnly=${
          sezamGuestsOnly ? 'true' : 'false'
        }`
      : undefined;

  return (
    <div className="mb-4 mt-3 d-flex flex-wrap align-items-start get-filtered-list-box">
      <Flatpickr
        onChange={onChangeDate}
        placeholder="Select a date range to load data"
        ref={flatpickrRef}
        options={{
          formatDate,
          locale: {
            firstDayOfWeek: 1,
          },
          mode: 'range',
        }}
        render={({ defaultValue, value, ...props }, ref) => {
          return (
            <InputGroup
              className="mr-2 flex-sm-grow-1"
              style={{ width: '330px' }}
            >
              <InputGroupAddon addonType="prepend">
                <InputGroupText>
                  <FontAwesomeIcon
                    icon={faCalendarAlt}
                    className="button-icon"
                  />
                </InputGroupText>
              </InputGroupAddon>
              <Input
                defaultValue={defaultValue}
                innerRef={ref}
                style={{ backgroundColor: '#fff' }}
                invalid={error}
                {...props}
              />
              {dateRange.length > 0 && (
                <InputGroupAddon addonType="append">
                  <Button onClick={clearDate} outline>
                    <FontAwesomeIcon icon={faTimes} className="button-icon" />
                  </Button>
                </InputGroupAddon>
              )}
            </InputGroup>
          );
        }}
      />
      <div className="mr-2 p-2 flex-sm-grow-0" style={{ width: '330px' }}>
        <CustomInput
          type="checkbox"
          id="sezamGuestsOnly"
          label="Registered guests via Sezam24 only"
          checked={sezamGuestsOnly}
          onChange={e => setSezamGuestsOnly(e.target.checked)}
        />
      </div>
      <div className="mt-2 mt-sm-0 flex-sm-grow-0" style={{ width: '150px' }}>
        <Button
          onClick={() => {
            if (dateRange.length > 1) {
              getBookingsList(dateRange[0], dateRange[1], sezamGuestsOnly);
              setError(false);
            } else {
              setError(true);
            }
          }}
          color="success"
          disabled={loading}
          block
        >
          Load
        </Button>
        {csvUrl && (
          <Button href={csvUrl} color="secondary" block>
            CSV
          </Button>
        )}
      </div>
    </div>
  );
};

export default GuestFilter;
