import React, { useEffect, useState } from 'react';
import { useApolloClient } from '@apollo/client';
import * as request from './requests';
import {
  Button,
  FormGroup,
  Input,
  InputGroup,
  InputGroupAddon,
  Label,
  Spinner,
} from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';

const getPrimaryNetworkId = data => {
  return data.propertiesPaymentNetworksList.find(pn => pn.primary)?.id || 0;
};

const PrimarySelect = ({
  data,
  setError: setParentError,
  propertyId,
  fetchData,
}) => {
  const client = useApolloClient();
  const [loading, setLoading] = useState(false);
  const [changed, setChanged] = useState(false);

  const [primaryNetworkId, setPrimaryNetworkId] = useState(
    getPrimaryNetworkId(data),
  );

  useEffect(() => {
    if (!loading) {
      setPrimaryNetworkId(getPrimaryNetworkId(data));
    }
  }, [data, loading]);

  const onChange = e => {
    setChanged(+e.target.value !== getPrimaryNetworkId(data));
    setPrimaryNetworkId(+e.target.value);
  };

  const onSubmit = async () => {
    setLoading(true);

    try {
      if (primaryNetworkId) {
        const propertiesPaymentNetwork = await client.query({
          query: request.GET_PROPERTIES_PAYMENT_NETWORK_BY_ID,
          variables: { id: primaryNetworkId },
          fetchPolicy: 'network-only',
        });

        if (propertiesPaymentNetwork.data.propertiesPaymentNetwork) {
          const primaryNetworks = await client.query({
            query: request.GET_PRIMARY_PROPERTIES_PAYMENT_NETWORKS,
            variables: { propertyId },
            fetchPolicy: 'network-only',
          });
          await Promise.all(
            primaryNetworks.data.propertiesPaymentNetworksList.map(
              async p =>
                await client.mutate({
                  mutation: request.UPDATE_PROPERTIES_PAYMENT_NETWORK_PRIMARY,
                  variables: { id: p.id, primary: false },
                }),
            ),
          );
          await client.mutate({
            mutation: request.UPDATE_PROPERTIES_PAYMENT_NETWORK_PRIMARY,
            variables: {
              id: primaryNetworkId,
              primary: true,
            },
          });
          setParentError(null);
        } else {
          setParentError(
            new Error(
              'Operation failed. Data has been updated. Try again please.',
            ),
          );
        }
      }
      await fetchData();
      setChanged(false);
    } catch (e) {
      setParentError(e);
    } finally {
      setLoading(false);
    }
  };

  return (
    <FormGroup className="mb-4">
      <Label>Select primary payment network</Label>
      <InputGroup>
        <Input
          style={{ borderRadius: '4px' }}
          type="select"
          placeholder="PMS code"
          value={primaryNetworkId}
          onChange={onChange}
          className="mr-2"
        >
          <option value={0} disabled>
            Primary payment network
          </option>
          {data.propertiesPaymentNetworksList.map(pn => (
            <option value={pn.id} key={`payment-network-${pn.id}`}>
              {`${pn.paymentNetwork.displayName} - ${pn.code}`}
            </option>
          ))}
        </Input>
        {changed && (
          <InputGroupAddon addonType="append">
            <div>
              <Button
                color="success"
                onClick={() => onSubmit()}
                style={{ width: '38px' }}
                className="mr-2"
              >
                {loading ? (
                  <Spinner color="light" size="sm" />
                ) : (
                  <FontAwesomeIcon icon={faCheck} className="button-icon" />
                )}
              </Button>
            </div>
          </InputGroupAddon>
        )}
      </InputGroup>
    </FormGroup>
  );
};

export default PrimarySelect;
