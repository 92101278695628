import React from 'react';
import { Spinner, UncontrolledAlert, Button, Row, Col } from 'reactstrap';
import { Can } from '../../../auth/abilityContext';
import ErrorLine from '../../containers/errorLine';
import DeleteButton from '../../deleteButton';
import useOnSubmitAccessSystemConfig from './useOnSubmitAccessSystemConfig';
import insertConfigSchemas from './insertConfigSchemas';
import {
  schema as schemaSource,
  uiSchema as uiSchemaSource,
  customFormats,
  proxyFields,
} from '../../../schemas/accessSystemConfig';
import FormWrapper from '../../../form/formWrapper';
import setOptionsLists from './setOptionsLists';
import hasSchema from '../../../lib/hasSchema';

const ACCESS_SYSTEM_ID_BETECH = 2;

const AccessSystemConfig = ({ data, addNew, setAddNew }) => {
  const subjectName = 'AccessSystemConfig';
  const { accessSystemConfig = {}, accessSystemsList = {} } = data;
  const proxyData = (accessSystemConfig.config || {}).proxy;
  const proxyEnabled = !!proxyData;

  const [currentAccessSystem = {}] = accessSystemsList.filter(
    as => as.id === accessSystemConfig.accessSystemId,
  );

  let config;
  if (hasSchema(currentAccessSystem.accessSystemConfigTemplate)) {
    config = {
      ...accessSystemConfig.config,
    };
  } else {
    config = JSON.stringify(accessSystemConfig.config || {});
  }

  if (accessSystemConfig.accessSystemId === ACCESS_SYSTEM_ID_BETECH) {
    config = {
      sector: JSON.stringify(accessSystemConfig.config.sector || {}),
    };
  }

  const formData = {
    ...accessSystemConfig,
    [`config${accessSystemConfig.accessSystemId}`]: config,
    proxySwitch: proxyEnabled,
  };

  if (proxyEnabled) {
    const proxy = {};
    const customData = {};

    Object.keys(proxyData).forEach(k => {
      if (k in proxyFields) {
        proxy[k] = proxyData[k];
      } else {
        customData[k] = proxyData[k];
      }
    });

    formData.proxy = { ...proxy, custom: JSON.stringify(customData) };
  }

  const { schema, uiSchema } = insertConfigSchemas(
    schemaSource,
    uiSchemaSource,
    accessSystemsList,
  );

  const {
    onSubmit,
    deleteConfig,
    loadingDelete,
    errorDelete,
    loading,
    error,
    success,
  } = useOnSubmitAccessSystemConfig(data, addNew, setAddNew);

  return (
    <>
      <FormWrapper
        formData={formData}
        subjectName={subjectName}
        loading={loading}
        schemaSource={schema}
        uiSchemaSource={uiSchema}
        onSubmit={onSubmit}
        customFormats={customFormats}
        optionsLists={setOptionsLists({
          accessSystemsList,
        })}
      >
        <div>
          {loading && <Spinner color="secondary" />}
          {error && <ErrorLine error={error} />}
          {success && (
            <UncontrolledAlert>Data has been saved</UncontrolledAlert>
          )}
        </div>
        <Row form>
          {addNew && (
            <Col md={6}>
              <Button onClick={() => setAddNew(false)} block>
                Cancel
              </Button>
            </Col>
          )}
          <Can I="update" a={subjectName}>
            {!addNew && (
              <Col md={6}>
                <DeleteButton
                  subjectName={subjectName}
                  deleteFunction={deleteConfig}
                  deletingStatus={
                    <div>
                      {loadingDelete && <Spinner color="secondary" />}
                      {errorDelete && <ErrorLine error={error} />}
                    </div>
                  }
                  block
                />
              </Col>
            )}
            <Col md={6}>
              <Button color="success" type="submit" block>
                Save
              </Button>
            </Col>
          </Can>
        </Row>
      </FormWrapper>
    </>
  );
};

export default AccessSystemConfig;
