export const schema = {
  type: 'object',
  required: [
    'messagingSystem',
    'templateType',
    'templateSubject',
    'templateData',
  ],
  properties: {
    id: {
      type: 'number',
      title: 'id',
    },
    messagingSystem: {
      type: 'string',
      title: 'Messaging system',
      enum: [],
      enumNames: [],
      default: 'EMAIL',
    },
    templateType: {
      type: 'string',
      title: 'Type',
      enum: [],
      enumNames: [],
      default: 'PRE_CHECK_IN',
    },
    language: {
      type: 'string',
      title: 'Language',
      enum: [],
      enumNames: [],
      default: 'en',
    },
    templateSubject: {
      type: 'string',
      title: 'Subject',
    },
    templateData: {
      type: 'string',
      title: 'Template',
    },
  },
  dependencies: {
    templateType: {
      oneOf: [
        {
          properties: {
            templateType: {
              enum: ['PRE_CHECK_IN'],
            },
            typeDescription: {
              type: 'string',
              description: 'Pre-check-in confirmation message',
            },
          },
        },
      ],
    },
  },
};

export const uiSchema = {
  'ui:order': ['id', 'messagingSystem', 'templateType', 'typeDescription', '*'],
  'templateSubject': { 'ui:widget': 'InputWithKeywords' },
  'templateData': { 'ui:widget': 'TemplateEditor' },
  'messagingSystem': { 'ui:disabled': true },
  'typeDescription': {
    'ui:field': 'Description',
  },
};
