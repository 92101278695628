import React from 'react';
import { useQuery } from '@apollo/client';
import { Spinner, Table } from 'reactstrap';
import '../../../styles/guest.css';
import ErrorLine from '../../containers/errorLine';
import getZonedTime from '../../../lib/getZonedTime';
import * as request from './requests';
import { ModalDialog } from '../../containers/dialog';

const DrawPayment = ({ data, timezone }) => {
  const { payment } = data;
  const {
    pmsPaymentId,
    paidAt,
    sentToPms,
    amount,
    status,
    currency,
    paymentNetwork,
    posMessage,
    maskedCardNumber,
    receipt,
    receiptMerchant,
  } = payment;
  const paymentData = [
    { title: 'PMS payment ID', data: pmsPaymentId },
    { title: 'Sent to PMS', data: sentToPms ? 'SENT' : 'UNSENT' },
    {
      title: 'Paid',
      data: paidAt && getZonedTime(paidAt, timezone),
    },
    { title: 'Amount', data: amount },
    { title: 'Currency', data: currency.alphabeticCode },
    { title: 'Status', data: status },
    { title: 'Payment network', data: paymentNetwork?.displayName },
    { title: 'POS message', data: posMessage },
    { title: 'Masked card number', data: maskedCardNumber },
    { title: 'Receipt', data: receipt, render: true },
    { title: 'Merchant receipt', data: receiptMerchant, render: true },
  ];
  return (
    <Table className="guest" bordered hover>
      <tbody>
        {paymentData.map(({ title, data, render }, i) => {
          if (data) {
            return (
              <tr key={`guestTable${i}`}>
                <td width="30%">{title}</td>
                {!render ? (
                  <td>{data}</td>
                ) : (
                  <td>
                    <ModalDialog
                      buttonLabel="View"
                      title="Receipt"
                      buttonSize="sm"
                      size="lg"
                      disabled={!data}
                    >
                      <div style={{ overflow: 'auto' }}>
                        <iframe
                          className="m-auto d-block border"
                          title="template"
                          width={480}
                          srcDoc={data}
                          style={{ height: '75vh' }}
                        />
                      </div>
                    </ModalDialog>
                  </td>
                )}
              </tr>
            );
          }
          return false;
        })}
      </tbody>
    </Table>
  );
};

const Payment = ({ row }) => {
  const paymentId = row.original.id;
  const timezone = row.original.timezone;

  const { loading, error, data } = useQuery(request.GET_PAYMENT, {
    variables: { paymentId },
    fetchPolicy: 'network-only',
  });

  return (
    <>
      {loading && <Spinner color="secondary" />}
      {error && <ErrorLine error={error} />}
      {data && <DrawPayment data={data} timezone={timezone} />}
    </>
  );
};

export default Payment;
