import React, { useMemo, useState } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import { Spinner } from 'reactstrap';
import {
  schema as schemaSource,
  uiSchema as uiSchemaSource,
} from '../../../schemas/settings';
import FormWrapper from '../../../form/formWrapper';
import SectionHead from '../sectionsHead';
import ErrorLine from '../../containers/errorLine';
import * as requests from './requests';

const getUtcOffset = utcOffset => {
  if (typeof utcOffset === 'number') {
    return `(${utcOffset > 0 ? '+' : ''}${utcOffset})`;
  } else {
    return '';
  }
};

const Settings = ({ data }) => {
  const subjectName = 'Settings';

  const sett = data.property.propertySettings || {};
  const { checkinCheckoutPolicy } = sett || {};
  const { start_time, end_time } = checkinCheckoutPolicy || {};

  const timezonesList = useMemo(() => {
    return data.timezoneViewsList.map(tz => ({
      id: tz.name,
      name: `${tz.name} ${getUtcOffset(tz.utcOffset.hours)}`,
    }));
  }, [data.timezoneViewsList]);

  const formData = {
    ...sett,
    checkinCheckoutPolicyStart: start_time,
    checkinCheckoutPolicyEnd: end_time,
  };

  const optionsLists = [
    {
      path: 'timezone',
      enum: timezonesList.map(t => t.id),
      enumNames: timezonesList.map(t => t.name),
    },
  ];

  const [complete, setComplete] = useState(null);
  const [formKey, setFormKey] = useState(1);

  const [updatePropertySetting, { loading, error }] = useMutation(
    requests.UPDATE_PROPERTY_SETTINGS,
    {
      onCompleted: () => {
        setComplete(true);
        setFormKey(formKey + 1);
      },
    },
  );

  const onSubmit = data => {
    const variables = { settingsId: sett.id, ...data };

    if (
      'checkinCheckoutPolicyStart' in variables ||
      'checkinCheckoutPolicyEnd' in variables
    ) {
      variables.checkinCheckoutPolicy = {
        start_time: variables.checkinCheckoutPolicyStart,
        end_time: variables.checkinCheckoutPolicyEnd,
      };
    }

    updatePropertySetting({ variables });
  };

  const success = complete && !loading && !error;

  return (
    <FormWrapper
      formData={formData}
      optionsLists={optionsLists}
      subjectName={subjectName}
      loading={loading}
      schemaSource={schemaSource}
      uiSchemaSource={uiSchemaSource}
      onSubmit={onSubmit}
      formKey={formKey}
      formContext={{ loading, success, error }}
      children={' '}
    />
  );
};

const SettingsContainer = ({ propertyData, title, links }) => {
  const propertyId = +propertyData.property.id;

  const { data, loading, error } = useQuery(requests.GET_PROPERTY_SETTINGS, {
    variables: { propertyId },
    fetchPolicy: 'network-only',
  });

  return (
    <div className="limited-container">
      <SectionHead propertyData={propertyData} title={title} links={links} />
      {loading && <Spinner color="secondary" />}
      {error && <ErrorLine error={error} />}
      {data && <Settings data={data} />}
    </div>
  );
};

export default SettingsContainer;
