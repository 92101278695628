import preauthTypeValues from '../components/property/preauthConfig/preauthTypeValues';
import { Widget } from '../form/formTemplate';

export const schema = {
  type: 'object',
  title: 'Pre-authorization configuration',
  properties: {
    preauthAmount: {
      type: 'number',
      title: 'Amount',
      minLength: 1,
      maxLength: 11,
      default: 0,
    },
    preauthCurrencyId: {
      type: 'number',
      title: 'Currency',
      enum: [],
    },
    preauthType: {
      type: 'string',
      title: 'Pre-auth type',
    },
  },
};

export const uiSchema = {
  'ui:ObjectFieldTemplate': Widget,
  'preauthType': {
    'ui:widget': 'BitCheckboxes',
    'ui:options': {
      bits: preauthTypeValues,
    },
  },
};
