import React, { useEffect, useState } from 'react';
import { useMutation } from '@apollo/client';
import {
  Spinner,
  Popover,
  PopoverHeader,
  PopoverBody,
  Badge,
} from 'reactstrap';
import get from 'lodash/get';
import submit from '../../../form/syntheticSubmit';
import { widgets } from '../../../form/widgets';
import Form from '@rjsf/core';

const schema = {
  type: 'object',
  properties: {
    switch: {
      type: 'boolean',
      title: '',
      default: false,
      enum: [true, false],
    },
  },
};

const uiSchema = {
  switch: {
    'ui:widget': 'Switch',
    'ui:options': {
      label: false,
    },
  },
};

const SwitchCell = ({ cell, fieldValue, fieldId, fieldName, query }) => {
  const cellId = `cell-${cell.getCellProps().key}`;

  const [form, setForm] = useState();
  const originalValue = get(cell, fieldValue);
  const [formData, setFormData] = useState({ switch: originalValue });

  useEffect(() => {
    setFormData({ switch: get(cell, fieldValue) });
  }, [cell, fieldValue]);

  const [show, setShow] = useState(false);
  const toggle = () => setShow(!show);

  const [update, { loading, error }] = useMutation(query);

  const onSubmit = data => {
    update({
      variables: {
        id: get(cell.row.original, fieldId),
        [fieldName]: data.formData.switch,
      },
    });
  };

  return (
    <div onClick={e => e.stopPropagation()} className="inline-form">
      <Form
        idPrefix={cellId}
        ref={form => setForm && setForm(form)}
        formData={formData}
        schema={schema}
        uiSchema={uiSchema}
        widgets={widgets}
        onSubmit={onSubmit}
        disabled={loading}
        onChange={e => {
          setFormData(() => {
            submit(form);
            return e.formData;
          });
        }}
        showErrorList={false}
        noHtml5Validate
        key={`switch-${cell.row.id}-${cellId}`}
      >
        {' '}
      </Form>
      {loading && <Spinner size="sm" />}
      {error && (
        <>
          <Badge
            color="danger"
            style={{ cursor: 'pointer' }}
            id={`error-${cellId}`}
          >
            Error
          </Badge>
          <Popover
            placement="bottom"
            isOpen={show}
            target={`error-${cellId}`}
            toggle={toggle}
            trigger="legacy"
          >
            <PopoverHeader>Error</PopoverHeader>
            <PopoverBody>
              <p className="text-danger mt-2">{error.message}</p>
            </PopoverBody>
          </Popover>
        </>
      )}
    </div>
  );
};

SwitchCell.defaultProps = {
  fieldId: 'id',
  fieldValue: 'value',
  fieldName: 'enabled',
};

export default SwitchCell;
