import React, { useState, useContext } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlusCircle } from '@fortawesome/free-solid-svg-icons';
import Widget from '../../containers/widget';
import AccessSystemConfig from './accessSystemConfig';
import SectionHead from '../sectionsHead';
import { Button, Spinner } from 'reactstrap';
import { AbilityContext } from '../../../auth/abilityContext';
import * as requests from './accessSystemConfigRequests';
import { useQuery } from '@apollo/client';
import ErrorLine from '../../containers/errorLine';

const AccessSystems = ({ propertyData, title, links }) => {
  const propertyId = +propertyData.property.id;

  const { loading, error, data } = useQuery(requests.GET_CONFIGS_LIST, {
    variables: { propertyId },
  });

  const [newKey, setNewKey] = useState(1);
  const [addNewConfig, setAddNewConfig] = useState(false);

  const onAdded = () => {
    setNewKey(newKey + 1);
    setAddNewConfig(false);
  };

  const ability = useContext(AbilityContext);

  return (
    <>
      <SectionHead propertyData={propertyData} title={title} links={links} />
      {loading && <Spinner color="secondary" />}
      {error && <ErrorLine error={error} />}
      {data && (
        <div className="row py-4">
          {data.accessSystemConfigsList.map((config, i) => (
            <Widget
              header={config.name}
              key={`accessSystem_${config.id}_${i}`}
              content={
                <AccessSystemConfig
                  data={{
                    accessSystemConfig: config,
                    accessSystemsList: data.accessSystemsList,
                    propertyId,
                  }}
                />
              }
            />
          ))}
          {addNewConfig && (
            <Widget
              header="Add new access system config"
              key={newKey}
              content={
                <AccessSystemConfig
                  addNew={addNewConfig}
                  setAddNew={setAddNewConfig}
                  onSaved={onAdded}
                  data={{
                    accessSystemsList: data.accessSystemsList,
                    propertyId,
                  }}
                />
              }
            />
          )}
          {!addNewConfig && ability.can('update', 'AccessSystemConfig') && (
            <div className="col-sd-12 col-md-6 col-lg-6 col-xl-4">
              <Button onClick={() => setAddNewConfig(true)} block outline>
                <FontAwesomeIcon icon={faPlusCircle} className="mr-2" />
                Add config
              </Button>
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default AccessSystems;
