import React, { useState, useContext } from 'react';
import { AbilityContext } from '../../../auth/abilityContext';
import {
  schema as schemaSource,
  uiSchema as uiSchemaSource,
} from '../../../schemas/pmsConfig';
import FormWrapper from '../../../form/formWrapper';
import insertConfigSchemas from './insertConfigSchemas';
import hasSchema from '../../../lib/hasSchema';
import { useMutation, useQuery } from '@apollo/client';
import * as requests from './requests';
import SectionHead from '../sectionsHead';
import { Spinner } from 'reactstrap';
import ErrorLine from '../../containers/errorLine';
import parseString from '../../../lib/parseString';

const PmsConfig = ({ data }) => {
  const subjectName = 'PmsConfig';
  const ability = useContext(AbilityContext);

  const pmsesList = data.pmsesList || [];
  const d = data.property || {};
  const conf = d.propertyConfig || {};
  const sett = d.propertySettings || {};
  const sync = sett.sync || {};
  const activePropertySync = !!(sync.areas || sync.bookings);

  const optionsLists = [
    {
      path: 'countryId',
      enum: data.countriesList.map(c => c.id),
      enumNames: data.countriesList.map(c => c.name),
    },
    {
      path: 'pmsId',
      enum: data.pmsesList.map(p => p.id),
      enumNames: data.pmsesList.map(p => p.name),
    },
  ];

  let schemaWithCredentialsSchemas, uiSchemaWithCredentialsSchemas;

  const currentPms = pmsesList.filter(pms => pms.id === conf.pms.id)[0];
  const currentCredentials = hasSchema(currentPms.propertyCredentialsTemplate)
    ? conf.credentials
    : JSON.stringify(conf.credentials);

  const formData = {
    pmsId: conf.pms.id,
    pmsPropertyId: conf.pmsPropertyId,
    countryId: conf.country.id,
    [`credentials${conf.pms.id}`]: currentCredentials,
    [`pmsConfig${conf.pms.id}`]: conf.pmsConfig,
    syncSwitch: activePropertySync,
    sync,
  };

  const { schema, uiSchema } = insertConfigSchemas(
    schemaSource,
    uiSchemaSource,
    pmsesList,
  );
  schemaWithCredentialsSchemas = schema;
  uiSchemaWithCredentialsSchemas = uiSchema;

  const [complete1, setComplete1] = useState(null);
  const [complete2, setComplete2] = useState(null);
  const [formKey, setFormKey] = useState(1);

  const [updatePmsConfig, { loading: loading1, error: error1 }] = useMutation(
    requests.UPDATE_PMS_CONFIG,
    {
      onCompleted: () => {
        setComplete1(true);
        setFormKey(formKey + 1);
      },
    },
  );

  const [updateSync, { loading: loading2, error: error2 }] = useMutation(
    requests.UPDATE_SYNC,
    {
      onCompleted: () => {
        setComplete2(true);
        setFormKey(formKey + 1);
      },
    },
  );

  const onSubmit = data => {
    const variables1 = { configId: conf.id, ...data };
    const variables2 = { settingsId: sett.id, ...data };

    if ('pmsId' in variables1) {
      const currentCred = `credentials${variables1.pmsId}`;
      const currentPmsConf = `pmsConfig${variables1.pmsId}`;
      if (ability.can('update', subjectName, 'credentials')) {
        variables1.credentials = {};
        if (currentCred in variables1) {
          variables1.credentials =
            typeof variables1[currentCred] === 'string'
              ? parseString(variables1[currentCred])
              : variables1[currentCred];
        }
      }
      if (ability.can('update', subjectName, 'pmsConfig')) {
        variables1.pmsConfig = {};
        if (currentPmsConf in variables1) {
          variables1.pmsConfig = variables1[currentPmsConf];
        }
      }
    }
    if (ability.can('update', subjectName, 'sync')) {
      if ('syncSwitch' in variables2 && !variables2.syncSwitch) {
        variables2.sync = null;
      }
    }

    updatePmsConfig({ variables: variables1 });
    updateSync({ variables: variables2 });
  };

  const loading = loading1 || loading2;
  const error = error1 || error2;
  const success = complete1 && complete2 && !loading && !error;

  return (
    <FormWrapper
      formData={formData}
      optionsLists={optionsLists}
      subjectName={subjectName}
      loading={loading}
      schemaSource={schemaWithCredentialsSchemas}
      uiSchemaSource={uiSchemaWithCredentialsSchemas}
      onSubmit={onSubmit}
      formKey={formKey}
      formContext={{ loading, success, error }}
      children={' '}
    />
  );
};

const PmsConfigContainer = ({ propertyData, title, links }) => {
  const propertyId = +propertyData.property.id;

  const { data, loading, error } = useQuery(requests.GET_PMS_CONFIG, {
    variables: { propertyId },
    fetchPolicy: 'network-only',
  });

  return (
    <div className="limited-container">
      <SectionHead propertyData={propertyData} title={title} links={links} />
      {loading && <Spinner color="secondary" />}
      {error && <ErrorLine error={error} />}
      {data && <PmsConfig data={data} />}
    </div>
  );
};

export default PmsConfigContainer;
