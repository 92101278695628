import React from 'react';
import gqlTag from 'graphql-tag';
import { useLazyQuery } from '@apollo/client';
import { Spinner } from 'reactstrap';
import Table from '../../containers/table/table';
import ErrorLine from '../../containers/errorLine';
import SectionHead from '../sectionsHead';
import Guest from './guest';
import SelectColumnFilter from '../../containers/table/selectColumnFilter';
import DateFilter from '../../containers/table/dateFilter';
import GuestFilter from './guestFilter';
import getZonedTime from '../../../lib/getZonedTime';

const GuestsTable = ({ propertyData, title, links }) => {
  const { propertySettings: { timezone } = {} } = propertyData.property || {};
  const propertyId = +propertyData.property.id;

  const GET_FILTERED_BOOKINGS_LIST = gqlTag`
    query BookingsList(
      $propertyId: Int!
      $lessThan: Datetime!
      $greaterThan: Datetime!
      $guestFilter: GuestFilter
    ) {
      bookingsList(
        condition: { propertyId: $propertyId }
        filter: {
          startDate: { lessThan: $lessThan, greaterThan: $greaterThan }
        }
      ) {
        id
        startDate
        endDate
        status
        area {
          id
          name
        }
        guestsList(filter: $guestFilter) {
          id
          firstName
          lastName
          middleName
        }
      }
    }
  `;

  const [getBookingsList, { loading, error, data }] = useLazyQuery(
    GET_FILTERED_BOOKINGS_LIST,
    { fetchPolicy: 'network-only' },
  );

  const getFilteredBookingsList = (startDate, endDate, sezamGuestsOnly) => {
    getBookingsList({
      variables: {
        propertyId,
        lessThan: endDate,
        greaterThan: startDate,
        ...(sezamGuestsOnly
          ? { guestFilter: { sentToPms: { isNull: false } } }
          : {}),
      },
    });
  };

  const toDate = dateStr => new Date(dateStr);

  const columns = React.useMemo(
    () => [
      {
        Header: 'First name',
        accessor: 'firstName',
        filter: 'fuzzyText',
        width: 300,
      },
      {
        Header: 'Last name',
        accessor: 'lastName',
        filter: 'fuzzyText',
        width: 300,
      },
      {
        id: 'startDate',
        Header: 'Start date',
        accessor: row => toDate(row.startDate),
        sortType: 'datetime',
        Cell: ({ cell: { value } }) => getZonedTime(value, timezone),
        Filter: DateFilter,
        filter: 'dateFilter',
        flexGrow: 0.5,
      },
      {
        id: 'endDate',
        Header: 'End date',
        accessor: row => toDate(row.endDate),
        sortType: 'datetime',
        Cell: ({ cell: { value } }) => getZonedTime(value, timezone),
        Filter: DateFilter,
        filter: 'dateFilter',
        flexGrow: 0.5,
      },
      {
        Header: 'Area',
        accessor: 'area.name',
        flexGrow: 0.5,
      },
      {
        Header: 'Status',
        accessor: 'status',
        Filter: SelectColumnFilter,
        filter: 'includes',
        width: 120,
        flexGrow: 0.3,
      },
    ],
    [timezone],
  );

  const getGuestsList = data => {
    if (!data) return [];
    const guestsList = [];
    data.bookingsList.forEach(b => {
      const { startDate, endDate, status, area } = b || {};
      const guests = b.guestsList.map(g => ({
        ...g,
        startDate,
        endDate,
        status,
        area,
      }));
      guestsList.push(...guests);
    });
    return guestsList;
  };

  const stringify = val => {
    if (val instanceof Date) return getZonedTime(val, timezone);

    return String(val);
  };
  const tableOptions = {
    globalFilter: (rows, ids, filterValue) => {
      rows = rows.filter(row => {
        return ids.some(id => {
          const rowValue = row.values[id];
          return stringify(rowValue)
            .toLowerCase()
            .includes(stringify(filterValue).toLowerCase());
        });
      });
      return rows;
    },
  };
  tableOptions.globalFilter.autoRemove = val => !val;

  const initialState = {
    sortBy: [{ id: 'startDate', desc: true }],
  };

  return (
    <>
      <SectionHead propertyData={propertyData} title={title} links={links} />
      <GuestFilter
        propertyId={propertyId}
        getBookingsList={getFilteredBookingsList}
        loading={loading}
      />
      {loading && <Spinner color="secondary" />}
      {error && <ErrorLine error={error} />}
      {data && (
        <Table
          className="py-4"
          columns={columns}
          data={getGuestsList(data)}
          ExpandCard={Guest}
          options={tableOptions}
          initialState={initialState}
        />
      )}
    </>
  );
};

export default GuestsTable;
