export const schema = {
  type: 'object',
  required: ['deviceId', 'paymentSystemId'],
  properties: {
    id: {
      type: 'number',
      title: 'id',
    },
    deviceId: {
      type: 'number',
      title: 'Kiosk device',
      enum: [],
      enumNames: [],
    },
    paymentSystemId: {
      type: 'number',
      title: 'Payment system',
      enum: [],
    },
  },
};

export const uiSchema = {
  id: {
    'ui:readonly': true,
  },
};
