import gqlTag from 'graphql-tag';

export const GET_PROPERTY_SETTINGS = gqlTag`
  query property($propertyId: Int!) {
    property(id: $propertyId) {
      id
      propertySettings {
        id
        payment
        guestRegistration
        documentScan
        accessSystem
        checkinCheckoutPolicy
        billingScheme
        timezone
        duplicateKey
        earlyCheckin
        email
        im
        phone
        preauth
        mandatoryGuestRegistration
      }
    }
    timezoneViewsList {
      name
      abbrev
      utcOffset {
        hours
      }
      isDst
    }
  }
`;

export const UPDATE_PROPERTY_SETTINGS = gqlTag`
  mutation UpdatePropertySetting(
    $settingsId: Int!
    $payment: Boolean
    $guestRegistration: Boolean
    $documentScan: Boolean
    $accessSystem: Boolean
    $checkinCheckoutPolicy: JSON
    $timezone: Timezone
    $duplicateKey: Boolean
    $earlyCheckin: Boolean
    $email: JSON
    $im: JSON
    $phone: JSON
    $preauth: Boolean
    $mandatoryGuestRegistration: Boolean
  ) {
    updatePropertySetting(
      input: {
        patch: {
          payment: $payment
          guestRegistration: $guestRegistration
          documentScan: $documentScan
          accessSystem: $accessSystem
          checkinCheckoutPolicy: $checkinCheckoutPolicy
          timezone: $timezone
          duplicateKey: $duplicateKey
          earlyCheckin: $earlyCheckin
          email: $email
          im: $im
          phone: $phone
          preauth: $preauth
          mandatoryGuestRegistration: $mandatoryGuestRegistration
        }
        id: $settingsId
      }
    ) {
      propertySetting {
        id
        payment
        guestRegistration
        documentScan
        accessSystem
        checkinCheckoutPolicy
        timezone
        duplicateKey
        earlyCheckin
        email
        im
        phone
        preauth
        mandatoryGuestRegistration
      }
    }
  }
`;
