import gqlTag from 'graphql-tag';

export const GET_DEVICES_LIST = gqlTag`
  query DevicesList($propertyId: Int!) {
    devicesList(condition: { propertyId: $propertyId }) {
      id
      name
      screenOrientation
      screenResolution
      status
      type {
        id
        name
      }
      paymentSystemDevicesList {
        id
        paymentSystemDevicesCurrenciesList {
          id
          currency {
            id
            alphabeticCode
          }
        }
      }
      property {
        id
        propertiesPaymentNetworksList {
          id
          paymentNetwork {
            id
            displayName
          }
        }
        areasList {
          id
          name
        }
      }
    }
  }
`;

export const GET_STARTPAGE_TEMPLATES_LIST = gqlTag`
query Q($propertyId: Int) {
  startpageTemplatesList(filter: { propertyIds: { anyEqualTo: $propertyId } }) {
    id
    propertyIds
    pageTag
  }
}
`;

export const CREATE_MANUAL_PAYMENT = gqlTag`
  mutation CreateManualPayment(
    $deviceId: Int!
    $currencyId: Int!
    $paymentSystemDeviceId: Int!
    $paymentNetworkId: Int
    $amount: Int!
  ) {
    createManualPayment(
      input: {
        manualPayment: {
          deviceId: $deviceId
          currencyId: $currencyId
          paymentSystemDeviceId: $paymentSystemDeviceId
          paymentNetworkId: $paymentNetworkId
          amount: $amount
        }
      }
    ) {
      manualPayment {
        id
      }
    }
  }
`;

export const CREATE_MANUAL_KEY = gqlTag`
  mutation CreateManualKey(
    $deviceId: Int!
    $areaId: Int!
    $startDate: Datetime!
    $endDate: Datetime!
  ) {
    createManualKey(
      input: {
        manualKey: {
          deviceId: $deviceId
          areaId: $areaId
          startDate: $startDate
          endDate: $endDate
        }
      }
    ) {
      manualKey {
        id
      }
    }
  }
`;

export const MANUAL_KEY_CHANGES = gqlTag`
  subscription ManualKeyChanges($manualKeyId: Int!) {
    manualKeyChanges(manualKeyId: $manualKeyId) {
      manualKey {
        id
        status
        statusMessage
      }
      event
    }
  }
`;

export const GET_CONFIGS_LIST = gqlTag`
  query AccessSystemConfigsList($propertyId: Int!) {
    accessSystemConfigsList(condition: { propertyId: $propertyId }) {
      id
      name
      accessSystemId
    }
  }
`;
