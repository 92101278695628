import { useState } from 'react';
import { useApolloClient } from '@apollo/client';
import * as requests from './requests';

const useOnSubmit = ({ propertyId, onComplete }) => {
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(null);

  const client = useApolloClient();

  const saveVoucher = async ({ variables }) => {
    setLoading(true);
    try {
      const { data: existVoucher, error: error1 } = await client.query({
        query: requests.GET_VOUCHER_TEMPLATE,
        variables: { propertyId },
        fetchPolicy: 'network-only',
      });

      if (error1) {
        setError(error1);
        return;
      }

      const { voucherTemplatesList } = existVoucher || {};
      const [{ id: voucherId } = {}] = voucherTemplatesList || [];

      if (voucherId) {
        const { error: error2 } = await client.mutate({
          mutation: requests.UPDATE_VOUCHER_TEMPLATE,
          variables: { ...variables, voucherId },
        });
        if (error2) setError(error2);
      } else if (variables.template) {
        const { error: error3 } = await client.mutate({
          mutation: requests.CREATE_VOUCHER_TEMPLATE,
          variables: { ...variables, propertyId },
        });
        if (error3) setError(error3);
      }

      onComplete();
      setError(null);
    } catch (e) {
      setError(e);
    } finally {
      setLoading(false);
    }
  };

  return { saveVoucher, loading, error };
};

export default useOnSubmit;
