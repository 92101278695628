/* eslint-disable indent */
import React, { useEffect, useState } from 'react';
import { ModalDialog } from '../../containers/dialog';
import {
  Button,
  Label,
  ListGroup,
  ListGroupItem,
  Spinner,
  UncontrolledAlert,
  UncontrolledTooltip,
  Input,
  FormGroup,
} from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faTv } from '@fortawesome/free-solid-svg-icons';
import { useQuery } from '@apollo/client';
import * as requests from './requests';
import { getStartPages, setStartPageTemplate } from '../../../api/kioskUiApi';
import ErrorLine from '../../containers/errorLine';

const DEFAULT_TEMPLATE_ID = 0;

const ChangeStartPage = ({
  startpageTemplates,
  startPageTemplateId,
  setStartPageTemplateId,
  deployedStartPages,
  deployAsDefault,
  setDeployAsDefault,
}) => {
  const ds = deployedStartPages && Object.values(deployedStartPages);

  return (
    <>
      {ds
        ? ds.reduce((p, c) => p + (c.is_default ? 1 : 0), 0) > 1 && (
            <ErrorLine
              error={{
                message:
                  "Found more than 1 default start page. Please 'Uninstall all' startpages and set up again.",
              }}
            />
          )
        : ''}

      <h5>Deployed</h5>
      <ListGroup flush>
        {deployedStartPages &&
          ds.map(x => (
            <ListGroupItem key={`deployedStartPage-${x.page_tag}`}>
              <code>{x.page_tag}</code> {x.is_default && '✅'}
            </ListGroupItem>
          ))}
      </ListGroup>

      <hr />

      <h5>Available</h5>
      <div
        className="mb-4 border"
        style={{ maxHeight: '224px', overflow: 'auto', borderRadius: '4px' }}
      >
        <ListGroup flush>
          {startpageTemplates.map((startPageTemplate, i) => {
            const selected = startPageTemplateId === startPageTemplate.id;
            return (
              <ListGroupItem
                key={`startPageTemplate-${i}`}
                onClick={() => setStartPageTemplateId(startPageTemplate.id)}
                active={selected}
                action
              >
                <div className="d-flex flex-row justify-content-between align-content-center align-items-start">
                  {`Start page template - ${startPageTemplate.id}`}
                  <code>{startPageTemplate.pageTag}</code>
                  <div>{selected && <FontAwesomeIcon icon={faCheck} />}</div>
                </div>
              </ListGroupItem>
            );
          })}
        </ListGroup>
      </div>
      <FormGroup check inline>
        <Input
          checked={deployAsDefault}
          type="checkbox"
          onChange={e => setDeployAsDefault(e.target.checked)}
        />
        <Label check>Set as default</Label>
      </FormGroup>
    </>
  );
};

const ChangeDesign = ({ data: cellData }) => {
  const subjectName = 'ChangeDesign';
  const { value } = cellData || {};
  const { deviceId, propertyId } = value || {};
  const buttonId = `${subjectName}-${deviceId}`;

  const [startPageTemplateId, setStartPageTemplateId] = useState();
  const [deployedStartPages, setDeployedStartPages] = useState();
  const [deployAsDefault, setDeployAsDefault] = useState(false);

  const {
    loading: loading1,
    error: error1,
    data,
  } = useQuery(requests.GET_STARTPAGE_TEMPLATES_LIST, {
    variables: { propertyId },
  });

  const [loading2, setLoading2] = useState(false);
  const [error2, setError2] = useState(false);
  const [complete, setComplete] = useState(false);

  useEffect(() => {
    (async () => {
      try {
        const { startpages } = await getStartPages(deviceId);
        setDeployedStartPages(startpages);
      } catch (error) {
        console.error(error);
        setError2(error);
      }
    })();
  }, [deviceId]);

  const onConfirm = async () => {
    setLoading2(true);
    try {
      await setStartPageTemplate(
        deviceId,
        startPageTemplateId,
        deployAsDefault,
      );
      setComplete(true);
      // uncheck the checkbox to prevent defaults > 1
      setDeployAsDefault(false);
    } catch (error) {
      setError2(error);
    } finally {
      setLoading2(false);
    }
  };

  const deleteAllStartpages = async () => {
    setLoading2(true);
    try {
      await setStartPageTemplate(deviceId, DEFAULT_TEMPLATE_ID);
      // setComplete(true);
    } catch (error) {
      setError2(error);
      console.error(error);
    } finally {
      setLoading2(false);
    }
  };

  const onClose = () => {
    setStartPageTemplateId(undefined);
    setLoading2(false);
    setError2(false);
    setComplete(false);
  };

  const loading = loading1 || loading2;
  const error = error1 || error2;
  const success = !error && !loading && complete;

  return (
    <>
      <ModalDialog
        buttonLabel={<FontAwesomeIcon icon={faTv} />}
        title="Change design"
        buttonSize="sm"
        buttonId={buttonId}
        onClose={onClose}
        footer={
          <>
            <Button
              onClick={deleteAllStartpages}
              color="danger"
              // disabled={}
            >
              Uninstall all
            </Button>
            <Button
              onClick={onConfirm}
              color="success"
              disabled={
                !startPageTemplateId &&
                startPageTemplateId !== DEFAULT_TEMPLATE_ID
              }
            >
              Confirm
            </Button>
          </>
        }
      >
        {loading && <Spinner color="secondary" />}
        {error && <ErrorLine error={error} />}
        {data && (
          <ChangeStartPage
            startpageTemplates={data.startpageTemplatesList}
            startPageTemplateId={startPageTemplateId}
            setStartPageTemplateId={setStartPageTemplateId}
            deployedStartPages={deployedStartPages}
            deployAsDefault={deployAsDefault}
            setDeployAsDefault={setDeployAsDefault}
          />
        )}
        {success && <UncontrolledAlert>Data has been saved</UncontrolledAlert>}
      </ModalDialog>
      <UncontrolledTooltip target={buttonId}>Change design</UncontrolledTooltip>
    </>
  );
};
export default ChangeDesign;
