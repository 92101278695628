import React from 'react';
import { Nav } from 'reactstrap';
import SectionLink from './sectionLink';

const SectionsList = ({ propertyId, links = [] }) => {
  const rootPath = `/property/${propertyId}`;

  return (
    <Nav vertical>
      {links.map((link, i) => (
        <SectionLink
          key={`link-${i}`}
          title={link.title}
          icon={link.icon}
          to={`${rootPath}${link.path && `/${link.path}`}`}
        />
      ))}
    </Nav>
  );
};

export default SectionsList;
