import React from 'react';
import gqlTag from 'graphql-tag';
import { useQuery } from '@apollo/client';
import { Spinner } from 'reactstrap';
import Table from '../containers/table/table';
import ErrorLine from '../containers/errorLine';
import SectionHead from './sectionsHead';

const AreaTypes = ({ propertyData, title, links }) => {
  const propertyId = +propertyData.property.id;
  const GET_AREA_TYPES_LIST = gqlTag`
    query AreaTypesList($propertyId: Int!) {
      areaTypesList(filter: { propertyId: { equalTo: $propertyId } }) {
        name
        id
        maxOccupancy
        description
        areas {
          totalCount
        }
      }
    }
  `;

  const { loading, error, data } = useQuery(GET_AREA_TYPES_LIST, {
    variables: { propertyId },
  });

  const columns = React.useMemo(
    () => [
      {
        Header: 'id',
        accessor: 'id',
        width: 80,
        flexGrow: 0,
      },
      {
        Header: 'Area type',
        accessor: 'name',
        width: 300,
        flexGrow: 1,
      },
      {
        Header: 'Max occupancy',
        accessor: 'maxOccupancy',
        flexGrow: 0.1,
      },
      {
        Header: 'Areas count',
        accessor: 'areas.totalCount',
        flexGrow: 0.1,
      },
    ],
    [],
  );

  return (
    <>
      <SectionHead propertyData={propertyData} title={title} links={links} />
      {loading && <Spinner color="secondary" />}
      {error && <ErrorLine error={error} />}
      {data && (
        <Table
          className="py-4"
          columns={columns}
          data={data.areaTypesList}
          options={{ disableFilters: true }}
        />
      )}
    </>
  );
};

export default AreaTypes;
