const messagingSystems = [
  { id: 'EMAIL', name: 'Email' },
  { id: 'MATRIX', name: 'Matrix' },
  { id: 'SMS', name: 'SMS' },
  { id: 'FACEBOOK', name: 'Facebook' },
  { id: 'WHATSAPP', name: 'WhatsApp' },
  { id: 'VIBER', name: 'Viber' },
  { id: 'TELEGRAM', name: 'Telegram' },
  { id: 'IRC', name: 'IRC' },
  { id: 'XMPP', name: 'XMPP' },
];

export default messagingSystems;
