import templateTypes from './templateTypes';
import messagingSystems from './messagingSystems';

const setOptionsLists = data => {
  const { languagesList } = data || {};

  return [
    {
      path: 'language',
      enum: languagesList.map(l => l.code),
      enumNames: languagesList.map(l => l.name),
    },
    {
      path: 'templateType',
      enum: templateTypes.map(t => t.id),
      enumNames: templateTypes.map(t => t.name),
    },
    {
      path: 'messagingSystem',
      enum: messagingSystems.map(s => s.id),
      enumNames: messagingSystems.map(s => s.name),
    },
  ];
};

export default setOptionsLists;
