import React, { useState } from 'react';
import {
  schema as schemaSource,
  uiSchema as uiSchemaSource,
} from '../../../schemas/preauthConfig';
import FormWrapper from '../../../form/formWrapper';
import { useMutation, useQuery } from '@apollo/client';
import * as requests from './requests';
import SectionHead from '../sectionsHead';
import { Spinner } from 'reactstrap';
import ErrorLine from '../../containers/errorLine';

const PreauthConfig = ({ data }) => {
  const subjectName = 'PreauthConfig';

  const currenciesList = data.currenciesList || [];
  const d = data.property || {};
  const conf = d.propertyConfig || {};

  const formData = {
    preauthAmount: conf.preauthAmount,
    preauthCurrencyId: conf.preauthCurrencyId,
    preauthType: conf.preauthType,
  };

  const optionsLists = [
    {
      path: 'preauthCurrencyId',
      enum: currenciesList.map(c => c.id),
      enumNames: currenciesList.map(c => c.alphabeticCode),
    },
  ];

  const [complete, setComplete] = useState(null);
  const [formKey, setFormKey] = useState(1);

  const [updatePreauthConfig, { loading, error }] = useMutation(
    requests.UPDATE_PREAUTH_CONFIG,
    {
      onCompleted: () => {
        setComplete(true);
        setFormKey(formKey + 1);
      },
    },
  );

  const onSubmit = data => {
    const variables = { configId: conf.id, ...data };
    updatePreauthConfig({ variables });
  };

  const success = complete && !loading && !error;

  return (
    <FormWrapper
      formData={formData}
      optionsLists={optionsLists}
      subjectName={subjectName}
      loading={loading}
      schemaSource={schemaSource}
      uiSchemaSource={uiSchemaSource}
      onSubmit={onSubmit}
      formKey={formKey}
      formContext={{ loading, success, error }}
      children={' '}
    />
  );
};

const PreauthConfigContainer = ({ propertyData, title, links }) => {
  const propertyId = +propertyData.property.id;

  const { data, loading, error } = useQuery(requests.GET_PREAUTH_CONFIG, {
    variables: { propertyId },
    fetchPolicy: 'network-only',
  });

  return (
    <div className="limited-container">
      <SectionHead propertyData={propertyData} title={title} links={links} />
      {loading && <Spinner color="secondary" />}
      {error && <ErrorLine error={error} />}
      {data && <PreauthConfig data={data} />}
    </div>
  );
};

export default PreauthConfigContainer;
