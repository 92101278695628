import React, { useContext, useState, useEffect } from 'react';
import { Router, useMatch } from '@reach/router';
import { navigate } from 'gatsby';
import { AbilityContext } from '../../auth/abilityContext';
import { Spinner } from 'reactstrap';
import { useQuery } from '@apollo/client';
import * as requests from './requests';
import SEO from '../seo';
import ErrorLine from '../containers/errorLine';
import PropertySections from './sections';
import { Sidebar } from './sidebar';
import PropertySidebarHead from './propertySidebarHead';
import SectionsList from './sectionsList';
import routes from './routes';

const PropertyRouter = ({ data, loading }) => {
  const propertyId = +data.property.id;
  const { propertySettings: { preauth } = {} } = data.property;
  const ability = useContext(AbilityContext);

  const routesToShow = routes.filter(r => {
    if (r.path === 'preauth' && !preauth) return false;
    if (r.path === 'preauth-config' && !preauth) return false;
    return ability.can('view', r.path);
  });

  const [showSidebar, setShowSidebar] = useState(true);

  const match = useMatch('/property/:param');
  useEffect(() => {
    if (match && showSidebar) {
      setShowSidebar(false);
    }
    if (!match) setShowSidebar(true);
  }, [match, showSidebar]);

  return (
    <div className={showSidebar ? 'page-with-sidebar' : ''}>
      {showSidebar && (
        <Sidebar>
          <PropertySidebarHead data={data} />
          <SectionsList propertyId={propertyId} links={routesToShow} />
        </Sidebar>
      )}
      <div className="pt-3">
        {loading && <Spinner color="secondary" />}
        <Router basepath={`/property/${propertyId}`}>
          <PropertySections propertyData={data} links={routesToShow} default />
          {routesToShow.map(({ Route, path, title }, i) => {
            return (
              <Route
                title={title}
                links={routesToShow}
                path={`/${path}`}
                propertyData={data}
                key={`${path}-${i}`}
              />
            );
          })}
        </Router>
      </div>
    </div>
  );
};

const PropertyContainer = ({ propertyId }) => {
  const { loading, error, data } = useQuery(requests.GET_PROPERTY, {
    variables: { propertyId },
    fetchPolicy: 'network-only',
  });

  if (data && !data.property) {
    navigate(`/404`, { replace: true });
    return null;
  } else {
    return (
      <>
        <SEO title={data ? data.property.name : 'Property page'} />
        {loading && !data && <Spinner color="secondary" />}
        {error && <ErrorLine error={error} />}
        {data && <PropertyRouter data={data} loading={loading} />}
      </>
    );
  }
};

export default PropertyContainer;
