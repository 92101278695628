import React from 'react';
import { Link } from 'gatsby';
import { NavItem } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCog } from '@fortawesome/free-solid-svg-icons';

const SectionLink = ({ to, icon, title }) => {
  return (
    <NavItem>
      <Link activeClassName="active" className="nav-link" to={to}>
        <div className="d-flex flex-row align-items-start">
          <div className="sidebar-icon mr-1">
            <FontAwesomeIcon icon={icon || faCog} />
          </div>
          <div className="sidebar-text">{title}</div>
        </div>
      </Link>
    </NavItem>
  );
};

export default SectionLink;
