export const proxyFields = {
  port: {
    title: 'Port',
    type: 'integer',
  },
  ip_address: {
    title: 'IP address',
    type: 'string',
  },
  type: {
    title: 'Type',
    type: 'string',
    default: 'socks5',
  },
  login: {
    title: 'Login',
    type: 'string',
  },
  password: {
    title: 'Password',
    type: 'string',
  },
};

export const schema = {
  type: 'object',
  required: ['name', 'accessSystemId'],
  properties: {
    name: {
      type: 'string',
      title: 'Name',
    },
    accessSystemId: {
      type: 'number',
      title: 'Access system',
      enum: [],
    },
    proxySwitch: {
      type: 'boolean',
      title: 'Proxy',
      enum: [true, false],
    },
  },
  dependencies: {
    proxySwitch: {
      oneOf: [
        {
          properties: {
            proxySwitch: {
              enum: [true],
            },
            proxy: {
              type: 'object',
              title: '',
              properties: {
                ...proxyFields,
                custom: {
                  type: 'string',
                  title: 'Custom proxy fields',
                  default: '{}',
                  description: 'JSON object',
                  maxLength: 1024,
                },
              },
            },
          },
        },
        {
          properties: {
            proxySwitch: {
              enum: [false],
            },
          },
        },
      ],
    },
  },
};

export const uiSchema = {
  'ui:order': ['name', 'accessSystemId', '*', 'proxySwitch', 'proxy'],
  'proxySwitch': {
    'ui:widget': 'Switch',
    'ui:enumDisabled': [true],
    'ui:options': {
      label: false,
    },
  },
  'proxy': {
    password: {
      'ui:widget': 'password',
      'ui:options': {
        autocomplete: 'new-password',
      },
    },
    custom: {
      'ui:widget': 'textarea',
    },
  },
};

export const customFormats = {
  password: /^[0-9]{0,6}$/iu,
};
