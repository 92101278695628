import React from 'react';
import { Match } from '@reach/router';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'gatsby';
import { SidebarModal } from './sidebar';
import PropertySidebarHead from './propertySidebarHead';
import SectionsList from './sectionsList';
import '../../styles/sectionHead.css';

const SectionHead = ({ propertyData, title, links }) => {
  const propertyId = +propertyData.property.id;
  const rootPath = `/property/${propertyId}`;
  return (
    <div className="section-head d-flex align-items-center mb-3 bg-light p-2">
      <SidebarModal>
        <PropertySidebarHead data={propertyData} />
        <SectionsList propertyId={propertyId} links={links} />
      </SidebarModal>
      <div className="d-flex flex-row flex-wrap align-items-center">
        <Match path={rootPath}>
          {props =>
            props.match ? (
              <span className="mr-2 active">{propertyData.property.name}</span>
            ) : (
              <>
                <Link to={rootPath}>
                  <span className="mr-2">{propertyData.property.name}</span>
                </Link>
                <FontAwesomeIcon
                  icon={faChevronRight}
                  className="mr-2"
                  size="xs"
                />
                <span className="active">{title}</span>
              </>
            )
          }
        </Match>
      </div>
    </div>
  );
};

export default SectionHead;
