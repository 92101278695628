import React, { useState } from 'react';
import { useMutation } from '@apollo/client';
import { Spinner, Button } from 'reactstrap';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import usePreauthSubscription from './usePreauthSubscription';
import * as requests from './requests';

const CancelPreauth = ({ cell }) => {
  const [skipPreauthSubscription, setSkipPreauthSubscription] = useState(true);

  const [update, { loading: loadingCancel }] = useMutation(
    requests.CANCEL_PREAUTH,
    {
      onError: error => {
        cell.row.setState(state => ({
          ...state,
          alert: { color: 'danger', message: `Error: ${error.message}` },
        }));
        setSkipPreauthSubscription(true);
        cell.row.setState(state => ({ ...state, disabled: false }));
      },
      onCompleted: () => {
        setSkipPreauthSubscription(false);
        cell.row.setState(state => ({ ...state, disabled: true }));
      },
    },
  );

  const { id, amount } = cell.row.original;
  // pass original preauht amount to `preauthCancel`
  // todo: allow a lower/manual cancel amount?
  const onCancel = () => update({ variables: { id, amount } });

  usePreauthSubscription(
    cell.row.setState,
    id,
    skipPreauthSubscription,
    setSkipPreauthSubscription,
  );

  const loading = loadingCancel || !skipPreauthSubscription;

  return (
    <span onClick={e => e.stopPropagation()}>
      {cell.row.state.active && (
        <div className="inline-form-sm" style={{ height: '100%' }}>
          {loading && <Spinner size="sm" />}
          {!loading && (
            <Button
              outline
              color="secondary"
              size="sm"
              onClick={() => onCancel()}
              style={{ boxShadow: 'none', minWidth: '32px' }}
              className="mr-2"
              disabled={cell.row.state.disabled}
            >
              <FontAwesomeIcon icon={faTimes} className="button-icon" />
            </Button>
          )}
        </div>
      )}
    </span>
  );
};

export default CancelPreauth;
