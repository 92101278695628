import React from 'react';
import { useQuery } from '@apollo/client';
import { Card, CardHeader, Spinner, ListGroup } from 'reactstrap';
import '../../../styles/guest.css';
import ErrorLine from '../../containers/errorLine';
import * as request from './requests';
import GuestsListItem from './guestsListItem';
import PaymentsListItem from './paymentsListItem';
import PreauthsListItem from './preauthsListItem';
import '../../../styles/bookingSetExpandCard.css';

const BookingSet = ({ data, timezone, currenciesList }) => {
  const { bookingSet } = data;
  const { bookingsList, billsList, preauthsList } = bookingSet || {};
  const guests = bookingsList.reduce((guests, booking) => {
    const { guestsList, pmsBookingId, area, startDate, endDate } = booking;
    if (guestsList.length === 0) {
      return [...guests, { pmsBookingId, area, startDate, endDate }];
    }
    const _guests_ = guestsList.map(g => ({
      ...g,
      pmsBookingId,
      area,
      startDate,
      endDate,
    }));
    return [...guests, ..._guests_];
  }, []);

  const payments = billsList.reduce((payments, bill) => {
    const { paymentsList } = bill;
    return [...payments, ...paymentsList];
  }, []);

  const bookingSetData = [
    {
      title: 'Guests',
      data: guests,
      ListItem: GuestsListItem,
    },
    {
      title: 'Payments',
      data: payments,
      ListItem: PaymentsListItem,
    },
    {
      title: 'Pre-authorizations',
      data: preauthsList,
      ListItem: PreauthsListItem,
    },
  ];
  return (
    <div className="row">
      {bookingSetData.map(({ title, data, ListItem }, i) => {
        return (
          <div className="col-12 col-xl-6 mb-4" key={`bookingSet-card-${i}`}>
            <Card>
              <CardHeader>{title}</CardHeader>
              <ListGroup flush>
                {data.length === 0 && (
                  <div className="text-center m-3 text-secondary">
                    <p>None records</p>
                  </div>
                )}
                {data.map((dataSet, i) => {
                  return (
                    <ListItem
                      data={dataSet}
                      timezone={timezone}
                      currenciesList={currenciesList}
                      key={`bookingSet-item-${i}`}
                    />
                  );
                })}
              </ListGroup>
            </Card>
          </div>
        );
      })}
    </div>
  );
};

const BookingSetContainer = ({ row }) => {
  const bookingSetId = row.original.id;
  const timezone = row.original.timezone;
  const currenciesList = row.original.currenciesList;

  const { loading, error, data } = useQuery(request.GET_BOOKING_SET, {
    variables: { bookingSetId },
    fetchPolicy: 'network-only',
  });

  return (
    <>
      {loading && <Spinner color="secondary" />}
      {error && <ErrorLine error={error} />}
      {data && (
        <BookingSet
          data={data}
          timezone={timezone}
          currenciesList={currenciesList}
        />
      )}
    </>
  );
};

export default BookingSetContainer;
