import React, { useState, useContext } from 'react';
import SectionHead from '../sectionsHead';
import { AbilityContext } from '../../../auth/abilityContext';
import * as requests from './paymentSystemsRequests';
import { useQuery } from '@apollo/client';
import { Button, Spinner, UncontrolledTooltip } from 'reactstrap';
import ErrorLine from '../../containers/errorLine';
import Widget from '../../containers/widget';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { paymentSystemDevice } from '../../../api/tasker';
import {
  faCalculator,
  faLock,
  faLockOpen,
  faPlusCircle,
  faRetweet,
  faWrench,
} from '@fortawesome/free-solid-svg-icons';
import PaymentSystemDevice from './paymentSystemDevice';

const PaymentSystemDevices = ({ propertyData, title, links }) => {
  const propertyId = +propertyData.property.id;

  const {
    loading,
    error,
    data = {},
  } = useQuery(requests.GET_DEVICES, {
    variables: { propertyId },
  });
  const { devicesList = [] } = data;
  const paymentDevises = [];
  devicesList.forEach(d => {
    (d.paymentSystemDevicesList || []).forEach(p => {
      paymentDevises.push({ deviceName: d.name, ...p });
    });
  });

  const [newKey, setNewKey] = useState(1);
  const [addNew, setAddNew] = useState(false);

  const onAdded = () => {
    setNewKey(newKey + 1);
    setAddNew(false);
  };

  const ability = useContext(AbilityContext);

  return (
    <>
      <SectionHead propertyData={propertyData} title={title} links={links} />
      {loading && <Spinner color="secondary" />}
      {error && <ErrorLine error={error} />}
      {!loading && data && (
        <div className="row py-4">
          {paymentDevises.map((paymentDevice, i) => (
            <Widget
              header={
                <div style={{ display: 'flex', columnGap: '6px' }}>
                  <div>{paymentDevice.deviceName}</div>
                  <div style={{ marginLeft: 'auto' }}>
                    <Button
                      size="sm"
                      id="psd-totals"
                      onClick={() =>
                        paymentSystemDevice.totals(paymentDevice.id)
                      }
                    >
                      <FontAwesomeIcon icon={faCalculator} />
                    </Button>
                    <UncontrolledTooltip target="psd-totals">
                      Totals
                    </UncontrolledTooltip>
                  </div>
                  <div>
                    <Button
                      size="sm"
                      id="psd-closePeriod"
                      onClick={() =>
                        paymentSystemDevice.closePeriod(paymentDevice.id)
                      }
                    >
                      <FontAwesomeIcon icon={faLock} />
                    </Button>
                    <UncontrolledTooltip target="psd-closePeriod">
                      Close period
                    </UncontrolledTooltip>
                  </div>
                  <div>
                    <Button
                      size="sm"
                      id="psd-openPeriod"
                      onClick={() =>
                        paymentSystemDevice.openPeriod(paymentDevice.id)
                      }
                    >
                      <FontAwesomeIcon icon={faLockOpen} />
                    </Button>
                    <UncontrolledTooltip target="psd-openPeriod">
                      Open period
                    </UncontrolledTooltip>
                  </div>
                  <div>
                    <Button
                      size="sm"
                      id="psd-reboot"
                      onClick={() =>
                        paymentSystemDevice.reboot(paymentDevice.id)
                      }
                    >
                      <FontAwesomeIcon icon={faRetweet} />
                    </Button>
                    <UncontrolledTooltip target="psd-reboot">
                      Reboot
                    </UncontrolledTooltip>
                  </div>
                  <div>
                    <Button
                      size="sm"
                      id="psd-maintenance"
                      onClick={() =>
                        paymentSystemDevice.maintenance(paymentDevice.id, 180)
                      }
                    >
                      <FontAwesomeIcon icon={faWrench} />
                    </Button>
                    <UncontrolledTooltip target="psd-maintenance">
                      Maintenance
                    </UncontrolledTooltip>
                  </div>
                </div>
              }
              key={`paymentDevice_${paymentDevice.id}_${i}`}
              content={
                <PaymentSystemDevice
                  data={{ data, paymentDevice, propertyId }}
                />
              }
            />
          ))}
          {addNew && (
            <Widget
              header="Add new POS"
              key={newKey}
              content={
                <PaymentSystemDevice
                  addNew={addNew}
                  setAddNew={setAddNew}
                  data={{ data, propertyId }}
                  onSaved={onAdded}
                />
              }
            />
          )}
          {!addNew && ability.can('update', 'PaymentSystemDevices') && (
            <div className="col-sd-12 col-md-6 col-lg-6 col-xl-4">
              {devicesList.length === 0 && (
                <p className="text-danger">
                  You cannot add a new POS until the kiosk device is added to
                  this property.
                </p>
              )}
              <Button
                onClick={() => setAddNew(true)}
                disabled={devicesList.length === 0}
                block
                outline
              >
                <FontAwesomeIcon icon={faPlusCircle} className="mr-2" />
                Add new POS
              </Button>
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default PaymentSystemDevices;
