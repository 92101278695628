import gqlTag from 'graphql-tag';

export const GET_PROPERTIES_PAYMENT_NETWORKS = gqlTag`
  query PropertiesPaymentNetworksList($propertyId: Int!) {
    propertiesPaymentNetworksList(condition: { propertyId: $propertyId }) {
      id
      code
      primary
      propertyId
      paymentNetworkId
      paymentNetwork {
        id
        displayName
      }
    }
    paymentNetworksList {
      id
      displayName
    }
  }
`;

export const GET_PROPERTIES_PAYMENT_NETWORK = gqlTag`
  query PropertiesPaymentNetwork($id: Int! $propertyId: Int!) {
    propertiesPaymentNetwork(id: $id) {
      id
      code
      primary
      propertyId
      paymentNetworkId
    }
    propertiesPaymentNetworksList(condition: { propertyId: $propertyId }) {
      id
      paymentNetworkId
    }
    paymentNetworksList {
      id
      displayName
    }
  }
`;

export const GET_PROPERTIES_PAYMENT_NETWORK_BY_ID = gqlTag`
  query PropertiesPaymentNetwork($id: Int!) {
    propertiesPaymentNetwork(id: $id) {
      id
      code
      primary
      propertyId
      paymentNetworkId
    }
  }
`;

export const GET_PRIMARY_PROPERTIES_PAYMENT_NETWORKS = gqlTag`
  query PropertiesPaymentNetworksList($propertyId: Int!) {
    propertiesPaymentNetworksList(condition: {propertyId: $propertyId, primary: true}) {
      id
    }
  }
`;

export const UPDATE_PROPERTIES_PAYMENT_NETWORK = gqlTag`
  mutation UpdatePropertiesPaymentNetwork(
    $id: Int!
    $code: String!
  ) {
    updatePropertiesPaymentNetwork(
      input: {
        patch: {
          code: $code
        }
        id: $id
      }
    ) {
      propertiesPaymentNetwork {
        id
        code
      }
    }
  }
`;

export const UPDATE_PROPERTIES_PAYMENT_NETWORK_PRIMARY = gqlTag`
  mutation UpdatePropertiesPaymentNetwork(
    $id: Int!
    $primary: Boolean
  ) {
    updatePropertiesPaymentNetwork(
      input: {
        patch: {
          primary: $primary
        }
        id: $id
      }
    ) {
      propertiesPaymentNetwork {
        id
        propertyId
        paymentNetworkId
        primary
        code
      }
    }
  }
`;

export const CREATE_PROPERTIES_PAYMENT_NETWORK = gqlTag`
  mutation CreatePropertiesPaymentNetwork(
    $propertyId: Int!
    $paymentNetworkId: Int!
    $code: String!
  ) {
    createPropertiesPaymentNetwork(
      input: {
        propertiesPaymentNetwork: {
          propertyId: $propertyId
          paymentNetworkId: $paymentNetworkId
          code: $code
        }
      }
    ) {
      propertiesPaymentNetwork {
        id
        propertyId
        paymentNetworkId
        code
      }
    }
  }
`;

export const DELETE_PROPERTIES_PAYMENT_NETWORK = gqlTag`
  mutation DeletePropertiesPaymentNetwork($id: Int!) {
    deletePropertiesPaymentNetwork(input: { id: $id }) {
      propertiesPaymentNetwork {
        id
      }
    }
  }
`;
