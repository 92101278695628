import React from 'react';
import Layout from '../components/containers/layout';
import Property from '../components/property';

const PropertyPage = ({ id }) => {
  if (typeof window === 'undefined') return null;
  const propertyId = +id;
  return (
    <Layout>
      <Property propertyId={propertyId} />
    </Layout>
  );
};

export default PropertyPage;
