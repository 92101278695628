import gqlTag from 'graphql-tag';

export const GET_CONFIGS_LIST = gqlTag`
  query AccessSystemConfigsList($propertyId: Int!) {
    accessSystemConfigsList(condition: { propertyId: $propertyId }) {
      id
      name
      config
      accessSystemId
      accessSystem {
        id
        name
      }
    }
    accessSystemsList {
      id
      name
      accessSystemConfigTemplate
    }
  }
`;

export const UPDATE_CONFIG = gqlTag`
  mutation UpdateAccessSystemConfig(
    $id: Int!
    $config: JSON
    $name: String
    $accessSystemId: Int!
  ) {
    updateAccessSystemConfig(
      input: {
        patch: { config: $config, name: $name, accessSystemId: $accessSystemId }
        id: $id
      }
    ) {
      accessSystemConfig {
        id
        name
        config
        accessSystemId
        accessSystem {
          id
          name
        }
      }
    }
  }
`;

export const CREATE_CONFIG = gqlTag`
  mutation createAccessSystemConfig(
    $propertyId: Int!
    $config: JSON
    $name: String!
    $accessSystemId: Int!
  ) {
    createAccessSystemConfig(
      input: {
        accessSystemConfig: {
          propertyId: $propertyId
          accessSystemId: $accessSystemId
          name: $name
          config: $config
        }
      }
    ) {
      accessSystemConfig {
        id
        name
        config
        accessSystemId
        accessSystem {
          id
          name
        }
      }
    }
  }
`;

export const DELETE_CONFIG = gqlTag`
  mutation deleteAccessSystemConfig($id: Int!) {
    deleteAccessSystemConfig(input: { id: $id }) {
      accessSystemConfig {
        id
        name
      }
    }
  }
`;
