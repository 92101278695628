import React, { useState } from 'react';
import {
  schema as schemaSource,
  uiSchema as uiSchemaSource,
} from '../../../schemas/propertyInformation';
import FormWrapper from '../../../form/formWrapper';
import { useMutation, useQuery } from '@apollo/client';
import * as requests from './requests';
import SectionHead from '../sectionsHead';
import { Spinner } from 'reactstrap';
import ErrorLine from '../../containers/errorLine';
import parseString from '../../../lib/parseString';

const PropertyInformation = ({ data }) => {
  const subjectName = 'PropertyInformation';
  const { property, propertyTypesList } = data || {};

  const formData = {
    ...property,
    address: JSON.stringify(property.address),
  };

  const optionsLists = [
    {
      path: 'propertyTypeId',
      enum: propertyTypesList.map(t => t.id),
      enumNames: propertyTypesList.map(t => t.name),
    },
  ];

  const [complete1, setComplete1] = useState(null);
  const [complete2, setComplete2] = useState(null);
  const [formKey, setFormKey] = useState(1);

  const [updateProperty, { loading: loadingProperty, error: errorProperty }] =
    useMutation(requests.UPDATE_PROPERTY, {
      onCompleted: () => {
        setComplete1(true);
        setFormKey(formKey + 1);
      },
    });

  const [updateLogo, { loading: loadingLogo, error: errorLogo }] = useMutation(
    requests.UPDATE_LOGO,
    {
      onCompleted: () => {
        setComplete2(true);
        setFormKey(formKey + 1);
      },
    },
  );

  const onSubmit = data => {
    const variables1 = { propertyId: property.id, ...data };
    const variables2 = {
      settingsId: property.propertySettings.id,
      logo: data.logo,
    };
    if ('address' in variables1) {
      variables1.address = parseString(variables1.address);
    }
    updateProperty({ variables: variables1 });
    updateLogo({ variables: variables2 });
  };

  const loading = loadingProperty || loadingLogo;
  const error = errorProperty || errorLogo;
  const success =
    complete1 && complete2 && !loading && !errorProperty && !errorLogo;

  return (
    <FormWrapper
      formData={formData}
      optionsLists={optionsLists}
      subjectName={subjectName}
      loading={loading}
      schemaSource={schemaSource}
      uiSchemaSource={uiSchemaSource}
      onSubmit={onSubmit}
      formKey={formKey}
      formContext={{ loading, success, error }}
      children={' '}
    />
  );
};

const PropertyInformationContainer = ({ propertyData, title, links }) => {
  const propertyId = +propertyData.property.id;

  const { data, loading, error } = useQuery(requests.GET_PROPERTY, {
    variables: { propertyId },
    fetchPolicy: 'network-only',
  });

  return (
    <div className="limited-container">
      <SectionHead propertyData={propertyData} title={title} links={links} />
      {loading && <Spinner color="secondary" />}
      {error && <ErrorLine error={error} />}
      {data && <PropertyInformation data={data} />}
    </div>
  );
};

export default PropertyInformationContainer;
