import React, { useState } from 'react';
import {
  ButtonDropdown,
  DropdownItem,
  DropdownToggle,
  DropdownMenu,
  CustomInput,
  FormGroup,
  Badge,
} from 'reactstrap';

const TagSelect = ({
  value = [],
  mainCheckboxLabel = 'Any',
  options,
  onChange,
  className,
  style,
}) => {
  const [dropdownOpen, setOpen] = useState(false);
  const toggle = () => setOpen(!dropdownOpen);

  const onCheck = (id, checked) => {
    if (id === 'main') {
      onChange([]);
      return;
    }
    if (id && checked) {
      onChange(prev => [...prev, id]);
    } else {
      onChange(prev => prev.filter(v => v !== id));
    }
  };

  const focusStyle = {
    color: '#495057',
    borderColor: '#80bdff',
    outline: 0,
    boxShadow:
      'inset 0 1px 1px rgb(0 0 0 / 8%), 0 0 0 0.2rem rgb(0 123 255 / 25%)',
  };

  return (
    <ButtonDropdown
      isOpen={dropdownOpen}
      toggle={toggle}
      className={className}
      style={style}
    >
      <DropdownToggle
        tag="div"
        className="custom-select py-1 pl-2 pr-3"
        style={{
          overflow: 'hidden',
          whiteSpace: 'nowrap',
          textOverflow: 'ellipsis',
          ...(dropdownOpen ? focusStyle : {}),
        }}
      >
        {value
          .map(id => options.find(option => option.id === id))
          .map(option => (
            <Badge color={option.color || 'secondary'} key={`key-${option.id}`}>
              {option.name}
            </Badge>
          ))}
        {!value.length && (
          <Badge color={'secondary'}>{mainCheckboxLabel}</Badge>
        )}
      </DropdownToggle>
      <DropdownMenu>
        <FormGroup className="pl-3 pt-2">
          <CustomInput
            type="checkbox"
            id="check-main"
            label={mainCheckboxLabel}
            checked={!value.length}
            onChange={e => onCheck('main', e.target.checked)}
          />
        </FormGroup>
        <DropdownItem divider />
        <FormGroup className="pl-3">
          {options.map(({ id, name }) => (
            <CustomInput
              key={`select-${id}`}
              type="checkbox"
              id={`check-${id}`}
              checked={!!value.find(valueId => valueId === id)}
              onChange={e => onCheck(id, e.target.checked)}
              label={name}
            />
          ))}
        </FormGroup>
      </DropdownMenu>
    </ButtonDropdown>
  );
};

export default TagSelect;
