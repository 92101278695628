const getBookingSetList = ({ bookingSetsList, currenciesList }, timezone) => {
  return bookingSetsList.map(bs => {
    const {
      id,
      reference,
      startDate,
      endDate,
      bookingsList,
      preauthsList,
      billsList,
    } = bs;
    const charges = billsList.reduce((chargesTotal, bill) => {
      const { chargesList } = bill;
      return [...chargesTotal, ...chargesList];
    }, []);
    const payments = billsList.reduce((paymentsTotal, bill) => {
      const { paymentsList } = bill;
      return [...paymentsTotal, ...paymentsList];
    }, []);

    const chargeSum = charges.reduce((chargeTotal, charge) => {
      const { grossAmount } = charge;
      return +(chargeTotal + Number(grossAmount)).toFixed(2);
    }, 0);
    const pmsPaymentSum = payments.reduce((paidTotal, payment) => {
      const { amount, deviceId, status } = payment;
      if ((status === 'SUCCESS' || status === 'PAID_OUT') && !deviceId) {
        return +(paidTotal + Number(amount)).toFixed(2);
      } else {
        return paidTotal;
      }
    }, 0);
    const paidOnKiosk = payments.reduce((paidTotal, payment) => {
      const { amount, deviceId, status } = payment;
      if ((status === 'SUCCESS' || status === 'PAID_OUT') && deviceId) {
        return +(paidTotal + Number(amount)).toFixed(2);
      } else {
        return paidTotal;
      }
    }, 0);
    const unsentPaymentSum = payments.reduce((paidTotal, payment) => {
      const { amount, deviceId, status, sentToPms } = payment;
      if (
        (status === 'SUCCESS' || status === 'PAID_OUT') &&
        deviceId &&
        sentToPms === false
      ) {
        return +(paidTotal + Number(amount)).toFixed(2);
      } else {
        return paidTotal;
      }
    }, 0);
    const remaining = +(chargeSum - pmsPaymentSum - unsentPaymentSum).toFixed(
      2,
    );
    const [{ currencyId: paymentCurrencyId } = {}] = bookingsList;

    const areas = bookingsList.reduce((areas, booking) => {
      const { area } = booking || {};
      const { name } = area || {};
      if (name) return `${areas ? `${areas}, ` : ''}${name}`;
      return areas;
    }, '');
    const { preauthAmount, currencyId: preauthCurrencyId } =
      preauthsList.reduce(
        (total, preauth) => {
          const { amount, currencyId, status } = preauth || {};
          if (status !== 'SUCCESS') return total;
          return {
            preauthAmount: +(total.preauthAmount + amount).toFixed(2),
            currencyId,
          };
        },
        { preauthAmount: 0 },
      );
    return {
      id,
      timezone,
      currenciesList,
      reference,
      startDate,
      endDate,
      areas,
      paid: paidOnKiosk,
      remaining,
      preauthAmount,
      paymentCurrencyId,
      preauthCurrencyId,
    };
  });
};

export default getBookingSetList;
