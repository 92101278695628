import getZonedTime from '../../../lib/getZonedTime';
import {
  Badge,
  ListGroupItem,
  Modal,
  ModalBody,
  ModalHeader,
} from 'reactstrap';
import React, { useState } from 'react';
import guestStatuses from './guestStatuses';
import Guest from '../guests/guest';
import getStatus from './getStatus';

const GuestsListItem = ({ data, timezone }) => {
  const { id, firstName, lastName, area, status, startDate, endDate } = data;

  const checkIn = startDate && getZonedTime(startDate, timezone);
  const checkOut = endDate && getZonedTime(endDate, timezone);

  const [modal, setModal] = useState(false);
  const toggle = () => {
    if (id) setModal(!modal);
  };

  const guestStatus = getStatus(guestStatuses, status);

  return (
    <ListGroupItem onClick={toggle} action={!!id}>
      <div className="d-flex flex-column">
        <div className="d-flex flex-row justify-content-between align-items-center">
          <span className="font-weight-bold">
            {firstName} {lastName}
          </span>
          <Badge color={guestStatus.color || 'secondary'}>
            {guestStatus.name || status}
          </Badge>
        </div>
        <div className="d-flex flex-row flex-wrap justify-content-between align-items-start">
          <div className="d-flex flex-column">
            <div>
              <span className="list-item-title">Stay: </span>
              <span className="list-item-text">
                {checkIn} - {checkOut}
              </span>
            </div>
          </div>
          <div>
            <span className="list-item-title">Area: </span>
            <span className="list-item-text">{area.name}</span>
          </div>
        </div>
      </div>
      <Modal
        isOpen={modal}
        toggle={toggle}
        returnFocusAfterClose={false}
        size="lg"
      >
        <ModalHeader toggle={toggle}>Guest</ModalHeader>
        <ModalBody>
          <Guest row={{ original: { id } }} />
        </ModalBody>
      </Modal>
    </ListGroupItem>
  );
};

export default GuestsListItem;
