import React from 'react';
import { useQuery } from '@apollo/client';
import { Spinner } from 'reactstrap';
import Table from '../../containers/table/table';
import ErrorLine from '../../containers/errorLine';
import SectionHead from '../sectionsHead';
import * as request from './messagingRequests';
import AddMessagingTemplate from './addMessagingTemplate';
import UpdateMessagingTemplate from './updateMessagingTemplate';
import templateTypes from './templateTypes';

const Messaging = ({ propertyData, title, links }) => {
  const propertyId = +propertyData.property.id;

  const { loading, error, data } = useQuery(
    request.GET_MESSAGING_TEMPLATES_LIST,
    {
      variables: { propertyId },
    },
  );

  const getTemplateType = row =>
    templateTypes.filter(type => type.id === row.templateType)[0] || {};

  const columns = React.useMemo(
    () => [
      {
        Header: 'id',
        accessor: 'id',
        width: 80,
        flexGrow: 0,
      },
      {
        Header: 'Subject',
        accessor: 'templateSubject',
        width: 300,
      },
      {
        Header: 'Type',
        accessor: row => getTemplateType(row).name || row.templateType,
        flexGrow: 0.5,
      },
      {
        Header: 'Language',
        accessor: 'language',
        width: 100,
        flexGrow: 0.2,
      },
      {
        Header: 'Messaging system',
        accessor: 'messagingSystem',
        width: 120,
        flexGrow: 0.2,
      },
    ],
    [],
  );

  return (
    <>
      <SectionHead propertyData={propertyData} title={title} links={links} />
      {loading && <Spinner color="secondary" />}
      {error && <ErrorLine error={error} />}
      {data && (
        <>
          <AddMessagingTemplate propertyId={propertyId} data={data} />
          <Table
            className="py-4"
            columns={columns}
            ExpandCard={UpdateMessagingTemplate}
            data={data.messagingTemplatesList}
            options={{ disableFilters: true }}
          />
        </>
      )}
    </>
  );
};

export default Messaging;
