const setOptionsLists = data => {
  const { accessSystemsList } = data || {};

  return [
    {
      path: 'accessSystemId',
      enum: accessSystemsList.map(t => t.id),
      enumNames: accessSystemsList.map(t => t.name),
    },
  ];
};

export default setOptionsLists;
