import React from 'react';
import { useSubscription } from '@apollo/client';
import * as requests from './requests';
import isActive from './isActive';

const usePreauthSubscription = (setRowState, preauthId, skip, setSkip) => {
  useSubscription(requests.PREAUTH_TRANSACTION_CHANGES, {
    variables: { preauthId },
    skip,
    fetchPolicy: 'network-only',
    onSubscriptionData: data => {
      const {
        data: { preauthTransactionChanges },
      } = data.subscriptionData;
      const { preauthTransaction } = preauthTransactionChanges || {};
      const {
        preauthId: _preauthId_,
        success,
        statusMessage,
        errorMessage,
        preauth,
      } = preauthTransaction || {};
      const { status } = preauth || {};
      if (_preauthId_ === preauthId) {
        setRowState(state => ({
          ...state,
          alert: {
            color:
              success === null ? 'secondary' : success ? 'success' : 'danger',
            message: (
              <>
                {errorMessage && <div>{errorMessage}</div>}
                {statusMessage && <div>{statusMessage}</div>}
              </>
            ),
          },
          disabled: false,
          active: isActive(status),
        }));
        if (success !== null) {
          setSkip(true);
        }
      }
    },
  });
};

export default usePreauthSubscription;
