const isActive = status => {
  switch (status) {
    case 'PENDING':
    case 'PROCESSING':
    case 'REJECTED':
    case 'SUCCESS':
    case 'INITIATED':
      return true;
    case 'FINALIZED':
    case 'FAILED':
    case 'CANCELED':
      return false;
    default:
      return false;
  }
};

export default isActive;
