const guestStatuses = [
  {
    id: 'NO_SHOW',
    name: 'No show',
    color: 'secondary',
  },
  {
    id: 'CANCELLED',
    name: 'Cancelled',
    color: 'secondary',
  },
  {
    id: 'CHECKED_IN',
    name: 'Checked-in',
    color: 'success',
  },
  {
    id: 'CHECKED_OUT',
    name: 'Checked-out',
    color: 'secondary',
  },
  {
    id: 'OUR',
    name: 'Our',
    color: 'secondary',
  },
  {
    id: 'CONFIRMED',
    name: 'Confirmed',
    color: 'success',
  },
  {
    id: 'ACTIVE',
    name: 'Active',
    color: 'success',
  },
  {
    id: 'EXPECTED',
    name: 'Expected',
    color: 'secondary',
  },
  {
    id: 'PENDING',
    name: 'Pending',
    color: 'secondary',
  },
];

export default guestStatuses;
