import React, { useState } from 'react';
import { useMutation } from '@apollo/client';
import { Button, Spinner, Alert } from 'reactstrap';
import { Can } from '../../../auth/abilityContext';
import { ModalDialog } from '../../containers/dialog';
import * as request from './messagingRequests';
import ErrorLine from '../../containers/errorLine';
import { cloneDeep, set } from 'lodash';
import setOptionsLists from './setOptionsLists';
import {
  schema as schemaSource,
  uiSchema as uiSchemaSource,
} from '../../../schemas/messaging';
import FormWrapper from '../../../form/formWrapper';
import submit from '../../../form/syntheticSubmit';

const AddMessagingTemplate = ({ data, propertyId }) => {
  const subjectName = 'CreateMessagingTemplate';

  const [form, setForm] = useState();
  const [formKey, setFormKey] = useState(1);
  const [complete, setComplete] = useState(false);

  const [createMessagingTemplate, { loading, error }] = useMutation(
    request.CREATE_MESSAGING_TEMPLATE,
    {
      onCompleted: () => {
        setComplete(true);
        setFormKey(formKey + 1);
      },
      refetchQueries: [
        {
          query: request.GET_MESSAGING_TEMPLATES_LIST,
          variables: { propertyId },
        },
      ],
    },
  );

  const onSubmit = data => {
    const variables = { ...data, propertyId };
    createMessagingTemplate({ variables });
  };

  const success = !!complete && !loading && !error;

  const onDismiss = () => setComplete(false);

  const setUiSchema = () => {
    const newUiSchema = cloneDeep(uiSchemaSource);
    set(newUiSchema, 'id["ui:widget"]', 'hidden');
    return newUiSchema;
  };

  return (
    <Can I="create" a={subjectName}>
      <div className="mb-3">
        <ModalDialog
          buttonLabel="Add messaging template"
          buttonColor="success"
          title="Add messaging template"
          size="lg"
          onClose={onDismiss}
          footer={
            <Button onClick={() => submit(form)} color="success">
              Save data
            </Button>
          }
        >
          <FormWrapper
            optionsLists={setOptionsLists(data)}
            subjectName={subjectName}
            loading={loading}
            schemaSource={schemaSource}
            uiSchemaSource={setUiSchema()}
            onSubmit={onSubmit}
            setForm={setForm}
            formKey={formKey}
            clearForm={true}
            children={' '}
          />
          {loading && <Spinner color="secondary" />}
          {error && <ErrorLine error={error} />}
          {success && (
            <Alert isOpen={success} toggle={onDismiss}>
              Messaging template has been added
            </Alert>
          )}
        </ModalDialog>
      </div>
    </Can>
  );
};

export default AddMessagingTemplate;
