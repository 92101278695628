import React from 'react';
import PropertyLogo from '../../propertyLogo';
import '../../../styles/propertyHead.css';

const PropertyHead = ({ data }) => {
  const d = data.property;
  return (
    <div className="property-head mb-4">
      <div className="row">
        <div className="col-md-12 col-lg-6">
          <div className="d-flex flex-row property-card-body">
            <PropertyLogo property={d} />
            <div className="property-information">
              <h3>{d.name}</h3>
              <div className="d-flex flex-row property-text">
                <div>
                  <span>ID: </span>
                  {d.id}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PropertyHead;
