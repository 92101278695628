import React, { useState } from 'react';
import { useQuery, useMutation } from '@apollo/client';
import { Spinner, UncontrolledAlert, Button } from 'reactstrap';
import ErrorLine from '../../containers/errorLine';
import * as request from './messagingRequests';
import { Can } from '../../../auth/abilityContext';
import DeleteButton from '../../deleteButton';
import {
  schema as schemaSource,
  uiSchema as uiSchemaSource,
} from '../../../schemas/messaging';
import FormWrapper from '../../../form/formWrapper';
import setOptionsLists from './setOptionsLists';

const UpdateMessagingTemplate = ({ row }) => {
  const subjectName = 'MessagingTemplate';
  const messagingTemplateId = +row.original.id;
  const propertyId = +row.original.propertyId;

  const [complete, setComplete] = useState(false);
  const [formKey, setFormKey] = useState(1);

  const {
    loading: dataLoading,
    error: dataError,
    data,
  } = useQuery(request.GET_MESSAGING_TEMPLATE, {
    variables: { messagingTemplateId },
  });

  const [
    updateMessagingTemplate,
    { loading: updateLoading, error: updateError },
  ] = useMutation(request.UPDATE_MESSAGING_TEMPLATE, {
    onCompleted: () => {
      setComplete(true);
      setFormKey(formKey + 1);
    },
  });

  const onSubmit = data => {
    const variables = { id: messagingTemplateId, ...data };
    updateMessagingTemplate({ variables });
  };

  const [
    deleteMessagingTemplate,
    { loading: deleteLoading, error: deleteError },
  ] = useMutation(request.DELETE_MESSAGING_TEMPLATE, {
    refetchQueries: [
      {
        query: request.GET_MESSAGING_TEMPLATES_LIST,
        variables: { propertyId },
      },
    ],
    variables: {
      id: messagingTemplateId,
    },
  });

  const loading = dataLoading || updateLoading || deleteLoading;

  const success =
    !!complete && !loading && !dataError && !updateError && !deleteError;

  return (
    <>
      {dataLoading && <Spinner color="secondary" />}
      {dataError && <ErrorLine error={dataError} />}
      {data && (
        <>
          <FormWrapper
            formData={data.messagingTemplate}
            optionsLists={setOptionsLists(data)}
            subjectName={subjectName}
            loading={loading}
            schemaSource={schemaSource}
            uiSchemaSource={uiSchemaSource}
            onSubmit={onSubmit}
            formKey={formKey}
          >
            <div>
              {updateLoading && <Spinner color="secondary" />}
              {updateError && <ErrorLine error={updateError} />}
              {success && (
                <UncontrolledAlert>Data has been saved</UncontrolledAlert>
              )}
            </div>
            <DeleteButton
              subjectName={subjectName}
              deleteFunction={deleteMessagingTemplate}
              deletingStatus={
                <div>
                  {deleteLoading && <Spinner color="secondary" />}
                  {deleteError && <ErrorLine error={deleteError} />}
                </div>
              }
            />
            <Can I="update" a={subjectName}>
              <Button type="submit" color="success" className="ml-3">
                Save data
              </Button>
            </Can>
          </FormWrapper>
        </>
      )}
    </>
  );
};

export default UpdateMessagingTemplate;
