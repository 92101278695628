import gqlTag from 'graphql-tag';

export const GET_DEVICES = gqlTag`
  query paymentSystemDevicesList($propertyId: Int!) {
    devicesList(condition: { propertyId: $propertyId }) {
      id
      name
      paymentSystemDevicesList {
        credentials
        id
        deviceId
        attributes
        paymentSystemId
        paymentSystemDevicesCurrenciesList {
          id
          currencyId
          currency {
            id
            alphabeticCode
          }
        }
      }
    }
    paymentSystemsList {
      id
      name
      attributesTemplate
      credentialsTemplate
    }
    currenciesList {
      id
      alphabeticCode
    }
  }
`;

export const UPDATE_DEVICE = gqlTag`
  mutation updatePaymentSystemDevice(
    $id: Int!
    $attributes: JSON
    $credentials: JSON
    $deviceId: Int
    $paymentSystemId: Int
  ) {
    updatePaymentSystemDevice(
      input: {
        patch: {
          attributes: $attributes
          credentials: $credentials
          deviceId: $deviceId
          paymentSystemId: $paymentSystemId
        }
        id: $id
      }
    ) {
      paymentSystemDevice {
        id
        attributes
        credentials
        deviceId
        paymentSystemId
      }
    }
  }
`;

export const CREATE_DEVICE = gqlTag`
  mutation createPaymentSystemDevice(
    $attributes: JSON
    $credentials: JSON
    $deviceId: Int!
    $paymentSystemId: Int!
  ) {
    createPaymentSystemDevice(
      input: {
        paymentSystemDevice: {
          paymentSystemId: $paymentSystemId
          deviceId: $deviceId
          attributes: $attributes
          credentials: $credentials
        }
      }
    ) {
      paymentSystemDevice {
        id
        attributes
        credentials
        deviceId
        paymentSystemId
      }
    }
  }
`;

export const DELETE_DEVICE = gqlTag`
  mutation deletePaymentSystemDevice($id: Int!) {
    deletePaymentSystemDevice(input: { id: $id }) {
      paymentSystemDevice {
        id
      }
    }
  }
`;

export const ASSIGN_CURRENCY = gqlTag`
  mutation AddCurrency($paymentSystemDeviceId: Int!, $currencyId: Int!) {
    createPaymentSystemDevicesCurrency(
      input: {
        paymentSystemDevicesCurrency: {
          paymentSystemDeviceId: $paymentSystemDeviceId
          currencyId: $currencyId
        }
      }
    ) {
      paymentSystemDevicesCurrency {
        id
        currencyId
        paymentSystemDeviceId
      }
    }
  }
`;

export const DELETE_CURRENCY = gqlTag`
  mutation DeleteCurrency($id: Int!) {
    deletePaymentSystemDevicesCurrency(input: { id: $id }) {
      paymentSystemDevicesCurrency {
        id
      }
    }
  }
`;
