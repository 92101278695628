import gqlTag from 'graphql-tag';

export const GET_GUEST_FIELDS_LIST = gqlTag`
  query propertyGuestFieldsList($propertyId: Int!) {
    propertyGuestFieldsList(filter: { propertyId: { equalTo: $propertyId } }) {
      id
      enabled
      guestColumn
      name
      status
    }
  }
`;

export const UPDATE_GUEST_FIELD_ENABLED = gqlTag`
  mutation updatePropertyGuestField($id: Int!, $enabled: Boolean!) {
    updatePropertyGuestField(input: { patch: { enabled: $enabled }, id: $id }) {
      propertyGuestField {
        id
        enabled
      }
    }
  }
`;

export const UPDATE_GUEST_FIELD_STATUS = gqlTag`
  mutation updatePropertyGuestField($id: Int!, $status: PropertyStatus!) {
    updatePropertyGuestField(input: { patch: { status: $status }, id: $id }) {
      propertyGuestField {
        id
        status
      }
    }
  }
`;
