import React from 'react';
import { useQuery } from '@apollo/client';
import { Spinner } from 'reactstrap';
import ErrorLine from '../../containers/errorLine';
import SubmitArea from './submitArea';
import * as request from './areasRequests';

const Area = ({ row }) => {
  const areaId = +row.original.id;

  const {
    loading: dataLoading,
    error: dataError,
    data,
  } = useQuery(request.GET_AREA, {
    variables: { areaId },
  });

  return (
    <>
      {dataLoading && <Spinner color="secondary" />}
      {dataError && <ErrorLine error={dataError} />}
      {data && <SubmitArea data={data} />}
    </>
  );
};

export default Area;
