import { set, cloneDeep } from 'lodash';
import hasSchema from '../../../lib/hasSchema';

const insertConfigSchemas = (schema, uiSchema, paymentSystems) => {
  const newSchema = cloneDeep(schema);
  const newUiSchema = cloneDeep(uiSchema);

  const oneOf = [];

  paymentSystems.forEach(ps => {
    const id = +ps.id;
    const {
      credentialsTemplate: credTemplate = {},
      attributesTemplate: attrTemplate = {},
    } = ps;
    let credSchema, credUiSchema, attrSchema, attrUiSchema;

    let hasCredSchema = hasSchema(credTemplate);
    let hasCredUiSchema = hasSchema(credTemplate, 'uiSchema');
    let hasAttrSchema = hasSchema(attrTemplate);
    let hasAttrUiSchema = hasSchema(attrTemplate, 'uiSchema');

    if (hasCredSchema) {
      credSchema = credTemplate.schema;
    } else {
      credSchema = {
        type: 'string',
        title: 'Credentials',
        default: '{}',
        description: 'JSON object',
      };
    }

    if (hasCredUiSchema) {
      credUiSchema = credTemplate.uiSchema;
    } else {
      credUiSchema = {
        'ui:widget': 'textarea',
        'ui:options': {
          rows: 5,
        },
      };
    }

    if (hasAttrSchema) {
      attrSchema = attrTemplate.schema;
    } else {
      attrSchema = {
        type: 'string',
        title: 'Attributes',
        default: '{}',
        description: 'JSON object',
      };
    }

    if (hasAttrUiSchema) {
      attrUiSchema = attrTemplate.uiSchema;
    } else {
      attrUiSchema = {
        'ui:widget': 'textarea',
      };
    }

    oneOf.push({
      properties: {
        paymentSystemId: {
          enum: [id],
        },
        [`credentials${id}`]: { title: 'Credentials', ...credSchema },
        [`attributes${id}`]: { title: 'Attributes', ...attrSchema },
      },
    });

    set(newUiSchema, `credentials${id}`, credUiSchema);
    set(newUiSchema, `attributes${id}`, attrUiSchema);
  });
  set(newSchema, 'dependencies.paymentSystemId.oneOf', oneOf);
  return { schema: newSchema, uiSchema: newUiSchema };
};

export default insertConfigSchemas;
