import gqlTag from 'graphql-tag';

export const GET_BOOKING_SETS = gqlTag`
  query BookingsList(
    $propertyId: Int!
    $bookingSetFilter: BookingSetFilter
  ) {
    bookingSetsList(
      condition: { propertyId: $propertyId }
      filter: $bookingSetFilter
    ) {
      id
      startDate
      endDate
      reference
      bookingsList {
        id
        currencyId
        area {
          id
          name
        }
      }
      preauthsList {
        id
        amount
        status
        currencyId
      }
      billsList {
        id
        chargesList {
          id
          grossAmount
        }
        paymentsList {
          id
          deviceId
          amount
          sentToPms
          status
          currencyId
        }
      }
    }
    currenciesList {
      id
      alphabeticCode
    }
  }
`;

export const GET_BOOKING_SET = gqlTag`
  query BookingSet(
    $bookingSetId: Int!
  ) {
    bookingSet(id:$bookingSetId) {
      id
      startDate
      endDate
      reference
      billsList {
        id
        paymentsList(filter: { deviceId: { isNull: false } }) {
          id
          deviceId
          paymentNetwork {
            id
            displayName
          }
          pmsPaymentId
          posMessage
          sentToPms
          status
          amount
          currencyId
        }
      }
      bookingsList {
        id
        pmsBookingId
        startDate
        endDate
        status
        area {
          id
          name
        }
        guestsList {
          id
          lastName
          firstName
          status
        }
      }
      preauthsList {
        id
        amount
        status
        currencyId
      }
    }
  }
`;
