export const schema = {
  type: 'object',
  required: ['accessSystemConfigId', 'attributes'],
  properties: {
    id: {
      type: 'number',
      title: 'id',
    },
    name: {
      type: 'string',
      title: 'Name',
    },
    description: {
      type: 'string',
      title: 'Description',
    },
    areaType: {
      type: 'string',
      title: 'Type',
    },
    status: {
      type: 'string',
      title: 'Status',
      enum: [],
      enumNames: [],
      default: 'CLEAN',
    },
    accessSystemConfigId: {
      type: 'number',
      title: 'Access system',
      enum: [],
      enumNames: [],
    },
    attributes: {
      type: 'object',
      title: 'Attributes',
      properties: {
        roomId: {
          type: 'string',
          title: 'Room ID',
        },
        customAttributes: {
          type: 'string',
          title: 'Custom attributes',
          default: '{}',
          description: 'JSON object',
          maxLength: 1024,
        },
      },
    },
  },
};

export const uiSchema = {
  id: { 'ui:disabled': true },
  name: { 'ui:disabled': true },
  description: { 'ui:disabled': true },
  areaType: { 'ui:disabled': true },
  status: { 'ui:disabled': true },
  attributes: {
    customAttributes: {
      'ui:widget': 'textarea',
    },
  },
};
