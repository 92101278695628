import React, { useState } from 'react';
import { useQuery } from '@apollo/client';
import { ListGroup, ListGroupItem, Spinner } from 'reactstrap';
import ErrorLine from '../../containers/errorLine';
import SectionHead from '../sectionsHead';
import * as request from './requests';
import PropertiesPaymentNetwork from './propertiesPaymentNetwork';
import AddPropertiesPaymentNetwork from './addPropertiesPaymentNetwork';
import PrimarySelect from './primarySelect';

const PaymentNetworks = ({ propertyData, title, links }) => {
  const propertyId = +propertyData.property.id;

  const [error, setError] = useState(null);

  const { loading, data, refetch } = useQuery(
    request.GET_PROPERTIES_PAYMENT_NETWORKS,
    {
      variables: { propertyId },
      onError: error => setError(error),
      fetchPolicy: 'no-cache',
    },
  );

  return (
    <>
      <SectionHead propertyData={propertyData} title={title} links={links} />
      <div className="col-lg-12 col-xl-6 p-2">
        {loading && <Spinner color="secondary" />}
        {error && <ErrorLine error={error} />}
        {data && (
          <>
            {data.propertiesPaymentNetworksList.length > 0 && (
              <PrimarySelect
                data={data}
                setError={setError}
                propertyId={propertyId}
                fetchData={refetch}
              />
            )}
            <AddPropertiesPaymentNetwork
              data={data}
              setError={setError}
              propertyId={propertyId}
              fetchData={refetch}
            />
            <div>
              <ListGroup className="mb-4">
                {data.propertiesPaymentNetworksList.map(pn => {
                  return (
                    <ListGroupItem
                      key={`payment-network-${pn.id}`}
                      className="pr-2 py-2"
                    >
                      <PropertiesPaymentNetwork
                        data={data}
                        propertiesPaymentNetwork={pn}
                        propertyId={propertyId}
                        setError={setError}
                        fetchData={refetch}
                      />
                    </ListGroupItem>
                  );
                })}
              </ListGroup>
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default PaymentNetworks;
