import React, { useContext } from 'react';
import { useQuery } from '@apollo/client';
import { Spinner, Badge } from 'reactstrap';
import Table from '../../containers/table/table';
import ErrorLine from '../../containers/errorLine';
import SectionHead from '../sectionsHead';
import deviceStatuses from './deviceStatuses';
import * as requests from './requests';
import TestPaymentButton from './testPayment';
import TestKeyButton from './testKey';
import ChangeDesign from './changeDesign';
import AccessSystemDevice from './accessSystemDevice';
import { AbilityContext } from '../../../auth/abilityContext';

const Devices = ({ propertyData, title, links }) => {
  const propertyId = +propertyData.property.id;
  const subjectName = 'Devices';
  const ability = useContext(AbilityContext);

  const getDeviceStatus = row =>
    deviceStatuses.filter(status => status.id === row.status)[0] || {};

  const getDataForTests = row => ({
    deviceId: row.id,
    propertiesPaymentNetworksList: row.property.propertiesPaymentNetworksList,
    paymentSystemDevicesList: row.paymentSystemDevicesList,
    areasList: row.property.areasList,
  });

  const getDataForChangeDesign = row => ({
    deviceId: row.id,
    propertyId: row.property.id,
  });

  const getDataForAccessSystemDevice = row => ({
    deviceId: row.id,
    propertyId: row.property.id,
  });

  const { loading, error, data } = useQuery(requests.GET_DEVICES_LIST, {
    variables: { propertyId },
  });

  const columns = React.useMemo(() => {
    const accessSystemDeviceColumn = {
      id: 'accessSystemDevice',
      Header: 'Access System Device',
      accessor: getDataForAccessSystemDevice,
      Cell: ({ cell }) => <AccessSystemDevice data={cell} />,
      width: 100,
      flexGrow: 0.1,
    };
    const testsColumn = {
      id: 'tests',
      Header: 'Test a device systems',
      accessor: getDataForTests,
      Cell: ({ cell }) => (
        <div>
          <TestPaymentButton data={cell} /> <TestKeyButton data={cell} />
        </div>
      ),
      width: 150,
      flexGrow: 0.1,
    };
    const changeDesignColumn = {
      id: 'changeDesign',
      Header: 'Change design',
      accessor: getDataForChangeDesign,
      Cell: ({ cell }) => <ChangeDesign data={cell} />,
      width: 100,
      flexGrow: 0.1,
    };
    return [
      {
        Header: 'id',
        accessor: 'id',
        width: 80,
        flexGrow: 0,
      },
      {
        Header: 'Device',
        accessor: 'name',
        width: 200,
        flexGrow: 0.5,
      },
      {
        Header: 'Type',
        accessor: 'type.name',
        width: 80,
        flexGrow: 0.1,
      },
      {
        Header: 'Screen orientation',
        accessor: 'screenOrientation',
        width: 150,
        flexGrow: 0.1,
      },
      {
        Header: 'Screen resolution',
        accessor: 'screenResolution',
        width: 150,
        flexGrow: 0.1,
      },
      {
        id: 'status',
        Header: 'Status',
        accessor: row => getDeviceStatus(row).name || row.status,
        Cell: ({ cell }) => (
          <Badge color={getDeviceStatus(cell.row.original).color}>
            {cell.value}
          </Badge>
        ),
        width: 100,
        flexGrow: 0.1,
      },
      ...(ability.can('view', 'accessSystemDevice')
        ? [{ ...accessSystemDeviceColumn }]
        : []),
      ...(ability.can('view', subjectName, 'tests')
        ? [{ ...testsColumn }]
        : []),
      ...(ability.can('view', 'Designs') ? [{ ...changeDesignColumn }] : []),
    ];
  }, [ability]);

  return (
    <>
      <SectionHead propertyData={propertyData} title={title} links={links} />
      {loading && <Spinner color="secondary" />}
      {error && <ErrorLine error={error} />}
      {data && (
        <Table
          className="py-4"
          columns={columns}
          data={data.devicesList}
          options={{ disableFilters: true }}
        />
      )}
    </>
  );
};

export default Devices;
