import React, { useState } from 'react';
import { useMutation } from '@apollo/client';
import {
  Popover,
  PopoverHeader,
  PopoverBody,
  Button,
  ButtonGroup,
  Spinner,
} from 'reactstrap';
import get from 'lodash/get';
import { faCheck, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import FormWrapper from '../../../form/formWrapper';
import submit from '../../../form/syntheticSubmit';

const EditableCell = ({
  cell,
  parentId = 'id',
  query,
  template = {},
  variableName,
}) => {
  const [popoverOpen, setPopoverOpen] = useState(false);
  const { schema, uiSchema } = template;
  const originalValue = get(cell, `row.original.${variableName}`);
  const { value } = cell;
  const cellId = `cell-${cell.getCellProps().key}`;
  const toggle = () => setPopoverOpen(!popoverOpen);

  const [form, setForm] = useState(null);

  const [update, { loading, error }] = useMutation(query, {
    onCompleted: () => {
      setPopoverOpen(false);
    },
  });

  const onSubmit = data => {
    update({
      variables: {
        id: get(cell.row.original, parentId),
        [variableName]: data[variableName],
      },
    });
  };

  return (
    <span onClick={e => e.stopPropagation()}>
      <span id={cellId}>{value}</span>
      <Popover
        placement="bottom"
        isOpen={popoverOpen}
        target={cellId}
        toggle={toggle}
        trigger="legacy"
      >
        <PopoverHeader>{cell.column.Header}</PopoverHeader>
        <PopoverBody>
          {error && <p className="text-danger mt-2">{error.message}</p>}
          <div className="inline-form">
            <FormWrapper
              formData={{ [variableName]: originalValue }}
              subjectName={variableName}
              loading={loading}
              schemaSource={schema}
              uiSchemaSource={uiSchema}
              onSubmit={onSubmit}
              setForm={setForm}
              formKey={cellId}
              children={' '}
            />
            {loading && <Spinner size="sm" />}
            {!loading && (
              <ButtonGroup>
                <Button color="success" onClick={() => submit(form)}>
                  <FontAwesomeIcon icon={faCheck} className="button-icon" />
                </Button>
                <Button onClick={() => setPopoverOpen(false)}>
                  <FontAwesomeIcon icon={faTimes} className="button-icon" />
                </Button>
              </ButtonGroup>
            )}
          </div>
        </PopoverBody>
      </Popover>
    </span>
  );
};

export default EditableCell;
