import React, { useRef, useCallback, useState, useEffect } from 'react';
import { format, endOfDay, startOfDay } from 'date-fns';
import formatDate from '../../../lib/formatDate';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faTimes,
  faSignInAlt,
  faSignOutAlt,
  faTicketAlt,
} from '@fortawesome/free-solid-svg-icons';
import Flatpickr from 'react-flatpickr';
import 'flatpickr/dist/themes/light.css';
import {
  Input,
  Button,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
} from 'reactstrap';
import { set } from 'lodash';
import '../../../styles/getFilteredList.css';
import preauthStatuses from './preauthStatuses';
import TagSelect from '../../inputs/tagSelect';

const DateFilter = ({ date, setDate, name, ico }) => {
  const flatpickrRef = useRef(null);

  useEffect(() => {
    if (flatpickrRef.current) {
      flatpickrRef.current.flatpickr.setDate(date);
    }
  });

  const clearDate = useCallback(() => {
    if (flatpickrRef.current) {
      flatpickrRef.current.flatpickr.clear();
      setDate('');
    }
  }, [setDate, flatpickrRef]);

  return (
    <Flatpickr
      onChange={([date]) => {
        if (date) {
          setDate(format(date, "yyyy-MM-dd'T'HH:mm:ss"));
        }
      }}
      placeholder={name}
      ref={flatpickrRef}
      options={{
        formatDate,
        locale: {
          firstDayOfWeek: 1,
        },
      }}
      render={({ defaultValue, value, ...props }, ref) => {
        return (
          <InputGroup
            className="mr-2 mt-2 flex-sm-grow-1"
            style={{ width: '220px' }}
          >
            <InputGroupAddon addonType="prepend">
              <InputGroupText>
                <FontAwesomeIcon icon={ico} className="button-icon" />
              </InputGroupText>
            </InputGroupAddon>
            <Input
              defaultValue={defaultValue}
              innerRef={ref}
              style={{ backgroundColor: '#fff' }}
              {...props}
            />
            {date && (
              <InputGroupAddon addonType="append">
                <Button onClick={clearDate} outline>
                  <FontAwesomeIcon icon={faTimes} className="button-icon" />
                </Button>
              </InputGroupAddon>
            )}
          </InputGroup>
        );
      }}
    />
  );
};

const PreauthsFilter = ({ propertyId, getBookingsList, loading, called }) => {
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState(
    format(startOfDay(new Date()), "yyyy-MM-dd'T'HH:mm:ss"),
  );

  const [reference, setReference] = useState('');
  const [statuses, setStatuses] = useState([]);

  const getFilteredBookingsList = () => {
    const filter = {};
    if (reference) {
      set(filter, 'reference.contains', reference);
    }
    if (startDate) {
      set(filter, 'startDate.greaterThan', startDate);
      set(
        filter,
        'startDate.lessThan',
        format(endOfDay(new Date(startDate)), "yyyy-MM-dd'T'HH:mm:ss"),
      );
    }
    if (endDate) {
      set(filter, 'endDate.greaterThan', endDate);
      set(
        filter,
        'endDate.lessThan',
        format(endOfDay(new Date(endDate)), "yyyy-MM-dd'T'HH:mm:ss"),
      );
    }

    const preauthFilter = { status: { in: statuses } };

    getBookingsList({
      variables: {
        propertyId,
        filter,
        ...(statuses.length > 0 ? { preauthFilter } : {}),
      },
    });
  };

  useEffect(() => {
    if (!called) {
      getFilteredBookingsList();
    }
  });

  return (
    <div className="mb-4 mt-3 d-flex flex-wrap align-items-start get-filtered-list-box-preauth">
      <InputGroup
        className="mr-2 mt-2 flex-sm-grow-1"
        style={{ width: '220px' }}
      >
        <InputGroupAddon addonType="prepend">
          <InputGroupText>
            <FontAwesomeIcon icon={faTicketAlt} className="button-icon" />
          </InputGroupText>
        </InputGroupAddon>
        <Input
          placeholder="Type a reference"
          value={reference}
          onChange={e => setReference(e.target.value)}
          style={{ backgroundColor: '#fff' }}
        />
        {reference && (
          <InputGroupAddon addonType="append">
            <Button onClick={() => setReference('')} outline>
              <FontAwesomeIcon icon={faTimes} className="button-icon" />
            </Button>
          </InputGroupAddon>
        )}
      </InputGroup>
      <DateFilter
        date={startDate}
        name="Select a check-in date"
        ico={faSignInAlt}
        setDate={setStartDate}
      />
      <DateFilter
        date={endDate}
        name="Select a check-out date"
        ico={faSignOutAlt}
        setDate={setEndDate}
      />
      <TagSelect
        options={preauthStatuses}
        value={statuses}
        onChange={value => setStatuses(value)}
        mainCheckboxLabel="Any status"
        className="mr-2 mt-2 flex-sm-grow-1"
        style={{
          width: '220px',
        }}
      />
      <div className="mt-2 flex-sm-grow-0" style={{ width: '220px' }}>
        {(reference || startDate || endDate) && (
          <Button
            onClick={() => {
              getFilteredBookingsList(reference, startDate, endDate);
            }}
            color="success"
            disabled={loading}
            block
          >
            Load pre-authorizations
          </Button>
        )}
      </div>
    </div>
  );
};

export default PreauthsFilter;
