import gqlTag from 'graphql-tag';

export const GET_PROPERTY = gqlTag`
  query property($propertyId: Int!) {
    property(id: $propertyId) {
      id
      name
      status
      propertySettings {
        id
        payment
        guestRegistration
        documentScan
        accessSystem
        logo
        preauth
        timezone
      }
    }
  }
`;
