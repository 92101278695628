import React, { useRef, useCallback, useState, useEffect } from 'react';
import { format, endOfDay, startOfDay } from 'date-fns';
import formatDate from '../../../lib/formatDate';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faTimes,
  faCalendarAlt,
  faTicketAlt,
} from '@fortawesome/free-solid-svg-icons';
import Flatpickr from 'react-flatpickr';
import 'flatpickr/dist/themes/light.css';
import {
  Input,
  Button,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  CustomInput,
} from 'reactstrap';
import { set } from 'lodash';
import '../../../styles/getFilteredList.css';

const DateFilter = ({ dateRange, setDateRange, error, setError }) => {
  const flatpickrRef = useRef(null);

  useEffect(() => {
    if (dateRange.length > 1) {
      setError(false);
    }
  }, [dateRange.length, setError]);

  useEffect(() => {
    if (flatpickrRef.current) {
      flatpickrRef.current.flatpickr.setDate(dateRange);
    }
  });

  const clearDate = useCallback(() => {
    if (flatpickrRef.current) {
      flatpickrRef.current.flatpickr.clear();
      setError(false);
      setDateRange([]);
    }
  }, [setDateRange, setError]);

  const onChangeDate = (dates = []) => {
    const startDate =
      !!dates[0] && format(startOfDay(dates[0]), "yyyy-MM-dd'T'HH:mm:ss");
    const endDate =
      !!dates[1] && format(endOfDay(dates[1]), "yyyy-MM-dd'T'HH:mm:ss");

    if (endDate) {
      setDateRange([startDate, endDate]);
    } else {
      setDateRange([startDate]);
    }
  };

  return (
    <Flatpickr
      onChange={onChangeDate}
      placeholder="Select a date range to load data"
      ref={flatpickrRef}
      options={{
        formatDate,
        locale: {
          firstDayOfWeek: 1,
        },
        mode: 'range',
      }}
      render={({ defaultValue, value, ...props }, ref) => {
        return (
          <InputGroup
            className="mr-2 mt-2 flex-sm-grow-1"
            style={{ width: '240px' }}
          >
            <InputGroupAddon addonType="prepend">
              <InputGroupText>
                <FontAwesomeIcon icon={faCalendarAlt} className="button-icon" />
              </InputGroupText>
            </InputGroupAddon>
            <Input
              defaultValue={defaultValue}
              innerRef={ref}
              style={{ backgroundColor: '#fff' }}
              invalid={error}
              {...props}
            />
            {dateRange.length > 0 && (
              <InputGroupAddon addonType="append">
                <Button onClick={clearDate} outline>
                  <FontAwesomeIcon icon={faTimes} className="button-icon" />
                </Button>
              </InputGroupAddon>
            )}
          </InputGroup>
        );
      }}
    />
  );
};

const PaymentFilter = ({
  propertyId,
  getBookingsList,
  loading,
  called,
  filter,
  setFilter,
}) => {
  const [dateRange, setDateRange] = useState([
    format(startOfDay(new Date()), "yyyy-MM-dd'T'HH:mm:ss"),
    format(endOfDay(new Date()), "yyyy-MM-dd'T'HH:mm:ss"),
  ]);
  const [reference, setReference] = useState('');
  const [error, setError] = useState(null);

  const { unsentToPmsFirst } = filter || {};

  const getFilteredBookingsList = () => {
    const bookingSetFilter = {};
    const paymentFilter = {};
    if (reference) {
      set(bookingSetFilter, 'reference.contains', reference);
    }
    if (dateRange.length > 1) {
      set(paymentFilter, 'paidAt.lessThanOrEqualTo', dateRange[1]);
      set(paymentFilter, 'paidAt.greaterThanOrEqualTo', dateRange[0]);
    }

    getBookingsList({
      variables: {
        propertyId,
        paymentFilter: { deviceId: { isNull: false }, ...paymentFilter },
        ...(reference ? { bookingSetFilter } : {}),
      },
    });
  };

  useEffect(() => {
    if (!called) {
      getFilteredBookingsList();
    }
  });

  return (
    <div className="mb-4 mt-3 d-flex flex-wrap align-items-start get-filtered-list-box-payments">
      <InputGroup
        className="mr-2 mt-2 flex-sm-grow-1"
        style={{ width: '240px' }}
      >
        <InputGroupAddon addonType="prepend">
          <InputGroupText>
            <FontAwesomeIcon icon={faTicketAlt} className="button-icon" />
          </InputGroupText>
        </InputGroupAddon>
        <Input
          placeholder="Type a reference"
          value={reference}
          onChange={e => setReference(e.target.value)}
          style={{ backgroundColor: '#fff' }}
        />
        {reference && (
          <InputGroupAddon addonType="append">
            <Button onClick={() => setReference('')} outline>
              <FontAwesomeIcon icon={faTimes} className="button-icon" />
            </Button>
          </InputGroupAddon>
        )}
      </InputGroup>
      <DateFilter
        dateRange={dateRange}
        setDateRange={setDateRange}
        error={error}
        setError={setError}
      />
      <div className="mr-2 mt-2 p-2 flex-sm-grow-0" style={{ width: '280px' }}>
        <CustomInput
          type="checkbox"
          id="unsentToPmsFirst"
          label="Unsent to the PMS payments first"
          checked={unsentToPmsFirst}
          onChange={e =>
            setFilter(f => ({ ...f, unsentToPmsFirst: e.target.checked }))
          }
        />
      </div>
      <div className="mt-2 flex-sm-grow-0" style={{ width: '150px' }}>
        <Button
          onClick={() => {
            if (reference || dateRange.length > 1) {
              getFilteredBookingsList();
              setError(false);
            } else if (dateRange.length === 1) {
              setError(true);
            }
          }}
          color="success"
          disabled={loading}
          block
        >
          Load payments
        </Button>
      </div>
    </div>
  );
};

export default PaymentFilter;
