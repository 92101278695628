import { Widget } from '../form/formTemplate';

export const schema = {
  type: 'object',
  title: 'Voucher template',
  properties: {
    template: {
      type: 'string',
    },
  },
};

export const uiSchema = {
  'ui:ObjectFieldTemplate': Widget,
  'template': {
    'ui:widget': 'VoucherEditor',
    'ui:options': {
      label: false,
    },
  },
};
