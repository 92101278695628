import React, { useState } from 'react';
import { useLazyQuery } from '@apollo/client';
import { Badge, Spinner } from 'reactstrap';
import Table from '../../containers/table/table';
import ErrorLine from '../../containers/errorLine';
import SectionHead from '../sectionsHead';
import * as request from './requests';
import getZonedTime from '../../../lib/getZonedTime';
import DateFilter from '../../containers/table/dateFilter';
import PaymentFilter from './paymentFilter';
import Payment from './payment';
import paymentStatuses from './paymentStatuses';
import { faCheck, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const toDate = dateStr => new Date(dateStr);
const getAmountWithCurrency = row =>
  `${+row.amount} ${row.currency.alphabeticCode}`;
const getPaymentStatus = row =>
  paymentStatuses.filter(status => status.id === row.status)[0] || {};

const getPaymentsList = ({ bookingSetsList }, timezone) => {
  const payments = [];
  bookingSetsList.forEach(bs => {
    const { reference, startDate, endDate, bookingsList, billsList } = bs;
    const [booking] = bookingsList || [];
    const { guestsList } = booking || {};
    const [guest] = guestsList || [];
    const { firstName, lastName } = guest || {};
    billsList.forEach(({ paymentsList }) => {
      paymentsList.forEach(p => {
        payments.push({
          timezone,
          reference,
          firstName,
          lastName,
          startDate,
          endDate,
          ...p,
        });
      });
    });
  });
  return payments;
};

const Payments = ({ propertyData, links, title }) => {
  const propertyId = +propertyData.property.id;
  const { propertySettings: { timezone } = {} } = propertyData.property || {};

  const [paymentsList, setPaymentsList] = useState(null);
  const [filter, setFilter] = useState({ unsentToPmsFirst: true });

  const [getBookingsList, { loading, error, called }] = useLazyQuery(
    request.GET_PAYMENTS,
    {
      variables: { propertyId },
      fetchPolicy: 'network-only',
      onCompleted: data => {
        setPaymentsList(getPaymentsList(data, timezone));
      },
    },
  );

  const columns = React.useMemo(
    () => [
      {
        id: 'reference',
        Header: 'Reference',
        accessor: 'reference',
        Cell: ({ cell: { value } }) =>
          (value || []).map(r => (
            <span key={`ref-${r}`}>
              {r}
              <br />
            </span>
          )),
        width: 120,
        flexGrow: 0.1,
      },
      {
        id: 'startDate',
        Header: 'Check-in',
        accessor: row => toDate(row.startDate),
        sortType: 'datetime',
        Cell: ({ cell: { value } }) => getZonedTime(value, timezone),
        Filter: DateFilter,
        filter: 'dateFilter',
        width: 120,
        flexGrow: 0.1,
      },
      {
        id: 'endDate',
        Header: 'Check-out',
        accessor: row => toDate(row.endDate),
        sortType: 'datetime',
        Cell: ({ cell: { value } }) => getZonedTime(value, timezone),
        Filter: DateFilter,
        filter: 'dateFilter',
        width: 120,
        flexGrow: 0.1,
      },
      {
        id: 'guestName',
        Header: 'Guest name',
        accessor: ({ firstName, lastName }) =>
          firstName && firstName[0]
            ? `${firstName[0].toUpperCase()}. ${lastName}`
            : lastName,
        width: 120,
        flexGrow: 0.2,
      },
      {
        id: 'paidAt',
        Header: 'Paid',
        accessor: row => toDate(row.paidAt),
        sortType: 'datetime',
        Cell: ({ cell: { value } }) => getZonedTime(value, timezone),
        Filter: DateFilter,
        filter: 'dateFilter',
        width: 120,
        flexGrow: 0.2,
      },
      {
        id: 'pmsPaymentId',
        Header: 'PMS payment ID',
        accessor: 'pmsPaymentId',
        width: 120,
        flexGrow: 0.1,
      },
      {
        id: 'sentToPms',
        Header: 'Sent to PMS',
        accessor: row => (row.sentToPms ? 'Sent' : 'Unsent'),
        Cell: ({ cell }) => {
          let color = 'secondary';
          const sentToPms = cell.row.original.sentToPms;
          const status = cell.row.original.status;
          if (sentToPms) {
            if (status === 'SUCCESS') color = 'success';
            if (status !== 'SUCCESS') color = 'danger';
          }
          if (!sentToPms) {
            if (status === 'SUCCESS') color = 'danger';
            if (status !== 'SUCCESS') return false;
          }
          return (
            <Badge color={color}>
              <FontAwesomeIcon
                icon={sentToPms ? faCheck : faTimes}
                className="mr-1"
              />
              {cell.value}
            </Badge>
          );
        },
        width: 80,
        flexGrow: 0.1,
        textAlign: 'center',
      },
      {
        id: 'amount',
        Header: 'Amount',
        accessor: getAmountWithCurrency,
        width: 80,
        flexGrow: 0.1,
        textAlign: 'right',
      },
      {
        id: 'status',
        Header: 'Status',
        accessor: row => getPaymentStatus(row).name || row.status,
        Cell: ({ cell }) => (
          <Badge
            color={getPaymentStatus(cell.row.original).color || 'secondary'}
          >
            {cell.value}
          </Badge>
        ),
        width: 80,
        flexGrow: 0.1,
        textAlign: 'center',
      },
      {
        id: 'paymentNetwork',
        Header: 'Payment network',
        accessor: row => row.paymentNetwork?.displayName,
        width: 80,
        flexGrow: 0.1,
      },
    ],
    [timezone],
  );

  const sortByUnsentToPmsFirst = [
    { id: 'sentToPms', desc: true },
    { id: 'status', desc: true },
  ];

  const initialState = {
    sortBy: [...(filter.unsentToPmsFirst ? sortByUnsentToPmsFirst : [])],
  };

  return (
    <>
      <SectionHead propertyData={propertyData} title={title} links={links} />
      <PaymentFilter
        propertyId={propertyId}
        getBookingsList={getBookingsList}
        filter={filter}
        setFilter={setFilter}
        loading={loading}
        called={called}
      />
      {loading && <Spinner color="secondary" />}
      {error && <ErrorLine error={error} />}
      {!loading && paymentsList && (
        <Table
          className="py-4"
          columns={columns}
          data={paymentsList}
          ExpandCard={Payment}
          initialState={initialState}
          options={{
            initialRowStateAccessor: row => ({
              ...row.original.initialState,
              active:
                !row.original.sentToPms && row.original.status === 'SUCCESS',
            }),
          }}
        />
      )}
    </>
  );
};

export default Payments;
