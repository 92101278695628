import gqlTag from 'graphql-tag';

export const GET_MESSAGING_TEMPLATES_LIST = gqlTag`
  query MessagingTemplatesList($propertyId: Int!) {
    messagingTemplatesList(condition: { propertyId: $propertyId }) {
      id
      propertyId
      messagingSystem
      templateType
      language
      templateSubject
      templateData
    }
    languagesList {
      id
      name
      code
    }
  }
`;

export const GET_MESSAGING_TEMPLATE = gqlTag`
  query MessagingTemplate($messagingTemplateId: Int!) {
    messagingTemplate(id: $messagingTemplateId) {
      id
      propertyId
      messagingSystem
      templateType
      language
      templateSubject
      templateData
    }
    languagesList {
      id
      name
      code
    }
  }
`;

export const UPDATE_MESSAGING_TEMPLATE = gqlTag`
  mutation UpdateMessagingTemplate(
    $id: Int!
    $language: String
    $messagingSystem: MessagingSystem
    $templateType: MessagingTemplateType
    $templateSubject: String
    $templateData: String
  ) {
    updateMessagingTemplate(
      input: {
        patch: {
          language: $language
          messagingSystem: $messagingSystem
          templateData: $templateData
          templateSubject: $templateSubject
          templateType: $templateType
        }
        id: $id
      }
    ) {
      messagingTemplate {
        id
        language
        messagingSystem
        templateData
        templateSubject
        templateType
      }
    }
  }
`;

export const CREATE_MESSAGING_TEMPLATE = gqlTag`
  mutation CreateMessagingTemplate(
    $messagingSystem: MessagingSystem!
    $templateType: MessagingTemplateType!
    $language: String
    $propertyId: Int!
    $templateSubject: String!
    $templateData: String!
  ) {
    createMessagingTemplate(
      input: {
        messagingTemplate: {
          messagingSystem: $messagingSystem
          templateType: $templateType
          language: $language
          propertyId: $propertyId
          templateSubject: $templateSubject
          templateData: $templateData
        }
      }
    ) {
      messagingTemplate {
        id
        id
        propertyId
        messagingSystem
        templateType
        language
        templateSubject
        templateData
      }
    }
  }
`;

export const DELETE_MESSAGING_TEMPLATE = gqlTag`
  mutation deleteMessagingTemplate($id: Int!) {
    deleteMessagingTemplate(input: { id: $id }) {
      messagingTemplate {
        id
      }
    }
  }
`;
