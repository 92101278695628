const setOptionsLists = data => {
  const { devicesList, paymentSystemsList } = data || {};

  return [
    {
      path: 'deviceId',
      enum: devicesList.map(l => l.id),
      enumNames: devicesList.map(l => l.name),
    },
    {
      path: 'paymentSystemId',
      enum: paymentSystemsList.map(t => t.id),
      enumNames: paymentSystemsList.map(t => t.name),
    },
  ];
};

export default setOptionsLists;
