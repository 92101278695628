import gqlTag from 'graphql-tag';

export const GET_VOUCHER_TEMPLATE = gqlTag`
  query VoucherTemplate($propertyId: Int!) {
    voucherTemplatesList(condition: { propertyId: $propertyId }) {
      id
      template
    }
  }
`;

export const UPDATE_VOUCHER_TEMPLATE = gqlTag`
  mutation UpdateVoucherTemplate($template: String!, $voucherId: Int!) {
    updateVoucherTemplate(
      input: { patch: { template: $template }, id: $voucherId }
    ) {
      voucherTemplate {
        id
        template
      }
    }
  }
`;

export const CREATE_VOUCHER_TEMPLATE = gqlTag`
  mutation CreateVoucherTemplate($propertyId: Int!, $template: String!) {
    createVoucherTemplate(
      input: {
        voucherTemplate: { propertyId: $propertyId, template: $template }
      }
    ) {
      voucherTemplate {
        id
        template
      }
    }
  }
`;
