import React, { useState } from 'react';
import { Button, Modal } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars } from '@fortawesome/free-solid-svg-icons';
import '../../styles/sidebar.css';

export const Sidebar = ({ children }) => {
  return (
    <div className="p-0 h-100vh sidebar">
      <div className="border-right h-100">{children}</div>
    </div>
  );
};

export const SidebarModal = ({ children }) => {
  const [modal, setModal] = useState(false);

  const toggle = () => setModal(!modal);

  return (
    <div>
      <Button
        className="d-xl-none mr-3"
        onClick={toggle}
        outline
        color="secondary"
        size="sm"
      >
        <FontAwesomeIcon icon={faBars} className="button-icon" />
      </Button>
      <Modal
        isOpen={modal}
        toggle={toggle}
        modalTransition={{ baseClass: 'sidebar-menu', timeout: 50 }}
        className="sidebar d-block m-0"
      >
        {children}
      </Modal>
    </div>
  );
};

export default Sidebar;
