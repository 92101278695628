import gqlTag from 'graphql-tag';

export const GET_AREA = gqlTag`
  query Area($areaId: Int!) {
    area(id: $areaId) {
      id
      name
      description
      status
      areaType {
        id
        name
      }
      accessSystemAreasList {
        id
        areaId
        attributes
        accessSystemConfigId
      }
      property {
        accessSystemConfigsList {
          id
          name
        }
      }
    }
  }
`;

export const UPDATE_ACCESS_SYSTEM_AREA = gqlTag`
  mutation UpdateAccessSystemArea(
    $accessSystemAreaId: Int!
    $accessSystemConfigId: Int!
    $areaId: Int
    $attributes: JSON
  ) {
    updateAccessSystemArea(
      input: {
        patch: {
          accessSystemConfigId: $accessSystemConfigId
          attributes: $attributes
          areaId: $areaId
        }
        id: $accessSystemAreaId
      }
    ) {
      accessSystemArea {
        area {
          id
          accessSystemAreasList {
            id
            areaId
            attributes
            accessSystemConfigId
          }
        }
      }
    }
  }
`;

export const CREATE_ACCESS_SYSTEM_AREA = gqlTag`
  mutation createAccessSystemArea(
    $areaId: Int!
    $accessSystemConfigId: Int!
    $attributes: JSON
  ) {
    createAccessSystemArea(
      input: {
        accessSystemArea: {
          areaId: $areaId
          accessSystemConfigId: $accessSystemConfigId
          attributes: $attributes
        }
      }
    ) {
      accessSystemArea {
        area {
          id
          accessSystemAreasList {
            id
            areaId
            attributes
            accessSystemConfigId
          }
        }
      }
    }
  }
`;

export const GET_AREAS_LIST = gqlTag`
  query AreasList($propertyId: Int!) {
    areasList(filter: { propertyId: { equalTo: $propertyId } }) {
      id
      name
      description
      status
      areaType {
        id
        name
      }
      accessSystemAreasList {
        id
        areaId
        attributes
        accessSystemConfigId
      }
      property {
        accessSystemConfigsList {
          id
          name
        }
      }
    }
  }
`;

export const UPDATE_AREA = gqlTag`
  mutation updateArea($id: Int!, $status: AreaStatus) {
    updateArea(input: { patch: { status: $status }, id: $id }) {
      area {
        id
        status
      }
    }
  }
`;

export const GET_ACCESS_SYSTEM_AREA = gqlTag`
  query accessSystemAreasList($areaId: Int!) {
    accessSystemAreasList(condition: { areaId: $areaId }) {
      id
      areaId
      attributes
      accessSystemConfigId
    }
  }
`;
