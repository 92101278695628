const areaStatuses = [
  {
    id: 'CLEAN',
    name: 'Clean',
    color: 'success',
  },
  {
    id: 'DIRTY',
    name: 'Dirty',
    color: 'danger',
  },
  {
    id: 'INSPECTED',
    name: 'Inspected',
    color: 'success',
  },
  {
    id: 'OUT_OF_SERVICE',
    name: 'Out of service',
    color: 'warning',
  },
  {
    id: 'OUT_OF_ORDER',
    name: 'Out of order',
    color: 'warning',
  },
  {
    id: 'OUT_OF_INVENTORY',
    name: 'Out of inventory',
    color: 'warning',
  },
  {
    id: 'INDETERMINATE',
    name: 'Indeterminate',
    color: 'secondary',
  },
  {
    id: 'OTHER',
    name: 'Other',
    color: 'secondary',
  },
];

export default areaStatuses;
