import React, { useState } from 'react';
import { useMutation } from '@apollo/client';
import {
  Spinner,
  Button,
  InputGroup,
  InputGroupAddon,
  Label,
  Input,
  FormGroup,
} from 'reactstrap';
import * as request from './requests';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';

const getPaymentNetworks = data => {
  const {
    propertiesPaymentNetwork,
    propertiesPaymentNetworksList,
    paymentNetworksList,
  } = data || {};
  const existPaymentNetworkIds = propertiesPaymentNetworksList.map(
    p => p.paymentNetworkId,
  );
  return paymentNetworksList.filter(paymentNetwork => {
    return (
      paymentNetwork.id === (propertiesPaymentNetwork || {}).paymentNetworkId ||
      !existPaymentNetworkIds.includes(paymentNetwork.id)
    );
  });
};

const AddPropertiesPaymentNetwork = ({
  data,
  propertyId,
  setError,
  fetchData,
}) => {
  const [code, setCode] = useState('');
  const [paymentNetworkId, setPaymentNetworkId] = useState(0);
  const [errorCode, setErrorCode] = useState(false);
  const [errorPaymentNetworkId, setErrorPaymentNetworkId] = useState(false);

  const [createPropertiesPaymentNetwork, { loading: updateLoading }] =
    useMutation(request.CREATE_PROPERTIES_PAYMENT_NETWORK, {
      onCompleted: () => {
        setError(null);
        setCode('');
        setPaymentNetworkId(0);
        fetchData();
      },
      onError: error => {
        setError(error);
      },
    });

  const onSubmit = () => {
    if (!code) setErrorCode(true);
    if (!paymentNetworkId) setErrorPaymentNetworkId(true);
    if (code && paymentNetworkId) {
      setErrorCode(false);
      setErrorPaymentNetworkId(false);
      createPropertiesPaymentNetwork({
        variables: { propertyId, code, paymentNetworkId },
      });
    }
  };

  return (
    <FormGroup className="mb-4">
      <Label>Add payment network</Label>
      <InputGroup>
        <InputGroupAddon
          addonType="prepend"
          style={{ width: '50%', maxWidth: '260px' }}
        >
          <Input
            type="select"
            placeholder="PMS code"
            value={paymentNetworkId}
            onChange={e => setPaymentNetworkId(+e.target.value)}
            invalid={errorPaymentNetworkId}
            className="mr-2"
          >
            <option value={0} disabled>
              Payment network
            </option>
            {getPaymentNetworks(data).map(pn => (
              <option value={pn.id} key={`payment-network-${pn.id}`}>
                {pn.displayName}
              </option>
            ))}
          </Input>
        </InputGroupAddon>
        <Input
          placeholder="PMS code"
          value={code}
          onChange={e => setCode(e.target.value)}
          invalid={errorCode}
          style={{ borderRadius: '4px' }}
          className="mr-2"
          onKeyUp={e => {
            if (e.key === 'Enter') onSubmit();
          }}
        />
        <InputGroupAddon addonType="append">
          <div>
            <Button
              color="success"
              onClick={() => onSubmit()}
              style={{ width: '38px' }}
              className="mr-2"
            >
              {updateLoading ? (
                <Spinner color="light" size="sm" />
              ) : (
                <FontAwesomeIcon icon={faPlus} className="button-icon" />
              )}
            </Button>
          </div>
        </InputGroupAddon>
      </InputGroup>
    </FormGroup>
  );
};

export default AddPropertiesPaymentNetwork;
