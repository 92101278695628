import { useState } from 'react';
import { useMutation } from '@apollo/client';
import { isEmpty } from 'lodash';
import * as requests from './paymentSystemsRequests';
import useAddCurrency from './useAddCurrency';
import * as request from './paymentSystemsRequests';
import parseString from '../../../lib/parseString';

const useOnSubmitPaymentDevice = (data, currencies = [], addNew, setAddNew) => {
  const { paymentDevice = {}, propertyId } = data;

  const [complete, setComplete] = useState(null);

  const refetchQueries = [
    {
      query: request.GET_DEVICES,
      variables: { propertyId },
    },
  ];

  const onAdded = () => {
    setComplete(true);
    setAddNew(false);
  };

  const [updateDevice, { loading: loadingUpdate, error: errorUpdate }] =
    useMutation(requests.UPDATE_DEVICE, {
      onCompleted: () => {
        setComplete(true);
      },
      refetchQueries,
    });

  const [createDevice, { loading: loadingCreate, error: errorCreate }] =
    useMutation(requests.CREATE_DEVICE, {
      refetchQueries: currencies.length === 0 ? refetchQueries : undefined,
      onCompleted: data => {
        const {
          createPaymentSystemDevice: { paymentSystemDevice: { id } = {} } = {},
        } = data;
        if (id && currencies.length > 0) {
          addCurrencies(
            currencies.map(c => c.id),
            id,
          );
        } else {
          onAdded();
        }
      },
    });

  const [deleteDevice, { loading: loadingDelete, error: errorDelete }] =
    useMutation(requests.DELETE_DEVICE, {
      refetchQueries,
      variables: {
        id: paymentDevice.id,
      },
    });

  const {
    addCurrencies,
    error: currencyError,
    loading: currencyLoading,
  } = useAddCurrency(propertyId, paymentDevice.id, onAdded);

  const onSubmit = data => {
    const { id, deviceId, paymentSystemId } = data;
    const _attributes_ = data[`attributes${paymentSystemId}`];
    const _credentials_ = data[`credentials${paymentSystemId}`];
    const attributesParsed =
      typeof _attributes_ === 'string'
        ? parseString(_attributes_)
        : _attributes_;
    const credentialsParsed =
      typeof _credentials_ === 'string'
        ? parseString(_credentials_)
        : _credentials_;
    const attributes = isEmpty(attributesParsed) ? null : attributesParsed;
    const credentials = isEmpty(credentialsParsed) ? null : credentialsParsed;

    if (addNew) {
      createDevice({
        variables: { deviceId, paymentSystemId, attributes, credentials },
      });
    } else if (id) {
      updateDevice({
        variables: { id, deviceId, paymentSystemId, attributes, credentials },
      });
    }
  };

  const loading =
    loadingUpdate || loadingCreate || loadingDelete || currencyLoading;
  const error = errorCreate || errorUpdate || errorDelete || currencyError;
  const success = complete && !loading && !error;

  return { onSubmit, deleteDevice, loading, error, success };
};

export default useOnSubmitPaymentDevice;
