import { useState } from 'react';
import { useMutation } from '@apollo/client';
import * as request from './paymentSystemsRequests';

const useAddCurrency = (propertyId, deviceId, onAdded) => {
  const refetchQueries = [
    {
      query: request.GET_DEVICES,
      variables: { propertyId },
    },
  ];

  const [currenciesIds, setCurrenciesIds] = useState([]);

  const [assignLastCurrency, { loading: lastLoading, error: lastError }] =
    useMutation(request.ASSIGN_CURRENCY, {
      onCompleted: () => {
        onAdded();
      },
      refetchQueries,
    });

  const [assignCurrency, { loading, error }] = useMutation(
    request.ASSIGN_CURRENCY,
    {
      onCompleted: data => {
        const {
          createPaymentSystemDevicesCurrency: {
            paymentSystemDevicesCurrency: { paymentSystemDeviceId } = {},
          } = {},
        } = data;
        const [currencyId] = currenciesIds;
        const variables = {
          paymentSystemDeviceId,
          currencyId,
        };
        if (currencyId) {
          if (currenciesIds.length === 1) {
            assignLastCurrency({ variables });
          } else {
            assignCurrency({ variables });
            setCurrenciesIds(prevIds => prevIds.slice(1));
          }
        }
      },
    },
  );

  const addCurrency = id => {
    const variables = {
      paymentSystemDeviceId: deviceId,
      currencyId: id,
    };
    assignCurrency({ variables, refetchQueries });
  };

  const addCurrencies = (ids, paymentSystemDeviceId) => {
    const [id] = ids;
    setCurrenciesIds(ids.slice(1));
    const variables = {
      paymentSystemDeviceId,
      currencyId: id,
    };
    if (ids.length === 1) {
      assignLastCurrency({ variables });
    } else {
      assignCurrency({ variables });
    }
  };

  const _error_ = error || lastError;
  const _loading_ = loading || lastLoading;

  return { addCurrency, addCurrencies, error: _error_, loading: _loading_ };
};

export default useAddCurrency;
