import React, { useRef } from 'react';
import gqlTag from 'graphql-tag';
import { useQuery } from '@apollo/client';
import { Spinner, Table, Button } from 'reactstrap';
import '../../../styles/guest.css';
import { useReactToPrint } from 'react-to-print';
import ErrorLine from '../../containers/errorLine';
import getZonedTime from '../../../lib/getZonedTime';

class DrawGuest extends React.Component {
  render() {
    if (!this.props.data || !this.props.data.guest) {
      return <ErrorLine error={new Error('No guest data')} />;
    }

    const {
      firstName,
      middleName,
      lastName,
      country,
      dateOfBirth,
      placeOfBirth,
      passportNumber,
      visaNumber,
      signature,
      booking,
      addressesList,
    } = this.props.data.guest || {};
    const { name: countryName } = country || {};
    const { startDate, endDate, property } = booking || {};
    const { propertySettings: { timezone } = {} } = property || {};
    const guestAddress = addressesList && addressesList[0];
    const {
      country: guestCountry,
      postalCode,
      city,
      line1,
    } = guestAddress || {};
    const { name: guestCountryName } = guestCountry || {};

    const address = [line1, postalCode, city, guestCountryName]
      .filter(str => !!str && str !== '?')
      .join(', ');

    const guestData = [
      { title: 'First name', data: firstName },
      { title: 'Middle name', data: middleName },
      { title: 'Last name', data: lastName },
      { title: 'Country', data: countryName },
      { title: 'Address', data: address },
      {
        title: 'Check in',
        data: startDate && getZonedTime(startDate, timezone),
      },
      {
        title: 'Check out',
        data: endDate && getZonedTime(endDate, timezone),
      },
      { title: 'Date of birth', data: dateOfBirth },
      { title: 'Place of birth', data: placeOfBirth },
      { title: 'Passport number', data: passportNumber },
      { title: 'Visa number', data: visaNumber },
    ];
    return (
      <Table className="guest" bordered hover>
        <tbody>
          {guestData.map(({ title, data }, i) => {
            if (data) {
              return (
                <tr key={`guestTable${i}`}>
                  <td width="30%">{title}</td>
                  <td>{data}</td>
                </tr>
              );
            }
            return false;
          })}
          {signature && (
            <tr>
              <td width="30%">Signature</td>
              <td>
                <img src={signature} alt="signature" title="signature" />
              </td>
            </tr>
          )}
        </tbody>
      </Table>
    );
  }
}

const Guest = ({ row }) => {
  const guestId = row.original.id;

  const GET_GUEST = gqlTag`
    query Property($guestId: Int!) {
      guest(id: $guestId) {
        id
        country {
          id
          name
        }
        firstName
        middleName
        lastName
        dateOfBirth
        placeOfBirth
        documents
        passportExpDate
        passportNumber
        signature
        visaNumber
        phoneNumbers
        emails
        booking {
          id
          startDate
          endDate
          property {
            propertySettings {
              timezone
            }
          }
        }
        addressesList {
          id
          country {
            id
            name
          }
          postalCode
          city
          line1
        }
      }
    }
  `;

  const { loading, error, data } = useQuery(GET_GUEST, {
    variables: { guestId },
    fetchPolicy: 'network-only',
  });

  const guestRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => guestRef.current,
    pageStyle: 'padding: 120px',
  });

  return (
    <>
      {loading && <Spinner color="secondary" />}
      {error && <ErrorLine error={error} />}
      {data && <DrawGuest data={data} ref={guestRef} />}
      {data && <Button onClick={handlePrint}>Print guest data</Button>}
    </>
  );
};

export default Guest;
