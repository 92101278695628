import React from 'react';
import { Card, CardHeader, CardBody } from 'reactstrap';

const Widget = ({ header, content, size }) => {
  let widthOfWidget = 'col-sd-12 col-md-6 col-lg-6 col-xl-4';
  if (size === 'lg') widthOfWidget = 'col-lg-12 col-xl-8';
  return (
    <div className={`${widthOfWidget} mb-4`}>
      <Card>
        <CardHeader>{header}</CardHeader>
        <CardBody>{content}</CardBody>
      </Card>
    </div>
  );
};

export default Widget;
